<template>
  <v-container class="white pa-6">
    <v-row
      ><v-col sm="1">{{ $t('components.productList.number') }}</v-col
      ><v-col sm="3">{{ $t('helpers.product') }}</v-col
      ><v-col col="2"
        >{{ $t('helpers.price') }} ({{ $t('helpers.net') }}) /
        {{ $t('helpers.piecesAmount') }}</v-col
      ><v-col sm="3" class="text-center">{{ $t('helpers.amount') }}</v-col
      ><v-col col="2">{{ $t('helpers.sum') }} ({{ $t('helpers.net') }})</v-col><v-col sm="1"></v-col
    ></v-row>
    <v-row><v-divider></v-divider></v-row>
    <v-form v-model="amountsFormValid" ref="totalAmountForm">
      <product-item
        v-for="(item, index) in items"
        :key="item._id"
        :onBasicSummary="onBasicSummary"
        :product="item"
        :nr="index + 1"
        v-on="$listeners"
      ></product-item>
      <v-row class="pt-5 flex-column flex-md-row" v-if="isButtonAddVisible">
        <v-col>
          <primary-button :to="{ name: 'Catalog' }" color="primary"
            ><v-icon class="mr-4">mdi-plus</v-icon
            >{{ $t('components.productList.addElement') }}</primary-button
          >
        </v-col>
        <v-spacer> </v-spacer>
        <v-col class="text-right">
          <p class="text mb-0">{{ $t('components.productList.productsPrice') }}:</p>
          <v-row v-if="isSysAdmin" class="mt-2 justify-end">
            <v-col cols="6">
              <v-text-field
                min="0"
                type="number"
                :rules="validator.priceGroupRules"
                hide-details="auto"
                solo
                dense
                elevation="0"
                outlined
                @change="updateSpecialPriceInStore"
                :suffix="currencyLabel()"
                v-model.number="customTotalAmount"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="productsWithDiscounts" class="pt-2">
            <v-col class="text-right"
              >{{ $t('components.priceDetails.discoutValue') }}:
              {{ showDiscountInPercentage }}</v-col
            ></v-row
          >
          <order-amount-details :showDeliveryDetails="false"></order-amount-details>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import PrimaryButton from '../buttons/PrimaryButton.vue';
import ProductItem from './ProductItem.vue';
import OrderAmountDetails from '../OrderAmountDetails.vue';
import { round } from 'lodash';
import { formatCurrency } from '../../mixins/formatCurrency';
import { productUnitPrice } from '../../mixins/productUnitPrice';
import { permissionRolesMixin } from '../../mixins/permissionRolesMixin';
import { userDataComputed, specialTotalPriceComputed } from '../../store/helper.js';
import { validators } from '../../validators/Validators';
import { basketDiscountMixin } from '../../mixins/basketDiscountMixin';

export default {
  name: 'ProductList',
  components: { ProductItem, PrimaryButton, OrderAmountDetails },
  mixins: [formatCurrency, productUnitPrice, permissionRolesMixin, basketDiscountMixin],
  props: {
    items: Array,
    isButtonAddVisible: {
      type: Boolean,
      default: true,
    },
    onBasicSummary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      test: this.specialTotalPrice,
      amountsFormValid: false,
    };
  },
  methods: {
    updateSpecialPriceInStore(val) {
      const price = round(this.getPriceInDefaultCurrency(val), 2);
      this.$store.commit('setSpecialTotalPrice', round(parseFloat(price), 2));
    },
  },
  computed: {
    ...userDataComputed,
    ...specialTotalPriceComputed,
    validator() {
      return validators;
    },
    customTotalAmount: {
      get() {
        return this.userReceivesDiscount
          ? this.priceWithDiscount(this.calculateByCurrency(this.specialTotalPrice)).toFixed(2)
          : this.calculateByCurrency(this.specialTotalPrice).toFixed(2);
      },
      set(value) {
        return value;
      },
    },
  },
  watch: {
    selectedCurrency: function () {
      this.$store.commit('setSpecialTotalPrice', 0);
    },
  },
};
</script>
