<template>
  <div>
    <v-progress-linear v-if="loading" indeterminate color="primary"> </v-progress-linear>
    <v-container v-else class="px-0">
      <v-row class="align-center">
        <slot name="filters"></slot>
      </v-row>
      <v-container class="white my-4">
        <v-row
          ><v-col
            ><v-data-table
              :headers="headers"
              :items="data"
              :items-per-page="6"
              hide-default-footer
              class="elevation-0"
              :disable-sort="disableSort"
              :sortBy="sortBy"
              :sortDesc="sortDesc"
              v-on="$listeners"
              :no-data-text="this.$t('components.myDataTable.noDataAvailable')"
              ><slot></slot>
              <template v-slot:[`item.fullName`]="{ item }">{{
                item.fullName ? item.fullName : `${item.firstName} ${item.lastName}`
              }}</template>
              <template v-slot:[`item.role`]="{ item }">{{
                $t(`statics.roles.${item.role}`)
              }}</template>

              <template v-slot:[`item.fullProfit`]="{ item }">
                {{ roundedProfit(item.fullProfit) }}
              </template>
              <template v-slot:[`item.countryCode`]="{ item }">
                {{ getCountryName(item.countryCode) || '' }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-menu :close-on-content-click="true" :nudge-width="200" offset-x>
                  <template v-slot:activator="{ on }">
                    <v-icon small color="black" class="mr-2" v-on="on"> mdi-dots-vertical </v-icon>
                  </template>
                  <v-card>
                    <slot name="actions" v-bind:item="item"></slot>
                  </v-card>
                </v-menu> </template></v-data-table></v-col
        ></v-row>
        <slot name="below"></slot>
      </v-container>
      <v-row v-if="isPaginationVisible"
        ><v-col
          ><div class="text-center">
            <v-pagination
              v-model="pageNumber"
              :length="length"
              total-visible="5"
            ></v-pagination></div></v-col
      ></v-row>
    </v-container>
  </div>
</template>
<script>
import { round } from 'lodash';
import { countriesCodeHandler } from '../mixins/countriesCodeHandler';

export default {
  name: 'RegistrationForms',
  mixins: [countriesCodeHandler],
  props: {
    headers: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    sortBy: {
      type: Array,
      default: () => [],
    },
    sortDesc: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      itemsPerPage: 6,
      pageNumber: 1,
    };
  },
  watch: {
    pageNumber: function (newVal) {
      this.$emit('update:page', newVal);
    },
  },
  computed: {
    isPaginationVisible() {
      return this.$props.length > 1;
    },
  },
  methods: {
    roundedProfit(profit) {
      return round(profit, 2);
    },
  },
  created() {
    this.pageNumber = this.page;
  },
};
</script>
<style lang="scss" scoped>
.v-btn--round {
  border-radius: 4px;
}
</style>
