<template>
  <v-container class="white pa-6">
    <v-form @submit.prevent="login" ref="form">
      <v-row class="mb-4">
        <v-col cols="10" sm="6" class="pb-0">
          <h2 class="text mb-4">{{ $t('helpers.email') }}</h2>

          <v-text-field
            name="email"
            :label="$t('helpers.email')"
            type="email"
            v-model="email"
            outlined
            solo
            elavation="0"
            hide-details
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10" sm="6" class="py-0">
          <h2 class="text mb-4">{{ $t('helpers.password') }}</h2>

          <v-text-field
            id="password"
            name="password"
            :label="$t('helpers.password')"
            v-model="password"
            hide-details
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            outlined
            solo
            elavation="0"
            class="mb-2"
          ></v-text-field>

          <router-link :to="{ name: 'PasswordReset' }">{{
            $t('components.loginForm.forgotPassword')
          }}</router-link>
        </v-col>
      </v-row>

      <v-row class="mt-4"
        ><v-col>
          <primary-button color="primary" :loading="loading" type="submit">{{
            $t('helpers.logIn')
          }}</primary-button></v-col
        >
      </v-row>
      <v-row
        ><v-col>
          <p>
            {{ $t('components.loginForm.noAccount') }}
            <router-link :to="{ name: 'Register' }" class="font-weight-bold">{{
              $t('helpers.register')
            }}</router-link>
          </p></v-col
        >
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import PrimaryButton from './buttons/PrimaryButton.vue';
import { userDataComputed } from '../store/helper';
import { formMixin } from '../mixins/formMixin';

export default {
  components: { PrimaryButton },
  mixins: [formMixin],
  name: 'LoginForm',
  data() {
    return {
      password: '',
      email: '',
      loading: false,
      showPassword: false,
    };
  },
  computed: {
    ...userDataComputed,
  },
  methods: {
    async login() {
      try {
        this.loading = true;
        await this.$store.dispatch('login', {
          email: this.email,
          password: this.password,
        });
        this.$store.dispatch('initLocale');
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
