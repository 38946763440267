const ROOF_TYPE = {
  SKOSNY: 'Dach skośny',
};

const POUR_TYPE = {
  BLACHODACHOWKA: 'Blachodachówka',
  RABEK: 'Rąbek',
  GONT: 'Gont(łupek)',
  BLACHA_TRAPEZOWA: 'Blacha trapezowa',
  DACHOWKA_CERAMICZNA: 'Dachówka ceramiczna',
  KARPIOWKA: 'Karpiówka',
};

const HANDLE_TYPE = {
  UCHWYT_REGULOWANY: 'Uchwyt regulowany',
  UCHWYT_Z_BLACHY: 'Uchwyt z blachy',
  UCHWYT_TRAPEZOWY: 'Uchwyt trapezowy',
  MOSTEK_TRAPEZOWY_330: 'Mostek trapezowy 330',
  MOSTEK_TRAPEZOWY_400: 'Mostek trapezowy 400',
  MOSTEK_TRAPEZOWY_500: 'Mostek trapezowy 500',
  UCHWYT_RABEK: 'Uchwyt rąbek',
  UCHWYT_GONT: 'Uchwyt gont',
  SRUBA_PODWOJNY_GWINT: 'Śruba z gwintem podwójnym',
  DG: 'DG',
  DC2_L460: 'DC2 L460',
  DC2_L560: 'DC2 L560',
  DC_L460: 'DC L460',
  DC1_L110: 'DC1 L110',
  DC1_L140: 'DC1 L140',
};

const CLAMP_TYPES = {
  CZARNE: 'Czarna klema i czarna konstrukcja',
  SREBRNE: 'Srebrna klema i srebrna konstrukcja',
};

const PANEL_ORIENTATION = {
  PION: 'PION',
  POZIOM: 'POZIOM',
  MIESZANE: 'MIESZANE',
};

const RAFTER_SPACING = {
  CM60: '60cm',
  CM70: '70cm',
  CM80: '80cm',
  CM90: '90cm',
  CM100: '100cm',
};

const SCREW_TYPES = {
  M10200: 'M10x200',
  M10250: 'M10x250',
  M10300: 'M10x300',
};

const TSC_TYPES = {
  M8X60: 'M8x60',
  M8X80: 'M8x80',
  M8X120: 'M8x120',
};

const noHandleTypePourTypes = [POUR_TYPE.BLACHODACHOWKA];

const permamentPourTypes = [POUR_TYPE.RABEK, POUR_TYPE.GONT];

const tcsHandleTypes = [
  HANDLE_TYPE.UCHWYT_REGULOWANY,
  HANDLE_TYPE.UCHWYT_Z_BLACHY,
  HANDLE_TYPE.DC1_L110,
  HANDLE_TYPE.DC1_L140,
  HANDLE_TYPE.DC2_L460,
  HANDLE_TYPE.DC2_L560,
  HANDLE_TYPE.DG,
  HANDLE_TYPE.DC_L460,
];

const permamentHandleType = {
  Blachodachówka: HANDLE_TYPE.SRUBA_PODWOJNY_GWINT,
};
module.exports = {
  TSC_TYPES,
  SCREW_TYPES,
  RAFTER_SPACING,
  PANEL_ORIENTATION,
  CLAMP_TYPES,
  HANDLE_TYPE,
  POUR_TYPE,
  ROOF_TYPE,
  permamentPourTypes,
  tcsHandleTypes,
  permamentHandleType,
  noHandleTypePourTypes,
};
