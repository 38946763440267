<template>
  <title-wrapper :title="$t('components.wizard.roofType.title')">
    <v-container class="mx-0 px-0"
      ><v-row class="flex-column-reverse flex-sm-row">
        <v-col cols="12" sm="4">
          <v-card @click="openInfoModal" class="white my-card text-center card-dimmed">
            <v-icon size="85" class="mb-8" color="primary">mdi-triangle-outline</v-icon>
            <h2 class="font-weight-bold">{{ $t('components.wizard.roofType.ground') }}</h2>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card @click="openInfoModal" class="white my-card text-center card-dimmed">
            <v-icon size="85" class="mb-8">$squareIcon</v-icon>
            <h2 class="font-weight-bold">{{ $t('components.wizard.roofType.flatRoof') }}</h2>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card class="white my-card text-center selected">
            <v-icon size="85" class="mb-8">$triangleIcon</v-icon>
            <h2 class="font-weight-bold">{{ $t('components.wizard.roofType.pitchedRoof') }}</h2>
          </v-card>
        </v-col>
      </v-row>
      <flat-roof-info-modal v-on:close="close" :open="openModal"></flat-roof-info-modal>
    </v-container>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../components/TitleWrapper.vue';
import FlatRoofInfoModal from '../../../components/modals/FlatRoofInfoModal.vue';
import { ROOF_TYPE } from '../../../static/fotoConstants.js';

export default {
  name: 'RoofType',
  components: { TitleWrapper, FlatRoofInfoModal },
  data() {
    return {
      selectedRoofType: ROOF_TYPE.SKOSNY,
      openModal: false,
    };
  },
  activated() {
    this.$emit('isFowardEnabled', true);
  },
  methods: {
    openInfoModal() {
      this.openModal = true;
    },
    close() {
      this.openModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';
.card-dimmed {
  opacity: 0.4;
}
.my-card {
  transition: all 0.2s ease-out;
  padding: 3.5rem;
  height: 100%;
  cursor: pointer;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 11px 18px -2px rgba(0, 0, 0, 0.3);
  }
  &.selected {
    border-bottom: 3px solid $primary !important;
  }
}
</style>
