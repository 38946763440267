<template>
  <div class="title-part white--text d-flex">
    <v-container class="cart" v-if="isCartActive" @click="toSummary">
      <v-row class="d-flex flex-row align-center flex-nowrap justify-center"
        ><v-col cols="4" class="pr-0 mr-1"><v-icon>mdi-cart</v-icon></v-col
        ><v-col cols="8" class="pl-0"
          ><span class="no-wrap">{{
            formatCurrency(isSysAdmin ? specialTotalPrice : cartTotalAmount)
          }}</span></v-col
        ></v-row
      ></v-container
    >
    <navbar-dropdown-button icon="$homeIcon" :links="homeLinks"></navbar-dropdown-button>
    <navbar-dropdown-button
      v-if="!isSysAdminOrSalesman"
      icon="$listIcon"
      :links="listLinks"
    ></navbar-dropdown-button>
    <navbar-dropdown-button icon="$settingsIcon" :links="settingsLinks"></navbar-dropdown-button>

    <span class="horizontal-divider mr-6"></span>
    <div v-if="isNotMobile">
      <span class="name"> {{ userData.firstName }} {{ userData.lastName }} </span>
      <span class="company-name mr-6 ml-1 font-weight-regular">
        ({{
          userData.companyName ? slicedCompanyName : $t(`statics.roles.${userData.role}`)
        }})</span
      >
    </div>
    <v-btn v-if="isLoggedIn" icon @click="logout" class="mr-4 mr-sm-0 logout--mobile">
      <v-icon> $logoutIcon </v-icon>
    </v-btn>
    <v-icon class="nav-triangle" size="72px"> $navbarTriangleIcon </v-icon>
    <change-route-confirm-modal ref="confirm"></change-route-confirm-modal>
  </div>
</template>
<script>
import NavbarDropdownButton from './NavbarDropdownButton.vue';
import { roles } from '../static/roles';
import { cartTotalAmountComputed, specialTotalPriceComputed } from '../store/helper';
import { formatCurrency } from '../mixins/formatCurrency';
import ChangeRouteConfirmModal from '../components/modals/ChangeRouteConfirmModal.vue';
import { permissionRolesMixin } from '../mixins/permissionRolesMixin';
import { authMixin } from '../mixins/authMixin';

export default {
  name: 'NavLinksSection',
  components: { NavbarDropdownButton, ChangeRouteConfirmModal },
  mixins: [formatCurrency, permissionRolesMixin, authMixin],
  data() {
    return {
      roles: roles,
    };
  },
  computed: {
    ...cartTotalAmountComputed,
    ...specialTotalPriceComputed,
    isNotMobile() {
      return !this.$vuetify.breakpoint.sm;
    },
    homeLinks() {
      const homeLinks = [
        {
          title: this.$i18n.t('components.navLinksSection.homeLinks.categories'),
          name: 'Categories',
        },
        { title: this.$i18n.t('components.navLinksSection.homeLinks.sets'), name: 'Sets' },
      ];
      return homeLinks;
    },
    listLinks() {
      const listLinks = [
        { title: this.$i18n.t('components.navLinksSection.listLinks.orders'), name: 'Orders' },
        { title: this.$i18n.t('components.navLinksSection.listLinks.offers'), name: 'Offers' },
      ];
      return listLinks;
    },
    settingsLinks() {
      let links = [
        {
          title: this.$i18n.t('components.navLinksSection.settingLinks.editAccount'),
          name: 'EditAccount',
        },
      ];
      const companyAdminLinks = [
        {
          title: this.$i18n.t('components.navLinksSection.settingLinks.manageCompany'),
          name: 'ManageCompany',
        },
        {
          title: this.$i18n.t('components.navLinksSection.settingLinks.editCompanyEmployees'),
          name: 'EditCompanyEmployees',
        },
      ];
      const sysAdminLinks = [
        { title: this.$i18n.t('components.navLinksSection.settingLinks.traders'), name: 'Traders' },
        { title: this.$i18n.t('components.navLinksSection.settingLinks.stock'), name: 'Stock' },
        {
          title: this.$i18n.t('components.navLinksSection.settingLinks.adminUsers'),
          name: 'AdminUsers',
        },
        {
          title: this.$i18n.t('components.navLinksSection.settingLinks.sysadminOrders'),
          name: 'SysadminOrders',
        },
        { title: this.$i18n.t('components.navLinksSection.settingLinks.offers'), name: 'Offers' },
        {
          title: this.$i18n.t('components.navLinksSection.settingLinks.systemSettings'),
          name: 'SystemSettings',
        },
      ];

      const salesmanLinks = [
        {
          title: this.$i18n.t('components.navLinksSection.settingLinks.adminUsers'),
          name: 'SalesmanAllCompanies',
        },
        {
          title: this.$i18n.t('components.navLinksSection.settingLinks.sysadminOrders'),
          name: 'SalesmanOrders',
        },
        { title: this.$i18n.t('components.navLinksSection.settingLinks.offers'), name: 'Offers' },
      ];

      links = links.concat(
        this.isSysAdmin
          ? sysAdminLinks
          : this.isCompanyAdmin
          ? companyAdminLinks
          : this.isSalesman
          ? salesmanLinks
          : []
      );
      return links;
    },
    slicedCompanyName() {
      return this.userData?.companyName?.split(' ').slice(0, 2).join(' ');
    },
  },
  methods: {
    isCartActive() {
      return this.$store.state.cart.length > 0;
    },
    toSummary() {
      this.$router.push({ name: 'TransactionSummary' });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../scss/variables.scss';
.title-part {
  background-color: $primary;
  align-items: center;
  position: relative;
  font-weight: bold;
  padding-left: 1rem;

  .horizontal-divider {
    display: inline-block;
    height: 75%;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  &::after {
    @media (min-width: 767px) {
      content: '';
      width: 50rem;
      height: 4.5rem;
      background-color: $primary;
      overflow: hidden;
      position: absolute;
      right: -50rem;
    }
  }
  .name,
  .company-name {
    color: rgba(255, 255, 255, 0.7);
  }
  .nav-triangle {
    position: absolute;
    left: -3.3rem;
    opacity: 1;
  }
}

.pointer {
  cursor: pointer;
}

.cart {
  position: absolute;
  right: 0%;
  top: 100%;
  background: white;
  cursor: pointer;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  width: auto;
  color: $font;
  transition: all 0.2s ease;
  &:hover {
    color: $primary;
  }
}
.no-wrap {
  white-space: nowrap;
  padding-right: 1.5rem;
}

.relative {
  position: relative;
}

.logout--mobile {
  display: block;
  height: 100%;
  transform: translateX(-1rem);
  &::before {
    right: -1.2rem;
  }
  @media (min-width: 1350px) {
    display: none;
  }
}
</style>
