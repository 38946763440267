<template>
  <div class="logo-section">
    <v-img
      contain
      max-height="50"
      max-width="35"
      v-if="$vuetify.breakpoint.sm"
      src="@/assets/bf-logo-name-slim.svg"
      alt="brainforge-logo"
    />
    <v-img v-else src="@/assets/bf-logo-name-slim.svg" alt="brainforge-logo" />
  </div>
</template>
<script>
export default {
  name: 'NavLogoSection',
};
</script>
<style lang="scss" scoped>
.logo-section {
  margin: auto 0;
  .v-responsive {
    max-width: 18%;
  }
}

.logo {
  max-height: 100%;
}
</style>
