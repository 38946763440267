import config from '../../configuration.json';
import axios from 'axios';

const url = `${config.VUE_APP_API_URL}/users`;

axios.defaults.withCredentials = true;

class UserService {
  static async update(id, user) {
    const res = await axios.put(`${url}/${id}`, { ...user });
    return res.data.data;
  }

  static async partialUpdate(id, user) {
    const res = await axios.put(`${url}/${id}/partial`, { ...user });
    return res;
  }

  static async deleteAccount(id) {
    const res = await axios.delete(`${url}/${id}`);
    return res.data.data;
  }

  static async changePassword(id, password, oldPassword) {
    const res = await axios.post(`${url}/change-password/${id}`, {
      password,
      oldPassword,
    });
    return res.data.data;
  }

  static async getUser(id) {
    const res = await axios.get(`${url}/${id}`);
    return res.data;
  }
}

export default UserService;
