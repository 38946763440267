<template>
  <title-wrapper :title="$t('components.wizard.panelOrientation.title')">
    <selectable-section
      :label="$t('components.wizard.panelOrientation.selectLabel')"
      :items="orientation"
      storeAction="setPanelOrientation"
      :active="panelOrientation"
      :preview="true"
    ></selectable-section>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../components/TitleWrapper.vue';
import SelectableSection from '../../../components/SelectableSection.vue';
import { panelOrientationComputed } from '../../../store/helper.js';
import { PANEL_ORIENTATION } from '../../../static/fotoConstants.js';
import orientacjaPionowo from '@/assets/wizzardImages/orientacjaPionowo.png';
import orientacjaPoziomo from '@/assets/wizzardImages/orientacjaPoziomo.png';
import orientacjaMieszane from '@/assets/wizzardImages/orientacjaMieszane.png';

export default {
  name: 'PanelOrientation',
  components: { TitleWrapper, SelectableSection },
  computed: {
    ...panelOrientationComputed,
    orientation() {
      const panelOrientations = [
        {
          value: PANEL_ORIENTATION.PION,
          text: this.$i18n.t('statics.panelOrientations.PION'),
          imgPath: orientacjaPionowo,
        },
        {
          value: PANEL_ORIENTATION.POZIOM,
          text: this.$i18n.t('statics.panelOrientations.POZIOM'),
          imgPath: orientacjaPoziomo,
        },
        {
          value: PANEL_ORIENTATION.MIESZANE,
          text: this.$i18n.t('statics.panelOrientations.MIESZANE'),
          imgPath: orientacjaMieszane,
        },
      ];
      return panelOrientations;
    },
  },
  activated() {
    this.$emit('isFowardEnabled', true);
  },
};
</script>
<style lang="scss" scoped></style>
