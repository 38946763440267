<template>
  <title-link-wrapper :title="$t('components.singleOffer.title')" :to="{ name: 'Offers' }">
    <v-container>
      <v-row>
        <v-col
          ><h2>{{ $t('components.singleOffer.offer') }}</h2></v-col
        >
      </v-row>
      <v-container class="white pa-4">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="products"
              :items-per-page="10"
              class="elevation-0"
              :disable-sort="true"
              :no-data-text="this.$t('components.myDataTable.noDataAvailable')"
            >
              <template v-slot:[`item.product.standardPrice`]="{ item }">
                <span
                  >{{
                    formatCurrency(
                      productUnitPrice({ ...item.product, quantity: item.quantity }),
                      offer.currency
                    )
                  }}
                </span>
              </template></v-data-table
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider> </v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col> </v-col>
          <v-col class="text-right"
            ><p>{{ $t('components.singleOffer.productPriceNet') }}: {{ productsTotalPrice }}</p>
            <p>{{ $t('components.singleOffer.deliveryPriceNet') }}: {{ offerDeliveryPrice }}</p>
            <b>{{ $t('helpers.sum') }} ({{ $t('helpers.net') }}): {{ offerTotalPrice }}</b>
          </v-col>
        </v-row>
        <div v-if="offer.services && offer.services.length">
          <v-row>
            <v-col>
              <h3>{{ $t('components.singleOffer.additionalService') }}</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="serviceHeaders"
                :items="offer.services"
                :items-per-page="10"
                class="elevation-0"
                :disable-sort="true"
                :no-data-text="this.$t('components.myDataTable.noDataAvailable')"
              >
                <template v-slot:[`item.name`]="{ item }">
                  <span>{{ setServiceName(item.name) }} </span>
                </template>
                <template v-slot:[`item.price`]="{ item }">
                  <span>{{ formatCurrency(item.price, offer.currency, false) }} </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col>
            <primary-button :onClick="toGenerateOffer">{{
              $t('components.singleOffer.generateOffer')
            }}</primary-button>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <outlined-button :onClick="handleModals">{{
              $t('components.singleOffer.finalizeOrder')
            }}</outlined-button>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <confirm-modal
      :title="$t('components.singleOffer.modalOne.title')"
      @accept="confirmForward"
      @decline="decline"
      :open="showModal"
      :agree="$t('helpers.continue')"
      :disagree="$t('helpers.cancel')"
    >
      {{ $t('components.singleOffer.modalOne.text') }}
    </confirm-modal>
    <confirm-modal
      :title="$t('components.singleOffer.modalTwo.title')"
      @accept="confirmWithDiffTotalPrice"
      @decline="decline"
      :open="showDiffTotalPriceModal"
      :agree="$t('helpers.continue')"
      :disagree="$t('helpers.cancel')"
    >
      {{ $t('components.singleOffer.modalTwo.text.partOne') }}
      <strong>{{ currentProductsPrice }}</strong
      >{{ $t('components.singleOffer.modalTwo.text.partTwo', { currency: selectedCurrency }) }}
    </confirm-modal>
  </title-link-wrapper>
</template>
<script>
import TitleLinkWrapper from '../../../../components/TitleLinkWrapper.vue';
import OfferService from '../../../../services/OfferService';
import PrimaryButton from '../../../../components/buttons/PrimaryButton.vue';
import OutlinedButton from '../../../../components/buttons/OutlinedButton.vue';
import ConfirmModal from '../../../../components/modals/ConfirmModal.vue';
import { userDataComputed, selectedCurrencyComputed } from '../../../../store/helper';
import { formatCurrency } from '../../../../mixins/formatCurrency';
import { formatDate } from '../../../../mixins/formatDate';
import { productUnitPrice } from '../../../../mixins/productUnitPrice';
import { round, sum } from 'lodash';

export default {
  components: { TitleLinkWrapper, OutlinedButton, PrimaryButton, ConfirmModal },
  name: 'SingleOrder',
  mixins: [formatCurrency, formatDate, productUnitPrice],
  data() {
    return {
      offerId: '',
      products: [],
      offer: '',
      showModal: false,
      showDiffTotalPriceModal: false,
    };
  },
  computed: {
    ...userDataComputed,
    ...selectedCurrencyComputed,
    headers() {
      const tableHeaders = [
        {
          text: this.$i18n.t('helpers.code'),
          align: 'start',
          value: 'product.code',
        },
        { text: this.$i18n.t('helpers.filterName'), value: 'product.name' },
        { text: this.$i18n.t('helpers.totalPrice'), value: 'product.standardPrice' },
        { text: this.$i18n.t('helpers.piecesAmount'), value: 'quantity' },
      ];
      return tableHeaders;
    },
    serviceHeaders() {
      const tableServiceHeaders = [
        { text: this.$i18n.t('helpers.filterName'), value: 'name' },
        { text: this.$i18n.t('helpers.totalPrice'), value: 'price' },
      ];
      return tableServiceHeaders;
    },
    company() {
      return this.offer.company ? this.offer.company : {};
    },
    currentProductsPrice() {
      const productsSum = this.products.map((product) =>
        round(this.productUnitPrice(product.product) * parseFloat(product.quantity), 2)
      );
      return round(sum(productsSum), 2);
    },
    productsTotalPrice() {
      return this.formatCurrency(this.offer.totalPrice || 0, this.offer.currency, false);
    },
    offerDeliveryPrice() {
      return this.formatCurrency(this.offer.deliveryPrice || 0, this.offer.currency, false);
    },
    offerTotalPrice() {
      const deliveryPriceIfUndefined = this.offer.deliveryPrice || 0;
      const totalPrice = this.offer.totalPrice + deliveryPriceIfUndefined;
      return this.formatCurrency(totalPrice, this.offer.currency, false);
    },
  },
  methods: {
    setServiceName(name) {
      if (name === 'wysyłka') return this.$i18n.t('helpers.shipment');
      return name;
    },
    toGenerateOffer() {
      this.$router.push({ name: 'GenerateOffer', params: { offerId: this.offerId } });
    },
    handleModals() {
      if (this.currentProductsPrice !== this.offer.totalPrice) {
        return (this.showDiffTotalPriceModal = true);
      }
      this.showModal = true;
    },
    confirmWithDiffTotalPrice() {
      this.showDiffTotalPriceModal = false;
      this.showModal = true;
    },
    decline() {
      this.showModal = false;
      this.showDiffTotalPriceModal = false;
    },
    confirmForward() {
      this.showModal = false;
      this.addToCart();
    },
    addToCart() {
      const products = this.offer.productsRefs.map((product) => {
        return {
          ...product.product,
          quantity: parseInt(product.quantity),
        };
      });
      this.$store.commit('addProductsToCart', products);
      this.$router.push({ name: 'TransactionSummary' });
    },
    async getOffer() {
      try {
        const response = await OfferService.getOffer(this.offerId);
        this.offer = response.data;
        this.products = response.data.productsRefs;
      } catch (error) {
        console.error(error);
      }
    },
    totalPrice(item) {
      const totalPrice = item.quantity * item.standardPrice;
      return totalPrice.toFixed(2);
    },
  },
  created() {
    const offerId = this.$route.params.offerId;
    this.offerId = offerId;
    this.getOffer();
  },
};
</script>
<style lang="scss" scoped></style>
