import { round, isEqual, gte, gt, inRange, find, forOwn, lt, lte, isEmpty } from 'lodash';
import discountGroups from '../static/discountGroups';
import { userDataComputed, cartComputed, discountsComputed } from '../store/helper';
import { findCompared } from '../utils/comparatorUtils';
import VueI18n from '../plugins/i18n';
import discountTypes from '../static/discountTypes';

export const groupPriceMixin = {
  methods: {
    rangeComparator({ min, max }) {
      return findCompared(min, max, this.quantity);
    },
    getDiscountPriceQuantityGroup() {
      return find(this.product.discountPricesQuanities, this.rangeComparator);
    },
    getGroupPriceByQuantity() {
      const groupPriceName = this.getDiscountPriceQuantityGroup();
      const price = this.userDiscountGroup['price'][groupPriceName.name];
      return gte(price, 0) ? price : this.userDiscountGroup['price']['normal'];
    },
    getPriceGroupRangeDescription(groupName) {
      const { min, max } = find(this.product.discountPricesQuanities, { name: groupName });
      if (max) {
        return `${VueI18n.t('statics.groupPriceMixin.between')} ${min}-${max} ${VueI18n.t(
          'helpers.piecesAmount'
        )}`;
      } else {
        return `${VueI18n.t('statics.groupPriceMixin.between')} ${min}-${
          this.product.stock
        } ${VueI18n.t('helpers.piecesAmount')}`;
      }
    },
    getPriceGroupLimitations(groupName) {
      const { min, max } = find(this.product.discountPricesQuanities, { name: groupName });
      return {
        min,
        max: max ? max : this.product.stock,
      };
    },
    getGroupNameByQuantity(quantity) {
      const group = find(this.product.discountPricesQuanities, ({ min, max }) => {
        return findCompared(min, max, quantity);
      });
      return group?.name;
    },
  },
  computed: {
    ...userDataComputed,
    ...cartComputed,
    ...discountsComputed,
    totalPrice() {
      return round(this.productUnitPrice * this.quantity, 2);
    },
    applySalesmanDiscount() {
      return this.discounts.find((discount) => discount.name === discountTypes.SALESMAN_DISCOUNT)
        ?.value;
    },
    finalPriceByGroupAfterDiscount() {
      const priceWithDiscount =
        this.getGroupPriceByQuantity() -
        this.getGroupPriceByQuantity() * this.applySalesmanDiscount;

      return !isEmpty(this.discounts) ? priceWithDiscount : this.getGroupPriceByQuantity();
    },
    productUnitPrice() {
      return this.userDiscountGroup
        ? this.finalPriceByGroupAfterDiscount
        : this.product.priceAfterDiscount;
    },
    userDiscountGroup() {
      const discountGroup = this.userData.discountGroup ?? discountGroups.BASIC;
      return this.product.discountPrices.find((disc) => disc.name === discountGroup);
    },
    priceGroupAvailable() {
      return this.availableGroupPrices.some((groupPrice) => {
        const {
          limitations: { min, max },
        } = groupPrice;
        return inRange(this.quantity, min, max + 1);
      });
    },
    availableGroupPrices() {
      const { price } = this.userDiscountGroup;
      let availableGroupPrices = [];
      forOwn(price, (price, groupName) => {
        if (gt(price, 0)) {
          availableGroupPrices.push({
            name: groupName,
            label: this.getPriceGroupRangeDescription(groupName),
            limitations: this.getPriceGroupLimitations(groupName),
          });
        }
      });
      return availableGroupPrices;
    },
    nextQuantityAvailable() {
      let nextAvailable = true;
      const nextQuantity = this.quantity + 1;
      if (gt(nextQuantity, this.product.stock)) {
        nextAvailable = false;
      } else {
        const { min, max } = this.getDiscountPriceQuantityGroup();
        const isNextQuantityInActiveGroup = inRange(
          nextQuantity,
          min,
          max ? max + 1 : this.product.stock + 1
        );
        if (!isNextQuantityInActiveGroup) {
          const nextGroupName = this.getGroupNameByQuantity(nextQuantity);
          nextAvailable = this.availableGroupPrices.some(
            (groupPrice) => groupPrice.name === nextGroupName
          );
        }
      }
      return nextAvailable;
    },
    previousQuantityAvailable() {
      let previousAvailable = true;
      const previousQuantity = this.quantity - 1;
      if (lt(previousQuantity, 0)) {
        previousAvailable = false;
      } else {
        const { min, max } = this.getDiscountPriceQuantityGroup();
        const isPreviousQuantityInActiveGroup = inRange(
          previousQuantity,
          min,
          max ? max + 1 : this.product.stock + 1
        );
        if (!isPreviousQuantityInActiveGroup) {
          const previousGroupName = this.getGroupNameByQuantity(previousQuantity);
          previousAvailable = this.availableGroupPrices.some(
            (groupPrice) => groupPrice.name === previousGroupName
          );
        }
      }
      return previousAvailable;
    },
    canAddMoreToCart() {
      let canAddMore = true;
      const product = this.cart.find((product) => isEqual(product._id, this.product._id));
      if (product) {
        const quantitiesSum = product.quantity + this.quantity;
        canAddMore = this.availableGroupPrices.some(({ limitations: { min, max } }) => {
          return gte(quantitiesSum, min) && lte(quantitiesSum, max);
        });
      }
      return canAddMore;
    },
  },
};
