<template>
  <v-expansion-panels class="my-4">
    <v-expansion-panel @click="activateColor">
      <v-expansion-panel-header expand-icon="mdi-menu-down">
        {{ title }}
        <template v-slot:actions>
          <v-icon :color="active ? 'primary' : ''"> $expand </v-icon>
        </template></v-expansion-panel-header
      >
      <v-expansion-panel-content>
        <v-divider class="mb-4"></v-divider>
        <div :class="active ? 'panel-bottom-content-active' : ''"></div>
        <slot />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  name: 'Collapse',
  props: ['title', 'color'],
  data() {
    return {
      active: false,
    };
  },
  methods: {
    activateColor() {
      this.active = !this.active;
      // if (this.active) {
      //   setTimeout(() => {
      //     this.$vuetify.goTo('.panel-bottom-content-active', {
      //       offset: 100,
      //     });
      //   }, 250);
      // }
    },
  },
};
</script>
<style lang="scss" scoped></style>
