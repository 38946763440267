<template>
  <div>
    <nav class="navbar white relative" :class="{ primary: $vuetify.breakpoint.sm }">
      <div class="white static-nav">
        <div class="inner-nav d-flex">
          <div class="d-flex align-center ml-2 ml-sm-0" @click="toHome">
            <nav-logo-section class="pointer"></nav-logo-section>
          </div>
          <v-spacer></v-spacer>
          <nav-links-section v-if="isLoggedIn" :userData="userData"></nav-links-section>
          <div class="switchers">
            <language-switcher class="language-switcher"></language-switcher>
            <currency-switcher v-if="isLoggedIn" class="currency"></currency-switcher>
          </div>
          <v-btn v-if="isLoggedIn" icon @click="logout" class="mr-4 mr-sm-0 logout--desktop">
            <v-icon> $logoutIcon </v-icon>
          </v-btn>
        </div>
      </div>
    </nav>
    <div class="tabs">
      <div class="tabs-wrapper" v-if="showAdminUsers">
        <v-container class="global-spacing pa-0">
          <v-container class="pa-0">
            <v-tabs class="tabs px-4" height="60px" v-model="activeTab" slider-size="4">
              <v-tab class="mr-5" v-for="tab in tabsPerRoles" :key="tab.id" :to="tab.route" exact>{{
                tab.name
              }}</v-tab>
            </v-tabs>
          </v-container>
        </v-container>
      </div>
      <div class="tabs-wrapper" v-if="showAdminStock">
        <v-container class="global-spacing pa-0">
          <v-container class="pa-0">
            <v-tabs class="tabs px-4" height="60px" v-model="activeStockTab" slider-size="4">
              <v-tab class="mr-5" v-for="tab in stockTabs" :key="tab.id" :to="tab.route" exact>{{
                tab.name
              }}</v-tab>
            </v-tabs>
          </v-container>
        </v-container>
      </div>
      <v-container class="global-spacing pb-0">
        <v-container class="pb-0">
          <div class="breadcrumps">
            <v-breadcrumbs
              v-if="matchedLinks.length > 1"
              class="pa-0 mb-0"
              :items="matchedLinks"
              divider="/"
            ></v-breadcrumbs>
          </div>
        </v-container>
      </v-container>
    </div>
  </div>
</template>
<script>
import NavLogoSection from './NavLogoSection.vue';
import NavLinksSection from './NavLinksSection.vue';
import { userDataComputed } from '../store/helper';
import { isEmpty } from 'lodash';
import VueI18n from '../plugins/i18n';
import LanguageSwitcher from './LanguageSwitcher.vue';
import CurrencySwitcher from './CurrencySwitcher.vue';
import { permissionRolesMixin } from '../mixins/permissionRolesMixin';
import { authMixin } from '../mixins/authMixin';

export default {
  name: 'Navbar',
  components: { NavLogoSection, NavLinksSection, LanguageSwitcher, CurrencySwitcher },
  mixins: [permissionRolesMixin, authMixin],
  data() {
    return {
      activeTab: `/admin/uzytkownicy`,
      activeStockTab: '/admin/asortyment',
    };
  },
  methods: {
    nextPath() {
      return !isEmpty(this.userData) ? 'Options' : 'Login';
    },
    toHome() {
      const routeName = this.$route.name;
      if (routeName !== 'Options' && routeName !== 'Login') {
        this.$router.push({ name: this.nextPath() });
      }
    },
  },
  computed: {
    tabs() {
      const navTabs = [
        {
          id: 1,
          name: VueI18n.t('components.navbar.activeTabOne'),
          route: '/admin/uzytkownicy/formularze',
          visible: this.isSysAdmin,
        },
        {
          id: 2,
          name: VueI18n.t('components.navbar.activeTabTwo'),
          route: '/admin/uzytkownicy/firmy',
          visible: this.isSysAdmin || this.isSalesman,
        },
        {
          id: 3,
          name: VueI18n.t('components.navbar.activeTabThree'),
          route: '/admin/uzytkownicy/administratorzy',
          visible: this.isSysAdmin,
        },
      ];
      return navTabs;
    },
    tabsPerRoles() {
      return this.tabs.filter((tab) => {
        return tab.visible;
      });
    },
    stockTabs() {
      const navStockTabs = [
        {
          id: 1,
          name: VueI18n.t('components.navbar.activeStockTabOne'),
          route: '/admin/asortyment/produkty',
        },
        {
          id: 2,
          name: VueI18n.t('components.navbar.activeStockTabTwo'),
          route: '/admin/asortyment/producenci',
        },
      ];
      return navStockTabs;
    },
    ...userDataComputed,
    showAdminUsers() {
      return this.$route.matched.some((route) => route.name === 'AdminUsers');
    },
    showAdminStock() {
      return this.$route.matched.some((route) => route.name === 'Stock');
    },
    matchedLinks() {
      const filteredRoutes = this.$route.matched.filter((route) => {
        return route.parent?.redirect?.name !== route.name;
      });
      const routes = filteredRoutes.map((route) => {
        return {
          text: VueI18n.t(`breadcrumps.${route.name}`),
          link: true,
          exact: true,
          disabled: false,
          to: { name: route.name },
        };
      });
      return routes;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../scss/variables.scss';
.static-nav {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  position: relative;
  z-index: 10;
  padding: 0 1rem;
  @media screen and (max-width: 540px) {
    padding: 0;
  }
  & .inner-nav {
    max-width: 72.5rem;
    width: 100%;
    height: 4.5rem;
    margin: 0 auto;
    position: relative;
  }
}
.tabs-wrapper {
  background: white;
  height: 60px;
}
.v-tab {
  text-transform: none;
}

.tabs {
  z-index: 1;
}

.pointer {
  cursor: pointer;
}
.switchers {
  display: flex;
  @media (max-width: 1350px) {
    display: none;
  }
}
.logout--desktop {
  display: block;
  height: 100%;
  transform: translateX(-1rem);
  &::before {
    right: -1.2rem;
  }
  @media (max-width: 1350px) {
    display: none;
  }
}
</style>
