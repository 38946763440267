<template>
  <svg width="87" height="87" viewBox="0 0 87 87" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="79" height="79" rx="6" stroke="#e7247b" stroke-width="8" />
  </svg>
</template>
<script>
export default {
  name: 'SquareIcon',
};
</script>
