import { render, staticRenderFns } from "./AddSysadmin.vue?vue&type=template&id=3aa8aec7&scoped=true&"
import script from "./AddSysadmin.vue?vue&type=script&lang=js&"
export * from "./AddSysadmin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3aa8aec7",
  null
  
)

export default component.exports