import config from '../../configuration.json';
import axios from 'axios';
import QueryHandler from '../utils/QueryHandler';

const url = `${config.VUE_APP_API_URL}/sysadmin`;

axios.defaults.withCredentials = true;

class SysadminService {
  static async getAllPending(params = {}) {
    const resultUrl = QueryHandler.buildQuery(`${url}/pending`, params);
    const res = await axios.get(resultUrl);
    return res.data;
  }

  static async getAllCompanies(params = {}) {
    const resultUrl = QueryHandler.buildQuery(`${url}/companies`, params);

    const res = await axios.get(resultUrl);
    return res.data;
  }

  static async getAllSysadmins(params = {}) {
    const resultUrl = QueryHandler.buildQuery(`${url}`, params);

    const res = await axios.get(resultUrl);
    return res.data;
  }

  static async accept(id) {
    const res = await axios.get(`${url}/company/activate/${id}`);
    return res.data;
  }

  static async reject(id) {
    const res = await axios.get(`${url}/company/reject/${id}`);
    return res.data;
  }
}

export default SysadminService;
