<template>
  <v-row>
    <v-col>
      <v-container>
        <v-row>
          <v-col
            ><span class="text-h6 font-weight-bold">
              {{ $t('components.orderDetails.orderNr') }}: {{ order.baselinkerId }}
              <v-chip :color="order.status.color" class="white--text">{{
                getTextForUser()
              }}</v-chip>
            </span>
            <v-menu v-if="isSysAdmin" :close-on-content-click="true" :nudge-width="200" offset-x>
              <template v-slot:activator="{ on }">
                <div v-on="on" class="text-decoration-underline ml-2 edit-btn d-inline">
                  {{ $t('helpers.edit') }}
                </div>
              </template>
              <v-card>
                <div v-if="!isForeignOrder">
                  <v-list-item
                    v-for="status in statuses"
                    :key="status.id"
                    class="hover-item-popup"
                    @click="
                      () => {
                        checkChangeStatus(status.id);
                      }
                    "
                  >
                    <v-list-item-content> {{ status.listItemText }} </v-list-item-content>
                  </v-list-item>
                </div>
                <div v-else>
                  <v-list-item
                    v-for="status in foreignStatuses"
                    :key="status.id"
                    class="hover-item-popup"
                    @click="
                      () => {
                        checkChangeStatus(status.id);
                      }
                    "
                  >
                    <v-list-item-content> {{ status.listItemText }} </v-list-item-content>
                  </v-list-item>
                </div>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            ><b>{{ $t('components.orderDetails.deliveryAddress') }}:</b><br />
            <span v-if="!isPickupInPerson">
              {{ order.delivery.address }}, {{ order.delivery.city }}, {{ order.delivery.zipCode }},
              {{ getCountryName(order.delivery.countryCode) }}
            </span>
            <span v-else> {{ $t('statics.deliveryTypes.PICKUP_IN_PERSON') }}</span>
            <p class="mt-2">
              <strong>{{ $t('components.orderDetails.deliveryMethod') }}</strong
              >:<br />
              {{
                order.delivery.type
                  ? $t(`statics.deliveryTypes.${order.delivery.type}`)
                  : $t('helpers.noData')
              }}
            </p>
            <p class="mt-2">
              <strong>{{ $t('components.orderDetails.receiverPhone') }}</strong
              >:<br />
              {{ order.delivery.phone || $t('helpers.noData') }}
            </p>

            <p
              class="mt-2 edit-btn text-decoration-underline"
              v-if="isSysAdmin"
              @click="openEditOrderDialog"
            >
              {{ $t('helpers.edit') }}
            </p>
          </v-col>
          <v-col
            ><b>{{ $t('components.orderDetails.deliveryDate') }}:</b><br />
            {{
              isForeignOrder
                ? $t('statics.deliveryTypes.FOREIGN_DELIVERY')
                : order.delivery.deliveryDate
            }}
            <p class="mt-2">
              <strong>{{ $t('components.orderDetails.deliveryNet') }}</strong
              >:<br />
              {{ formatCurrency(order.delivery.price ? order.delivery.price : 0, order.currency) }}
            </p>
            <b>{{ $t('helpers.shipmentNumber') }}:</b> <br />
            {{ order.shipmentNumber || $t('helpers.noData') }}
          </v-col>
        </v-row>
      </v-container>
    </v-col>
    <v-col>
      <v-container class="text-right">
        <v-row>
          <v-col class="py-1"
            ><b> {{ $t('components.orderDetails.createdAt') }} </b>{{ formatDate(order.date) }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-1"
            ><b> {{ $t('helpers.company') }}:</b>
            {{ company.companyName || $t('helpers.noData') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-1">
            <b>{{ $t('helpers.registerForm') }}:</b>
            {{ company.nip || $t('helpers.noData') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-1"
            ><b>{{ $t('components.orderDetails.worker') }}: </b>{{ order.delivery.firstName }}
            {{ order.delivery.lastName }}</v-col
          ></v-row
        >
        <v-row v-if="isSysAdmin">
          <v-col class="py-1">
            <b>{{ $t('components.orderDetails.additionalFiles') }}:</b>
            {{ returnAdditionalData() }}
          </v-col>
        </v-row>
      </v-container>
    </v-col>
    <change-address-modal
      :open="editOrderModal"
      :title="$t('components.orderDetails.modal.changeAddress.title')"
      :agree="$t('components.orderDetails.modal.changeAddress.agree')"
      :disagree="$t('components.orderDetails.modal.changeAddress.disagree')"
      v-on:decline="onDeclineEditOrder"
      v-on:accept="onAcceptEditOrder"
      :loading="loadingSaveButton"
      v-if="isSysAdmin"
    >
      <v-form v-model="isFormValid" ref="form" class="mt-4">
        <v-row>
          <v-col class="py-0 py-sm-2" cols="12" v-if="!isForeignOrder">
            <h2 class="text font-weight-bold text-color">
              {{ $t('components.orderDetails.modal.changeAddress.formDeliveryDate') }}
            </h2>
            <v-date-picker
              v-model="delivery.deliveryDate"
              required
              class="mt-2"
              full-width
              :locale="selectedLanguage"
              firstDayOfWeek="1"
              :min="getMinimumDeliveryDate()"
              :allowed-dates="allowedDates"
            ></v-date-picker>
          </v-col>
          <v-col class="py-0 py-sm-2" cols="12" v-if="!isForeignOrder">
            <delivery-options
              @delivery-option-changed="deliveryChange"
              :loading="deliveryLoading"
              :delivery-price="deliveryPrice"
              :radios="deliveryType"
              :disableRadios="isForeignOrder"
            ></delivery-options>
          </v-col>
          <v-col class="py-0 py-sm-2" cols="12">
            <h2 class="text font-weight-bold text-color">
              {{ $t('helpers.firstName') }}
            </h2>
            <v-text-field
              v-model="delivery.firstName"
              outlined
              hide-details="auto"
              :rules="validators.requiredRules"
              solo
              elevation="0"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <h2 class="text font-weight-bold text-color">
              {{ $t('helpers.lastName') }}
            </h2>
            <v-text-field
              v-model="delivery.lastName"
              outlined
              hide-details="auto"
              :rules="validators.requiredRules"
              solo
              elevation="0"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <h2 class="text font-weight-bold text-color">{{ $t('helpers.street') }}</h2>
            <v-text-field
              v-model="delivery.address"
              outlined
              hide-details="auto"
              :rules="validators.requiredRules"
              solo
              elevation="0"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <h2 class="text font-weight-bold text-color">{{ $t('helpers.zipCode') }}</h2>
            <v-text-field
              v-model="delivery.zipCode"
              outlined
              hide-details="auto"
              :rules="validators.requiredRules"
              solo
              elevation="0"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <h2 class="text font-weight-bold text-color">{{ $t('helpers.city') }}</h2>
            <v-text-field
              v-model="delivery.city"
              outlined
              hide-details="auto"
              :rules="validators.requiredRules"
              solo
              elevation="0"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <h2 class="text font-weight-bold text-color">
              {{ $t('helpers.shipmentNumber') }}
            </h2>
            <v-text-field v-model="shipmentNumber" outlined hide-details="auto" solo elevation="0">
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <h2 class="text font-weight-bold text-color">
              {{ $t('helpers.phoneNumber') }}
            </h2>
            <v-text-field
              disabled
              v-model="delivery.phone"
              outlined
              hide-details="auto"
              solo
              elevation="0"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </change-address-modal>
    <confirm-modal
      @accept="confirmShipmentNumber"
      @decline="closeShipmentModalAndResetDelivery"
      @optionalAction="continueWihoutShipmentNumber"
      :showOptionalAction="true"
      :title="$t('components.orderDetails.modal.confirm.title')"
      :open="showShipmentModal"
      :agree="$t('components.orderDetails.modal.confirm.agree')"
      :optionalActionLabel="$t('components.orderDetails.modal.confirm.optionalLabel')"
    >
      <v-text-field
        class="text-h6 mt-4"
        name="shipmentNumber"
        type="text"
        :placeholder="$t('helpers.shipmentNumber')"
        v-model="shipmentNumber"
        outlined
        elavation="0"
      >
      </v-text-field>
    </confirm-modal>
  </v-row>
</template>
<script>
import { formatDate } from '../mixins/formatDate.js';
import { holidayMixin } from '../mixins/holidays.js';
import { countriesCodeHandler } from '../mixins/countriesCodeHandler.js';
import { userDataComputed, selectedLanguageComputed } from '../store/helper.js';
import OrderService from '../services/OrderService.js';
import {
  CLIENT_ORDER_STATUSES,
  FOREIGN_CLIENT_STATUSES,
  SENT,
  STATUSES,
} from '../static/orderStatuses';
import ChangeAddressModal from './modals/ChangeAddressModal.vue';
import { validators } from '../validators/Validators.js';
import { formatCurrency } from '../mixins/formatCurrency.js';
import DeliveryOptions from '../components/DeliveryOptions.vue';
import DeliveryService from '../services/DeliveryService.js';
import { deliveryTypes } from '../static/deliveryTypes';
import ConfirmModal from '../components/modals/ConfirmModal.vue';
import { formMixin } from '../mixins/formMixin';
import { permissionRolesMixin } from '../mixins/permissionRolesMixin.js';

export default {
  name: 'OrderDetails',
  components: { ChangeAddressModal, DeliveryOptions, ConfirmModal },
  mixins: [
    formatDate,
    holidayMixin,
    formatCurrency,
    countriesCodeHandler,
    formMixin,
    permissionRolesMixin,
  ],
  props: {
    order: {
      type: Object,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deliveryError: false,
      deliveryLoading: true,
      deliveryPrice: 0,
      deliveryType: '',
      editOrderModal: false,
      isFormValid: false,
      loadingSaveButton: false,
      showShipmentModal: false,
      shipmentNumber: '',
      additionalData: undefined,
      delivery: {
        deliveryDate: '',
        firstName: '',
        lastName: '',
        address: '',
        zipCode: '',
        city: '',
        name: '',
        price: '',
        phone: '',
        type: '',
      },
    };
  },
  methods: {
    returnAdditionalData() {
      return this.additionalData === 'błąd:Brak kontrahenta'
        ? this.$i18n.t('helpers.noData')
        : this.additionalData;
    },
    getTextForUser() {
      return this.orderStatuses.find((item) => item.id === this.order.status.id).text;
    },
    async calculateDelivery() {
      this.deliveryLoading = true;

      try {
        const response = await DeliveryService.getDeliveryPrice(this.order.items);
        this.deliveryPrice = response.deliveryPrice;
        this.deliveryError = false;
      } catch (error) {
        this.$toasted.global.error({
          message: this.$i18n.t('components.orderDetails.toasted.calculateDeliveryFunc'),
        });
        this.deliveryError = true;
      } finally {
        this.deliveryLoading = false;
      }
    },
    getDeliveryObjectFromValue(value, price) {
      if (value === this.deliveryTypes.PICKUP_IN_PERSON.type) {
        this.delivery = { ...this.delivery, ...this.deliveryTypes.PICKUP_IN_PERSON };
      } else if (value === this.deliveryTypes.TO_CUSTOMER_DELIVERY.type) {
        this.delivery = {
          ...this.delivery,
          ...this.deliveryTypes.TO_CUSTOMER_DELIVERY,
          price: price,
        };
      } else {
        this.delivery = { ...this.delivery, ...this.deliveryTypes.FOREIGN_DELIVERY };
      }
    },
    deliveryChange(value) {
      this.deliveryType = value;
      this.getDeliveryObjectFromValue(value, this.deliveryPrice);
    },
    async checkChangeStatus(newStatus) {
      if (newStatus === SENT().id && !this.shipmentNumber) {
        this.openShipmentModal();
      } else {
        await this.changeStatus(newStatus);
      }
    },
    updateAndNotify() {
      this.$emit('updateOrder');
      this.$toasted.global.success({
        message: this.$i18n.t('components.orderDetails.toasted.updateAndNotifyFunc'),
      });
    },
    async changeStatus(newStatus) {
      try {
        await OrderService.updateStatus(this.order.id, newStatus);
        await this.updateAndNotify();
      } catch (err) {
        console.error(err);
      }
    },
    async changeOrderStatusToSent() {
      try {
        await OrderService.updateStatus(this.order.id, SENT().id);
        this.closeShipmentModal();
        this.updateAndNotify();
      } catch (err) {
        console.error(err);
      }
    },
    openShipmentModal() {
      this.showShipmentModal = true;
    },
    closeShipmentModal() {
      this.showShipmentModal = false;
    },
    continueWihoutShipmentNumber() {
      this.changeOrderStatusToSent();
      this.closeShipmentModalAndResetDelivery();
    },
    closeShipmentModalAndResetDelivery() {
      this.closeShipmentModal();
      this.setDelivery();
    },
    async confirmShipmentNumber() {
      if (this.shipmentNumber) {
        try {
          await OrderService.updateOrder(this.order.id, {
            ...this.order,
            shipmentNumber: this.shipmentNumber,
          });
          await this.changeOrderStatusToSent();
        } catch (error) {
          console.error(error);
        }
      }
    },
    onDeclineEditOrder() {
      this.setDelivery();
      this.editOrderModal = false;
    },
    async onAcceptEditOrder() {
      try {
        this.loadingSaveButton = true;
        this.$refs.form.validate();
        if (this.isFormValid) {
          await OrderService.updateOrder(this.order.id, {
            ...this.order,
            shipmentNumber: this.shipmentNumber,
            delivery: this.delivery,
          });
          this.editOrderModal = false;
          this.$emit('updateOrder');
          this.$toasted.global.success({
            message: this.$i18n.t('components.orderDetails.toasted.onAcceptEditOrderFunc'),
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.loadingSaveButton = false;
      }
    },
    setDelivery() {
      this.delivery = { ...this.order.delivery };
      this.shipmentNumber = this.order.shipmentNumber;
      this.deliveryType = this.delivery.type;
    },
    async fetchBaselinkerExtraFieldOne() {
      try {
        const fieldOne = await OrderService.getBaselinkerExtraFiledOne(this.order.baselinkerId);
        this.additionalData = fieldOne;
      } catch (err) {
        console.error(err);
      }
    },
    openEditOrderDialog() {
      this.editOrderModal = true;
    },
  },
  computed: {
    ...userDataComputed,
    ...selectedLanguageComputed,
    deliveryTypes() {
      return deliveryTypes();
    },
    statuses() {
      return CLIENT_ORDER_STATUSES();
    },
    foreignStatuses() {
      return FOREIGN_CLIENT_STATUSES();
    },
    orderStatuses() {
      return STATUSES();
    },
    validators() {
      return validators;
    },
    isPickupInPerson() {
      return this.deliveryType === this.deliveryTypes.PICKUP_IN_PERSON.type;
    },
    isForeignOrder() {
      return this.foreignDelivery(this.order.delivery.countryCode);
    },
  },
  async created() {
    this.setDelivery();
    if (this.isSysAdmin && !this.isForeignOrder) this.fetchBaselinkerExtraFieldOne();
    this.calculateDelivery();
  },
};
</script>
<style lang="scss" scoped>
.edit-btn {
  cursor: pointer;
}
</style>
