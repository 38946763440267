<template>
  <title-wrapper :title="$t('components.wizard.rafterSpacing.title')">
    <selectable-section
      :label="$t('components.wizard.rafterSpacing.selectLabel')"
      :items="rafterSpacings"
      storeAction="setRafterSpacing"
      :active="rafterSpacing"
      :preview="true"
    ></selectable-section>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../components/TitleWrapper.vue';
import SelectableSection from '../../../components/SelectableSection.vue';
import { rafterSpacingComputed } from '../../../store/helper.js';
import { RAFTER_SPACING } from '../../../static/fotoConstants.js';
import krokwie80 from '@/assets/wizzardImages/krokwie80.png';
import VueI18n from '../../../plugins/i18n';

export default {
  name: 'RafterSpacing',
  components: { TitleWrapper, SelectableSection },
  data() {
    return {
      rafterSpacings: [
        {
          value: RAFTER_SPACING.CM80,
          text: VueI18n.t('statics.rafterSpacings.CM80'),
          imgPath: krokwie80,
        },
      ],
    };
  },
  computed: {
    ...rafterSpacingComputed,
  },
  activated() {
    this.$emit('isFowardEnabled', true);
  },
};
</script>
<style lang="scss" scoped></style>
