<template>
  <title-wrapper :title="$t('components.wizard.screwType.title')">
    <clickable-section
      v-if="handleTypes[pourType]"
      :items="handleTypes[pourType]"
      @updateSelected="updateSelected"
      :active="selectedScrewType"
    ></clickable-section>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../components/TitleWrapper.vue';
import ClickableSection from '../../../components/ClickableSection.vue';
import { screwTypeComputed, pourTypeComputed } from '../../../store/helper.js';
import { SCREW_TYPES } from '../../../static/fotoConstants.js';
import srubaZGwintemPodwojnym from '@/assets/wizzardImages/srubaZGwintemPodwojnym.png';

export default {
  name: 'ScrewType',
  components: { TitleWrapper, ClickableSection },
  data() {
    return {
      selectedScrewType: '',
      isValid: false,
      storeAction: 'setScrewType',
      handleTypes: {
        Blachodachówka: [
          {
            value: SCREW_TYPES.M10200,
            text: SCREW_TYPES.M10200,
            imgPath: srubaZGwintemPodwojnym,
          },
          {
            value: SCREW_TYPES.M10250,
            text: SCREW_TYPES.M10250,
            imgPath: srubaZGwintemPodwojnym,
          },
          {
            value: SCREW_TYPES.M10300,
            text: SCREW_TYPES.M10300,
            imgPath: srubaZGwintemPodwojnym,
          },
        ],
      },
    };
  },
  mounted() {
    if (this.screwType) {
      this.selectedScrewType = this.screwType;
      this.isValid = true;
    }
  },
  watch: {
    isValid: function (val) {
      this.$emit('isFowardEnabled', val);
    },
  },
  methods: {
    updateSelected(screwType) {
      this.selectedScrewType = screwType;
      this.$store.commit(this.storeAction, this.selectedScrewType);
      this.isValid = true;
    },
  },
  computed: {
    ...screwTypeComputed,
    ...pourTypeComputed,
  },
  activated() {
    if (this.selectedScrewType) {
      this.$emit('isFowardEnabled', true);
    } else {
      this.$emit('isFowardEnabled', false);
    }
  },
};
</script>
<style lang="scss" scoped></style>
