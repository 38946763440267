import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { supportedLangs } from '../static/supportedLangs';
Vue.use(VueI18n);

const languages = Object.getOwnPropertyNames(loadLocaleMessages());

export const supportedLanguages = [];

function loadLocaleMessages() {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
const browserLocale = navigator.language;

function checkLocalStorage() {
  const vuexLocalStorage = localStorage.getItem('vuex');

  if (vuexLocalStorage) {
    const { userData, locale } = JSON.parse(vuexLocalStorage);
    return userData ? userData.lang : locale;
  } else return null;
}

function checkDefaultLanguage() {
  let matched = null;

  languages.forEach((lang) => {
    const langObj = {
      value: supportedLangs[lang].i18nCode,
      text: supportedLangs[lang].textForUser,
      flagCode: supportedLangs[lang].flagCode,
    };
    supportedLanguages.push(langObj);
  });
  const setByBrowserLocale = supportedLanguages.some((item) => item.value === browserLocale)?.value;

  matched = checkLocalStorage() || setByBrowserLocale || null;

  if (!matched) {
    languages.forEach((lang) => {
      let languagePartials = browserLocale.split('-')[0];
      if (lang === languagePartials || lang.split('-')[0] === languagePartials) {
        matched = lang;
      }
    });
  }

  return matched;
}
export const selectedLocale = checkDefaultLanguage() || process.env.VUE_APP_I18N_LOCALE || 'pl';
export default new VueI18n({
  locale: selectedLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'pl',
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
});
