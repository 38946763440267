<template>
  <title-link-wrapper
    :title="$t('components.addCompanyUser')"
    :to="{ name: 'SingleCompany', params: { companyId: this.$route.params.companyId } }"
  >
    <add-user
      to-details="SingleCompanyUser"
      :user-roles="['Pracownik', 'Admin firmy']"
      :add-func="addFunc"
    ></add-user>
  </title-link-wrapper>
</template>
<script>
import AddUser from '../../../../components/AddUser.vue';
import TitleLinkWrapper from '../../../../components/TitleLinkWrapper.vue';
import AuthService from '../../../../services/AuthService';

export default {
  name: 'AddCompanyUser',
  components: { AddUser, TitleLinkWrapper },
  data() {
    return {
      userRole: 'Administrator firmy',
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      isFormValid: false,
      loading: false,
    };
  },

  methods: {
    async addFunc(user) {
      user.companyId = this.$route.params.companyId;
      return await AuthService.registerUser(user);
    },
  },
};
</script>
<style lang="scss" scoped></style>
