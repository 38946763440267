<template>
  <title-wrapper :title="$t('components.wizard.pourType.title')">
    <selectable-section
      :label="$t('components.wizard.pourType.selectLabel')"
      :items="pourTypes"
      storeAction="setPourType"
      :active="pourType"
      :preview="true"
    ></selectable-section>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../components/TitleWrapper.vue';
import SelectableSection from '../../../components/SelectableSection.vue';
import { pourTypeComputed } from '../../../store/helper.js';
import { POUR_TYPE } from '../../../static/fotoConstants.js';
import pokrycieBlachodachowka from '@/assets/wizzardImages/pokrycieBlachodachowka.png';
import pokrycieBlachaTrapezowa from '@/assets/wizzardImages/pokrycieBlachaTrapezowa.png';
import pokrycieCeramika from '@/assets/wizzardImages/pokrycieCeramika.png';
import pokrycieKarpiowka from '@/assets/wizzardImages/pokrycieKarpiowka.png';
import pokrycieGont from '@/assets/wizzardImages/pokrycieGont.png';
import pokrycieRabek from '@/assets/wizzardImages/pokrycieRabek.png';
import VueI18n from '../../../plugins/i18n';

export default {
  name: 'PourType',
  components: { TitleWrapper, SelectableSection },
  data() {
    return {
      pourTypes: [
        {
          value: POUR_TYPE.BLACHODACHOWKA,
          text: VueI18n.t('statics.pourType.BLACHODACHOWKA'),
          imgPath: pokrycieBlachodachowka,
        },
        {
          value: POUR_TYPE.BLACHA_TRAPEZOWA,
          text: VueI18n.t('statics.pourType.BLACHA_TRAPEZOWA'),
          imgPath: pokrycieBlachaTrapezowa,
        },
        {
          value: POUR_TYPE.DACHOWKA_CERAMICZNA,
          text: VueI18n.t('statics.pourType.DACHOWKA_CERAMICZNA'),
          imgPath: pokrycieCeramika,
        },
        {
          value: POUR_TYPE.KARPIOWKA,
          text: VueI18n.t('statics.pourType.KARPIOWKA'),
          imgPath: pokrycieKarpiowka,
        },
        {
          value: POUR_TYPE.GONT,
          text: VueI18n.t('statics.pourType.GONT'),
          imgPath: pokrycieGont,
        },
        {
          value: POUR_TYPE.RABEK,
          text: VueI18n.t('statics.pourType.RABEK'),
          imgPath: pokrycieRabek,
        },
      ],
    };
  },
  activated() {
    this.$emit('isFowardEnabled', true);
  },
  computed: {
    ...pourTypeComputed,
  },
};
</script>
<style lang="scss" scoped></style>
