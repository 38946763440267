import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/store';
import { roles } from '../static/roles';
import { isEmpty } from 'lodash';
import { externalOrganizations } from '../static/externalOrganizations';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
  },
  {
    path: '/logowanie',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/rejestracja',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
  },
  {
    path: '/wynik-aktywacji',
    name: 'ActivationSuccessful',
    component: () => import(/* webpackChunkName: "login" */ '../views/Activation/Thanks.vue'),
  },
  {
    path: '/rejestracja-udana',
    name: 'RegistrationSuccessful',
    component: () => import(/* webpackChunkName: "login" */ '../views/RegistrationSuccessful.vue'),
  },
  {
    path: '/aktywacja/:userId',
    name: 'ActivationSuccessful',
    component: () => import(/* webpackChunkName: "login" */ '../views/Activation/Activate.vue'),
  },
  {
    path: '/aktywuj-konto/:userId',
    name: 'ActivateAccount',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Activation/ActivatePassword.vue'),
  },
  {
    path: '/warunki-przetwarzania-danych-osobowych',
    name: 'ProcessingPersonalData',
    component: () =>
      import(
        /* webpackChunkName: "conditions of processing personal data" */ '../views/PrivacyPolicy/ProcessingPersonalData.vue'
      ),
  },
  {
    path: '/warunki-platnosci',
    name: 'ConditionsOfPayment',
    component: () =>
      import(
        /* webpackChunkName: "conditions of payment" */ '../views/PrivacyPolicy/ConditionsOfPayment.vue'
      ),
  },
  {
    path: '/warunki-realizacji-zamowien',
    name: 'TermsOfOrderFulfillment',
    component: () =>
      import(
        /* webpackChunkName: "terms of order fulfillment" */ '../views/PrivacyPolicy/TermsOfOrderFulfillment.vue'
      ),
  },
  {
    path: '/wybor-opcji',
    name: 'Options',
    meta: {
      requiresAuth: true,
      authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
    },
    component: () => import(/* webpackChunkName: "register" */ '../views/Main.vue'),
  },
  {
    path: '/reset-email',
    name: 'PasswordReset',
    component: () =>
      import(/* webpackChunkName: "register" */ '../views/ResetPassword/ResetPassword.vue'),
  },
  {
    path: '/reset-hasla/:token',
    name: 'SetPassword',
    component: () =>
      import(/* webpackChunkName: "register" */ '../views/ResetPassword/SetPassword.vue'),
  },
  {
    path: '/haslo-zmienione',
    name: 'PasswordChanged',
    component: () =>
      import(/* webpackChunkName: "register" */ '../views/ResetPassword/PasswordChanged.vue'),
  },
  {
    path: '/zmiana-hasla-email',
    name: 'PasswordMailSent',
    component: () =>
      import(/* webpackChunkName: "register" */ '../views/ResetPassword/PasswordResetSent.vue'),
  },
  {
    path: '/kategorie',
    name: 'Categories',
    meta: {
      requiresAuth: true,
      authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
    },
    component: () => import(/* webpackChunkName: "register" */ '../views/Categories.vue'),
  },
  {
    path: '/zestawy',
    name: 'Sets',
    meta: {
      requiresAuth: true,
      authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
    },
    component: () => import(/* webpackChunkName: "register" */ '../views/Sets.vue'),
  },
  {
    path: '/katalog',
    name: 'Catalog',
    meta: {
      requiresAuth: true,
      authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
    },
    redirect: { name: 'CatalogList' },
    // route level code-splitting
    // this generates a separate chunk (stock.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "stock" */ '../views/Catalog/CatalogIndex.vue'),
    children: [
      {
        path: '',
        name: 'CatalogList',
        meta: {
          requiresAuth: true,
          authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
        },
        // route level code-splitting
        // this generates a separate chunk (products.[hash].js) for this route
        // which is lazy-loaded when the route is visited.

        component: () => import(/* webpackChunkName: "products" */ '../views/Catalog/Catalog.vue'),
      },
      {
        path: ':productId',
        name: 'CatalogItem',
        meta: {
          requiresAuth: true,
          authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
        },
        // route level code-splitting
        // this generates a separate chunk (products.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "products" */ '../views/Catalog/CatalogItem.vue'),
      },
    ],
  },
  {
    path: '/doradca',
    name: 'Wizard',
    redirect: { name: 'StartWizard' },
    meta: {
      requiresAuth: true,
      authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
    },
    // route level code-splitting
    // this generates a separate chunk (edit-account.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "edit-account" */ '../views/Wizard/WizardIndex.vue'),
    children: [
      {
        path: 'start',
        name: 'StartWizard',
        meta: {
          requiresAuth: true,
          authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
        },
        component: () =>
          import(/* webpackChunkName: "edit-account" */ '../views/Wizard/StartWizard.vue'),
      },
      {
        path: 'deklarowana-moc',
        name: 'DeclaredPower',
        meta: {
          requiresAuth: true,
          authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
        },

        // route level code-splitting
        // this generates a separate chunk (edit-account.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "edit-account" */ '../views/Wizard/DeclaredPower.vue'),
      },
      {
        path: 'moduly',
        name: 'Modules',
        meta: {
          requiresAuth: true,
          authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
        },

        // route level code-splitting
        // this generates a separate chunk (edit-account.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "edit-account" */ '../views/Wizard/Modules.vue'),
      },
      {
        path: 'inwertery',
        name: 'Inverters',
        meta: {
          requiresAuth: true,
          authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
        },

        // route level code-splitting
        // this generates a separate chunk (edit-account.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "edit-account" */ '../views/Wizard/Inverters.vue'),
      },
      {
        path: 'optymalizatory',
        name: 'Optimizers',
        meta: {
          requiresAuth: true,
          authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
        },

        // route level code-splitting
        // this generates a separate chunk (edit-account.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "edit-account" */ '../views/Wizard/Optimizers.vue'),
      },
      {
        path: ':productId',
        name: 'WizardItemDetails',
        meta: {
          requiresAuth: true,
          authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
        },
        // route level code-splitting
        // this generates a separate chunk (products.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "wizard-item-details" */ '../views/Wizard/WizardItemDetails.vue'
          ),
      },
      {
        path: 'konstrukcja-dachowa',
        name: 'RoofConstruction',
        redirect: { name: 'RoofType' },
        meta: {
          requiresAuth: true,
          authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
        },

        // route level code-splitting
        // this gene rates a separate chunk (edit-account.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "edit-account" */ '../views/Wizard/RoofConstruction.vue'),

        children: [
          {
            path: 'typ-dachu',
            name: 'RoofType',
            meta: {
              requiresAuth: true,
              authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
            },

            // route level code-splitting
            // this generates a separate chunk (edit-account.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(/* webpackChunkName: "edit-account" */ '../views/Wizard/Roof/RoofType.vue'),
          },
          {
            path: 'polac-dachu',
            name: 'PourType',
            meta: {
              requiresAuth: true,
              authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
            },

            // route level code-splitting
            // this generates a separate chunk (edit-account.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(/* webpackChunkName: "edit-account" */ '../views/Wizard/Roof/PourType.vue'),
          },
          {
            path: 'typ-sruby',
            name: 'ScrewType',
            meta: {
              requiresAuth: true,
              authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
            },

            // route level code-splitting
            // this generates a separate chunk (edit-account.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(/* webpackChunkName: "edit-account" */ '../views/Wizard/Roof/ScrewType.vue'),
          },
          {
            path: 'uchwyt-dachowy',
            name: 'HandleType',
            meta: {
              requiresAuth: true,
              authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
            },

            // route level code-splitting
            // this generates a separate chunk (edit-account.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(/* webpackChunkName: "edit-account" */ '../views/Wizard/Roof/HandleType.vue'),
          },
          {
            path: 'wkret-tcs',
            name: 'TcsType',
            meta: {
              requiresAuth: true,
              authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
            },

            // route level code-splitting
            // this generates a separate chunk (edit-account.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(/* webpackChunkName: "edit-account" */ '../views/Wizard/Roof/TcsType.vue'),
          },
          {
            path: 'rozstaw-krokwi',
            name: 'RafterSpacing',
            meta: {
              requiresAuth: true,
              authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
            },

            // route level code-splitting
            // this generates a separate chunk (edit-account.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "edit-account" */ '../views/Wizard/Roof/RafterSpacing.vue'
              ),
          },
          {
            path: 'orientacja-paneli',
            name: 'PanelOrientation',
            meta: {
              requiresAuth: true,
              authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
            },

            // route level code-splitting
            // this generates a separate chunk (edit-account.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "edit-account" */ '../views/Wizard/Roof/PanelOrientation.vue'
              ),
          },
          {
            path: 'ilosc-rzedow',
            name: 'RowsNumber',
            meta: {
              requiresAuth: true,
              authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
            },

            // route level code-splitting
            // this generates a separate chunk (edit-account.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(/* webpackChunkName: "edit-account" */ '../views/Wizard/Roof/RowsNumber.vue'),
          },
          {
            path: 'ilosc-modulow',
            name: 'ModuleNumber',
            meta: {
              requiresAuth: true,
              authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
            },
            // route level code-splitting
            // this generates a separate chunk (edit-account.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "edit-account" */ '../views/Wizard/Roof/ModuleNumber.vue'
              ),
          },
          {
            path: 'rodzaj-klem',
            name: 'ClampType',
            meta: {
              requiresAuth: true,
              authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
            },
            // route level code-splitting
            // this generates a separate chunk (edit-account.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(/* webpackChunkName: "edit-account" */ '../views/Wizard/Roof/ClampType.vue'),
          },
        ],
      },
      {
        path: 'akcesoria-montazowe',
        name: 'MountingAccessories',
        meta: {
          requiresAuth: true,
          authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
        },
        // route level code-splitting
        // this generates a separate chunk (edit-account.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "edit-account" */ '../views/Wizard/MountingAccessories.vue'),
      },
      {
        path: 'osprzet',
        name: 'ElectricalInstallationEquipment',
        meta: {
          requiresAuth: true,
          authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
        },
        // route level code-splitting
        // this generates a separate chunk (edit-account.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "edit-account" */ '../views/Wizard/ElectricalInstallationEquipment.vue'
          ),
      },
      {
        path: 'podsumowanie',
        name: 'WizardSummary',
        meta: {
          requiresAuth: true,
          authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
        },
        // route level code-splitting
        // this generates a separate chunk (edit-account.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "edit-account" */ '../views/Wizard/WizardSummary.vue'),
      },
    ],
  },
  {
    path: '/edycja-konta',
    name: 'EditAccount',
    meta: {
      requiresAuth: true,
      authorize: [roles.EMPLOYEE, roles.COMPANY_ADMIN, roles.SYSADMIN, roles.SALESMAN],
    },
    // route level code-splitting
    // this generates a separate chunk (edit-account.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "edit-account" */ '../views/User/EditAccount.vue'),
  },
  {
    path: '/zarzadzaj-firma',
    name: 'ManageCompany',
    meta: {
      requiresAuth: true,
      authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN],
    },
    // route level code-splitting
    // this generates a separate chunk (dit-company.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "edit-company-manage" */ '../views/User/EditCompany/ManageCompany.vue'
      ),
  },
  {
    path: '/podsumowanie',
    name: 'TransactionSummary',
    meta: {
      requiresAuth: true,
      authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN, roles.EMPLOYEE, roles.SALESMAN],
    },
    component: () =>
      import(/* webpackChunkName: "edit-company-manage" */ '../views/Summary/Index.vue'),
  },
  {
    path: '/edycja-uzytkownikow',
    name: 'EditCompanyEmployees',
    redirect: { name: 'ManageUsers' },
    meta: {
      requiresAuth: true,
      authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN],
    },
    // route level code-splitting
    // this generates a separate chunk (edit-company.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "edit-company" */ '../views/User/EditCompany/Index.vue'),
    children: [
      {
        path: '',
        name: 'ManageUsers',
        meta: {
          requiresAuth: true,
          authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN],
        },
        // route level code-splitting
        // this generates a separate chunk (edit-company.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "edit-company-users" */ '../views/User/EditCompany/ManageUsers.vue'
          ),
      },
      {
        path: ':userId',
        name: 'UserDetails',
        meta: {
          requiresAuth: true,
          authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN],
        },
        // route level code-splitting
        // this generates a separate chunk (edit-company.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "edit-company-users" */ '../views/User/EditCompany/UserDetails.vue'
          ),
      },
      {
        path: ':userId/edytuj',
        name: 'EditUser',
        meta: {
          requiresAuth: true,
          authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN],
        },
        // route level code-splitting
        // this generates a separate chunk (edit-company.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "edit-company-manage" */ '../views/User/EditCompany/EditUser.vue'
          ),
      },
      {
        path: 'dodaj',
        name: 'AddUser',
        meta: {
          requiresAuth: true,
          authorize: [roles.COMPANY_ADMIN, roles.SYSADMIN],
        },
        // route level code-splitting
        // this generates a separate chunk (edit-company.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "edit-company-manage" */ '../views/User/EditCompany/AddUser.vue'
          ),
      },
    ],
  },
  {
    path: '/handlowiec/uzytkownicy',
    name: 'SalesmanAllCompanies',
    redirect: { name: 'SalesmanCompanies' },
    meta: {
      requiresAuth: true,
      authorize: [roles.SYSADMIN, roles.SALESMAN],
    },
    // route level code-splitting
    // this generates a separate chunk (salesman-users.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "salesman-users" */ '../views/Salesman/Index.vue'),
    children: [
      {
        path: '/zarządzaj-firmami',
        name: 'SalesmanCompanies',
        redirect: { name: 'SalesmanManageCompanies' },
        meta: {
          requiresAuth: true,
          authorize: [roles.SYSADMIN, roles.SALESMAN],
        },
        // route level code-splitting
        // this generates a separate chunk (sysadmin-companies.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "sysadmin-companies" */ '../views/SysAdmin/Users/Companies/Index.vue'
          ),
        children: [
          {
            path: '',
            name: 'SalesmanManageCompanies',
            meta: {
              requiresAuth: true,
              authorize: [roles.SYSADMIN, roles.SALESMAN],
            },
            // route level code-splitting
            // this generates a separate chunk (sysadmin-companies.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "sysadmin-companies" */ '../views/SysAdmin/Users/Companies/ManageCompanies.vue'
              ),
          },
          {
            path: ':companyId',
            name: 'SalesmanSingleCompany',
            meta: {
              requiresAuth: true,
              authorize: [roles.SYSADMIN, roles.SALESMAN],
            },
            // route level code-splitting
            // this generates a separate chunk (sysadmin-single-company.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "sysadmin-single-company" */ '../views/SysAdmin/Users/Companies/SingleCompany.vue'
              ),
          },
        ],
      },
    ],
  },

  {
    path: '/admin/uzytkownicy',
    name: 'AdminUsers',
    redirect: { name: 'RegistrationForms' },
    meta: {
      requiresAuth: true,
      authorize: [roles.SYSADMIN],
    },
    // route level code-splitting
    // this generates a separate chunk (admin-users.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "admin-users" */ '../views/SysAdmin/Users/Index.vue'),
    children: [
      {
        path: 'formularze',
        name: 'RegistrationForms',
        meta: {
          requiresAuth: true,
          authorize: [roles.SYSADMIN, roles.SALESMAN],
        },
        // route level code-splitting
        // this generates a separate chunk (registration-forms.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "registration-forms" */ '../views/SysAdmin/Users/RegistrationForms/ManageForms.vue'
          ),
      },
      {
        path: 'firmy',
        name: 'Companies',
        redirect: { name: 'ManageCompanies' },
        meta: {
          requiresAuth: true,
          authorize: [roles.SYSADMIN, roles.SALESMAN],
        },
        // route level code-splitting
        // this generates a separate chunk (sysadmin-companies.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "sysadmin-companies" */ '../views/SysAdmin/Users/Companies/Index.vue'
          ),
        children: [
          {
            path: '',
            name: 'ManageCompanies',
            meta: {
              requiresAuth: true,
              authorize: [roles.SYSADMIN, roles.SALESMAN],
            },
            // route level code-splitting
            // this generates a separate chunk (sysadmin-companies.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "sysadmin-companies" */ '../views/SysAdmin/Users/Companies/ManageCompanies.vue'
              ),
          },
          {
            path: 'dodaj',
            name: 'AddCompany',
            meta: {
              requiresAuth: true,
              authorize: [roles.SYSADMIN, roles.SALESMAN],
            },
            // route level code-splitting
            // this generates a separate chunk (sysadmin-companies.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "sysadmin-companies" */ '../views/SysAdmin/Users/Companies/AddCompany.vue'
              ),
          },
          {
            path: ':companyId',
            name: 'SingleCompany',
            meta: {
              requiresAuth: true,
              authorize: [roles.SYSADMIN, roles.SALESMAN],
            },
            // route level code-splitting
            // this generates a separate chunk (sysadmin-single-company.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "sysadmin-single-company" */ '../views/SysAdmin/Users/Companies/SingleCompany.vue'
              ),
          },

          {
            path: ':companyId/:userId',
            name: 'SingleCompanyUser',
            meta: {
              requiresAuth: true,
              authorize: [roles.SYSADMIN, roles.SALESMAN],
            },
            // route level code-splitting
            // this generates a separate chunk (sysadmin-single-company.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "sysadmin-single-company" */ '../views/SysAdmin/Users/Users/UserCompanyDetails.vue'
              ),
          },
          {
            path: ':companyId/:userId/edytuj',
            name: 'EditCompanyUser',
            meta: {
              requiresAuth: true,
              authorize: [roles.SYSADMIN, roles.SALESMAN],
            },
            // route level code-splitting
            // this generates a separate chunk (sysadmin-single-company.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "sysadmin-single-company" */ '../views/SysAdmin/Users/Users/EditCompanyUser.vue'
              ),
          },
          {
            path: ':companyId/dodaj',
            name: 'AddCompanyUser',
            meta: {
              requiresAuth: true,
              authorize: [roles.SYSADMIN, roles.SALESMAN],
            },
            // route level code-splitting
            // this generates a separate chunk (sysadmin-single-company.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "sysadmin-single-company" */ '../views/SysAdmin/Users/Users/AddCompanyUser.vue'
              ),
          },
        ],
      },
      {
        path: 'administratorzy',
        name: 'Admins',
        redirect: { name: 'ManageSysadmins' },
        meta: {
          requiresAuth: true,
          authorize: [roles.SYSADMIN],
        },
        // route level code-splitting
        // this generates a separate chunk (admins.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "admins" */ '../views/SysAdmin/Users/Admins/Index.vue'),
        children: [
          {
            path: '',
            name: 'ManageSysadmins',
            meta: {
              requiresAuth: true,
              authorize: [roles.SYSADMIN],
            },
            // route level code-splitting
            // this generates a separate chunk (single-admin.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "manage-sys-admins" */ '../views/SysAdmin/Users/Admins/ManageSysadmins.vue'
              ),
          },
          {
            path: 'dodaj',
            name: 'AddSysadmin',
            meta: {
              requiresAuth: true,
              authorize: [roles.SYSADMIN],
            },
            // route level code-splitting
            // this generates a separate chunk (single-admin.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "single-admin" */ '../views/SysAdmin/Users/Admins/AddSysadmin.vue'
              ),
          },
          {
            path: ':userId',
            name: 'SingleSysadmin',
            meta: {
              requiresAuth: true,
              authorize: [roles.SYSADMIN],
            },
            // route level code-splitting
            // this generates a separate chunk (single-admin.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "single-admin" */ '../views/SysAdmin/Users/Admins/SingleAdmin.vue'
              ),
          },
          {
            path: ':userId/edytuj',
            name: 'EditSysadmin',
            meta: {
              requiresAuth: true,
              authorize: [roles.SYSADMIN],
            },
            // route level code-splitting
            // this generates a separate chunk (single-admin.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "single-admin" */ '../views/SysAdmin/Users/Admins/EditSysadmin.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '/admin/handlowcy',
    name: 'Traders',

    redirect: { name: 'ManageTraders' },
    meta: {
      requiresAuth: true,
      authorize: [roles.SYSADMIN],
    },
    // route level code-splitting
    // this generates a separate chunk (traders.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "trader-index" */ '../views/SysAdmin/Traders/Index.vue'),
    children: [
      {
        path: '',
        name: 'ManageTraders',
        meta: {
          requiresAuth: true,
          authorize: [roles.SYSADMIN],
        },
        // route level code-splitting
        // this generates a separate chunk (single-trader.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "traders" */ '../views/SysAdmin/Traders/ManageTraders.vue'),
      },
      {
        path: ':traderId',
        name: 'SingleTrader',
        meta: {
          requiresAuth: true,
          authorize: [roles.SYSADMIN],
        },
        // route level code-splitting
        // this generates a separate chunk (single-trader.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "single-trader" */ '../views/SysAdmin/Traders/SingleTrader.vue'
          ),
      },
    ],
  },
  {
    path: '/admin/asortyment',
    name: 'Stock',
    meta: {
      requiresAuth: true,
      authorize: [roles.SYSADMIN],
    },
    redirect: { name: 'Products' },
    // route level code-splitting
    // this generates a separate chunk (stock.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "stock" */ '../views/SysAdmin/Stock/Index.vue'),
    children: [
      {
        path: 'produkty',
        name: 'Products',
        meta: {
          requiresAuth: true,
          authorize: [roles.SYSADMIN],
        },
        // route level code-splitting
        // this generates a separate chunk (products.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "products" */ '../views/SysAdmin/Stock/Products/ManageProducts.vue'
          ),
      },
      {
        path: 'producenci',
        name: 'Producers',
        meta: {
          requiresAuth: true,
          authorize: [roles.SYSADMIN],
        },
        // route level code-splitting
        // this generates a separate chunk (producers.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "producers" */ '../views/SysAdmin/Stock/Producers/Producers.vue'
          ),
      },
      {
        path: ':productId',
        name: 'SingleProduct',
        meta: {
          requiresAuth: true,
          authorize: [roles.SYSADMIN],
        },
        // route level code-splitting
        // this generates a separate chunk (single-product.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "single-product" */ '../views/SysAdmin/Stock/Products/SingleProduct.vue'
          ),
      },
    ],
  },
  {
    path: `/handlowiec/zamowienia`,
    name: 'SalesmanOrders',
    meta: {
      requiresAuth: true,
      authorize: [roles.SALESMAN],
    },
    redirect: { name: 'SalesmanOrdersList' },
    // route level code-splitting
    // this generates a separate chunk (orders.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "orders" */ '../views/SysAdmin/Orders/Index.vue'),
    children: [
      {
        path: '',
        name: 'SalesmanOrdersList',
        meta: {
          requiresAuth: true,
          authorize: [roles.SALESMAN],
        },
        // route level code-splitting
        // this generates a separate chunk (orders.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "orders" */ '../views/SysAdmin/Orders/ManageOrders.vue'),
      },
      {
        path: ':orderId',
        name: 'SalesmanSingleOrder',
        meta: {
          requiresAuth: true,
          authorize: [roles.SALESMAN],
        },
        // route level code-splitting
        // this generates a separate chunk (single-orders.[hash].js) for this route
        // which is lazy-loaded when the route is visited.P
        component: () =>
          import(
            /* webpackChunkName: "single-orders" */ '../views/SysAdmin/Orders/SingleOrder.vue'
          ),
      },
    ],
  },
  {
    path: `/admin/zamowienia`,
    name: 'SysadminOrders',
    meta: {
      requiresAuth: true,
      authorize: [roles.SYSADMIN],
    },
    redirect: { name: 'OrdersList' },
    // route level code-splitting
    // this generates a separate chunk (orders.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "orders" */ '../views/SysAdmin/Orders/Index.vue'),
    children: [
      {
        path: '',
        name: 'OrdersList',
        meta: {
          requiresAuth: true,
          authorize: [roles.SYSADMIN],
        },
        // route level code-splitting
        // this generates a separate chunk (orders.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "orders" */ '../views/SysAdmin/Orders/ManageOrders.vue'),
      },
      {
        path: ':orderId',
        name: 'SingleOrder',
        meta: {
          requiresAuth: true,
          authorize: [roles.SYSADMIN],
        },
        // route level code-splitting
        // this generates a separate chunk (single-orders.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "single-orders" */ '../views/SysAdmin/Orders/SingleOrder.vue'
          ),
      },
    ],
  },
  {
    path: '/zamowienia',
    name: 'Orders',
    meta: {
      requiresAuth: true,
      authorize: [roles.EMPLOYEE, roles.COMPANY_ADMIN, roles.SYSADMIN, roles.SALESMAN],
    },
    redirect: { name: 'UserOrdersList' },

    // route level code-splitting
    // this generates a separate chunk (orders.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "orders" */ '../views/User/Orders/Index.vue'),
    children: [
      {
        path: '',
        name: 'UserOrdersList',
        meta: {
          requiresAuth: true,
          authorize: [roles.EMPLOYEE, roles.COMPANY_ADMIN, roles.SYSADMIN, roles.SALESMAN],
        },
        // route level code-splitting
        // this generates a separate chunk (products.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "products" */ '../views/User/Orders/UserManageOrders.vue'),
      },
      {
        path: ':orderId',
        name: 'UserSingleOrder',
        meta: {
          requiresAuth: true,
          authorize: [roles.EMPLOYEE, roles.COMPANY_ADMIN, roles.SYSADMIN, roles.SALESMAN],
        },
        // route level code-splitting
        // this generates a separate chunk (single-order.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "single-order" */ '../views/User/Orders/UserSingleOrder.vue'),
      },
    ],
  },
  {
    path: '/oferty',
    name: 'Offers',
    meta: {
      requiresAuth: true,
      authorize: [roles.EMPLOYEE, roles.COMPANY_ADMIN, roles.SYSADMIN, roles.SALESMAN],
    },
    redirect: { name: 'OffersList' },

    // route level code-splitting
    // this generates a separate chunk (offers.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "offers" */ '../views/User/Offers/Index.vue'),
    children: [
      {
        path: '',
        name: 'OffersList',
        meta: {
          requiresAuth: true,
          authorize: [roles.EMPLOYEE, roles.COMPANY_ADMIN, roles.SYSADMIN, roles.SALESMAN],
        },
        // route level code-splitting
        // this generates a separate chunk (single-offers.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "single-offers" */ '../views/User/Offers/ManageOffers.vue'),
      },
      {
        path: ':offerId',
        name: 'SingleOfferIndex',
        redirect: { name: 'SingleOffer' },
        meta: {
          requiresAuth: true,
          authorize: [roles.EMPLOYEE, roles.COMPANY_ADMIN, roles.SYSADMIN, roles.SALESMAN],
        },
        // route level code-splitting
        // this generates a separate chunk (single-offers.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "single-offers" */ '../views/User/Offers/SingleOffer/Index.vue'
          ),
        children: [
          {
            path: '',
            name: 'SingleOffer',
            meta: {
              requiresAuth: true,
              authorize: [roles.EMPLOYEE, roles.COMPANY_ADMIN, roles.SYSADMIN, roles.SALESMAN],
            },
            // route level code-splitting
            // this generates a separate chunk (single-offers.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "single-offers" */ '../views/User/Offers/SingleOffer/SingleOffer.vue'
              ),
          },
          {
            path: 'generowanie-oferty',
            name: 'GenerateOffer',
            meta: {
              requiresAuth: true,
              authorize: [roles.EMPLOYEE, roles.COMPANY_ADMIN, roles.SYSADMIN, roles.SALESMAN],
            },
            // route level code-splitting
            // this generates a separate chunk (generate-offer.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "generate-offer" */ '../views/User/Offers/SingleOffer/GenerateOffer.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '/ustawienia-systemu',
    name: 'SystemSettings',
    meta: {
      requiresAuth: true,
      authorize: [roles.SYSADMIN],
    },
    component: () =>
      import(
        /* webpackChunkName: "generate-offer" */ '../views/SysAdmin/SystemSettings/SystemSettings.vue'
      ),
  },
  {
    path: '/autoryzacja-wymagana',
    name: 'AuthorizationRequired',
    component: () =>
      import(/* webpackChunkName: "AuthorizationRequired" */ '../views/AuthorizationRequired.vue'),
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "Not found" */ '../views/NotFound.vue'),
  },
];

const verifyUser = async (userData, to) => {
  if (userData && to.meta.authorize) {
    try {
      await store.dispatch(`checkUser`, { userData });
    } catch (e) {
      await store.dispatch(`logout`);
    }
  }
};

const redirectUnathorized = async (userData, to, next) => {
  if (to.meta.requiresAuth) {
    const { name, params } = to;
    await store.commit('setEnteringView', { name, params });
    const currentRole = userData?.role;
    if (!to.meta.authorize.includes(currentRole)) {
      next({
        name: `AuthorizationRequired`,
      });
    }
  }
};

const handleHomeRedirect = async (userData, to, next) => {
  if (to.name === 'Home') {
    if (!isEmpty(userData)) {
      next({
        name: `Options`,
      });
    } else {
      next({
        name: `Login`,
      });
    }
  }
};

const handleLoginRegisterRedirect = async (userData, to, next) => {
  if (!isEmpty(userData) && (to.name === 'Login' || to.name === 'Register')) {
    next({
      name: `Options`,
    });
  }
};

const setOrganizationToStore = async (org, id) => {
  await store.dispatch(`setOrganization`, { org, id });
};

const checkIfExternalOrganization = async (to) => {
  const { org, id } = to.query;
  const isOrgValid = Object.values(externalOrganizations).includes(org);
  if (isOrgValid && id) {
    await setOrganizationToStore(org, id);
  }
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  let userData = store.state.userData;
  handleHomeRedirect(userData, to, next);
  handleLoginRegisterRedirect(userData, to, next);
  await checkIfExternalOrganization(to);
  await verifyUser(userData, to);
  await redirectUnathorized(userData, to, next);
  next();
});

export default router;
