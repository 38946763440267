import axios from 'axios';
import Vue from 'vue';
import VueI18n from '../plugins/i18n';
import store from '../store/store'; // path to your Vuex store
import router from '../router/router'; // path to your Vuex store

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && router.currentRoute.name !== 'Login') {
      Vue.toasted.global.error({ message: VueI18n.t('errorMessages.noAuthorization') });
      store.dispatch(`logout`);
    }
    if (error.response.status === 403) {
      Vue.toasted.global.error({
        message: VueI18n.t('errorMessages.noRightsForOperation'),
      });
      store.dispatch(`logout`);
    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.data?.errorCode) {
      Vue.toasted.global.error({
        message: VueI18n.t(`errorMessages.${error.response.data.errorCode}`),
      });
    }
    return Promise.reject(error);
  }
);

export default axios;
