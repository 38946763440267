<template>
  <title-link-wrapper :title="$t('components.userSingleOrder.title')" :to="{ name: 'Orders' }">
    <v-progress-linear v-if="loading" :indeterminate="true" class="mb-3" />
    <v-container v-else>
      <order-details :order="order" :company="company" />
      <v-container class="white pa-4">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="products"
              :items-per-page="6"
              :disable-sort="true"
              class="elevation-0"
              :no-data-text="this.$t('components.myDataTable.noDataAvailable')"
            >
              <template v-slot:[`item.standardPrice`]="{ item }">
                <span>{{ formatCurrency(item.standardPrice, order.currency, false) }}</span>
              </template>
              <template v-slot:[`item.totalPriceItem`]="{ item }">
                <span>{{ totalPrice(item) }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider> </v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col> </v-col>
          <price-details
            :totalPriceGross="totalPriceGross"
            :VAT="VAT"
            :totalPrice="order.totalPrice"
            :exchangeRate="order.currency"
            :orderDiscounts="order.discounts ? order.discounts : []"
            :shouldCalculateByCurrency="false"
          />
        </v-row>
        <v-row>
          <v-col cols="12" md="7" v-if="!currentFVFunction.hidden">
            <primary-button :onClick="currentFVFunction.func" :loading="buttonLoading">{{
              currentFVFunction.text
            }}</primary-button>
            <p class="mt-2">*{{ $t('components.userSingleOrder.sentToEmail') }}</p>
          </v-col>
          <v-col cols="12" :md="!currentFVFunction.hidden ? '5' : '12'" class="text-right mb-4">
            <primary-button :onClick="generateOrderPdf" :loading="buttonLoading">{{
              $t('components.userSingleOrder.generateOrderPdf')
            }}</primary-button>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </title-link-wrapper>
</template>
<script>
import TitleLinkWrapper from '../../../components/TitleLinkWrapper.vue';
import OrderService from '../../../services/OrderService';
import PrimaryButton from '../../../components/buttons/PrimaryButton.vue';
import { userDataComputed } from '../../../store/helper';
import { roles } from '../../../static/roles';
import { formatCurrency } from '../../../mixins/formatCurrency';
import { ORDER_STATUSES } from '../../../static/orderStatuses';
import OrderDetails from '../../../components/OrderDetails.vue';
import PriceDetails from '../../../components/PriceDetails.vue';
import { sortHandler } from '../../../mixins/sortHandler';
import { calculateGrossPrice } from '../../../utils/priceUtils';
import { vatMultipliers } from '../../../static/vatMultipliers';
import FileSaver from 'file-saver';
import VueI18n from '../../../plugins/i18n';

export default {
  components: { TitleLinkWrapper, PrimaryButton, OrderDetails, PriceDetails },
  name: 'SingleOrder',
  mixins: [formatCurrency, sortHandler],

  data() {
    return {
      orderId: '',
      products: [],
      order: '',
      loading: true,
      buttonLoading: false,
      orderStatuses: ORDER_STATUSES(),
      salesmanDiscount: 0,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: VueI18n.t('helpers.code'),
          align: 'start',
          value: 'code',
        },
        { text: VueI18n.t('helpers.filterName'), value: 'name' },
        {
          text: `${VueI18n.t('helpers.price')} (${VueI18n.t('helpers.net')})`,
          value: 'standardPrice',
        },
        { text: VueI18n.t('helpers.piecesAmount'), value: 'quantity' },
        {
          text: `${VueI18n.t('helpers.sum')} (${VueI18n.t('helpers.net')})`,
          value: 'totalPriceItem',
          sortable: false,
        },
      ];
    },

    ...userDataComputed,
    company() {
      return this.order.company ? this.order.company : {};
    },
    VAT() {
      return this.totalPriceGross - this.order.totalPrice;
    },
    totalPriceGross() {
      return calculateGrossPrice(this.order.totalPrice, vatMultipliers.VAT23.value);
    },
    currentFVFunction() {
      if (this.order.status.id === this.orderStatuses.PAID.id) {
        return this.createFVObject(
          this.$i18n.t('components.userSingleOrder.sendAgainProforma'),
          () => this.changeOrderStatus(this.orderStatuses.FOR_CLIENT_PAID_PRO_FV.id)
        );
      } else if (this.order.status.id === this.orderStatuses.NEW_ORDER.id) {
        return this.createFVObject(
          this.$i18n.t('components.userSingleOrder.sendAgainProforma'),
          () => this.changeOrderStatus(this.orderStatuses.FOR_CLIENT_NOT_PAID_PRO_FV.id)
        );
      } else if (this.order.status.id === this.orderStatuses.SENT.id) {
        return this.createFVObject(
          this.$i18n.t('components.userSingleOrder.sendAgainInvoice'),
          () => this.changeOrderStatus(this.orderStatuses.FOR_CLIENT_FV.id)
        );
      } else if (this.order.status.id === this.orderStatuses.CANCELLED.id) {
        return this.createFVObject('', () => {}, true);
      } else {
        return { hidden: true };
      }
    },
  },
  methods: {
    createFVObject(text, func, hidden = false) {
      return {
        text,
        func,
        hidden,
      };
    },
    async changeOrderStatus(status_id) {
      try {
        this.buttonLoading = true;
        const response = await OrderService.updateStatus(this.orderId, status_id);
        if (response.status === 200) {
          this.order.status = response.data;
        }

        this.$toasted.global.success({
          message: this.$i18n.t('components.userSingleOrder.changeOrderStatusFunc'),
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.buttonLoading = false;
      }
    },
    async getOrderCompanyAdmin() {
      try {
        this.loading = true;
        const response = await OrderService.getCompanyOrder(this.orderId);
        this.order = response.data;
        this.products = response.data.items;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async getOrderUser() {
      try {
        this.loading = true;
        const response = await OrderService.getUserOrder(this.orderId);
        this.order = response.data;
        this.products = response.data.items;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    totalPrice(item) {
      const totalPrice = item.quantity * item.standardPrice;
      return this.formatCurrency(totalPrice, this.order.currency, false);
    },
    getOrderByRole() {
      if (this.userData.role === roles.COMPANY_ADMIN) {
        this.getOrderCompanyAdmin();
      } else {
        this.getOrderUser();
      }
    },
    fetchSort() {
      this.getOrderByRole();
    },
    async generateOrderPdf() {
      try {
        const res = await OrderService.generateOfferPdf(this.orderId);

        FileSaver.saveAs(
          res,
          `${VueI18n.t('components.singleOrder.orderNumber')} ${this.order.baselinkerId}.pdf`
        );
        this.$toasted.global.success({
          message: VueI18n.t('components.singleOrder.toasted.generateOrderPdfFunc'),
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
  activated() {
    const orderId = this.$route.params.orderId;
    this.orderId = orderId;
    this.getOrderByRole();
  },
};
</script>
<style lang="scss" scoped></style>
