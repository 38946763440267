<template>
  <v-container class="white pa-8">
    <v-row
      ><v-col>
        <h1 class="text">
          {{ $t('components.address.title.partOne') }}
          {{
            isPickupInPerson
              ? `${$t('components.address.title.optionOne')}`
              : `${$t('components.address.title.optionTwo')}`
          }}
        </h1></v-col
      ></v-row
    >
    <div v-if="isSysAdminOrSalesman">
      <v-row
        ><v-col><v-divider></v-divider></v-col
      ></v-row>
      <v-row
        ><v-col>{{ $t('components.address.companyOrderFor') }}:</v-col></v-row
      >
      <v-row class="pb-0 mb-0">
        <v-col class="pb-0 mb-0" cols="9">
          <v-select
            v-model="selectedCompany"
            :items="companies"
            :label="$t('helpers.company')"
            @change="useCompanyAddress"
            item-text="companyName"
            :menu-props="{ bottom: true, offsetY: true }"
            item-value="_id"
            outlined
            solo
          ></v-select>
        </v-col>
        <v-col cols="1" class="pb-0 mb-0">
          <v-btn color="primary" elevation="0" fab dark block @click="unselectCompany">
            <v-icon>mdi-cancel</v-icon>
          </v-btn></v-col
        >
        <v-col cols="9" v-if="isSalesman" class="subtitle-2 red--text">
          <p>
            {{ $t('components.address.discountGroupInfo') }}
          </p>
        </v-col>
      </v-row>
    </div>
    <div v-if="!isSysAdminOrSalesman">
      <v-row
        ><v-col><v-divider></v-divider></v-col
      ></v-row>
      <v-row
        ><v-col>{{ $t('components.address.wantToUseCompanyAddress') }}</v-col></v-row
      >
      <v-row
        ><v-col>
          <primary-button :onClick="useCompanyAddress">{{
            $t('components.address.useCompanyAddress')
          }}</primary-button></v-col
        ></v-row
      >
    </div>
    <v-row
      ><v-col><v-divider></v-divider></v-col
    ></v-row>
    <v-form v-model="isFormValid" ref="form">
      <v-row class="pb-0 mb-0"
        ><v-col cols="12" sm="6" class="mb-0 pb-0">
          <h2 class="text mb-4">{{ $t('helpers.firstName') }}*</h2>
          <v-text-field
            v-model="deliveryData.firstName"
            elevation="0"
            :label="$t('helpers.firstName')"
            autocomplete="firstName"
            :rules="validators.requiredRules"
            outlined
            solo
          ></v-text-field></v-col
        ><v-col cols="12" sm="6" class="pb-0 mb-0">
          <h2 class="text mb-4">{{ $t('helpers.lastName') }}*</h2>
          <v-text-field
            v-model="deliveryData.lastName"
            elevation="0"
            :label="$t('helpers.lastName')"
            autocomplete="lastName"
            :rules="validators.requiredRules"
            outlined
            solo
          ></v-text-field></v-col
      ></v-row>
      <v-row class="my-0 py-0" v-if="!isPickupInPerson">
        <v-col cols="12" sm="6" class="my-0 py-0">
          <h2 class="text mb-4">{{ $t('helpers.address') }}*</h2>
          <v-text-field
            v-model="deliveryData.address"
            elevation="0"
            :label="$t('helpers.address')"
            autocomplete="address"
            :rules="validators.requiredRules"
            outlined
            solo
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="my-0 py-0">
          <h2 class="text mb-4">{{ $t('helpers.zipCode') }}*</h2>
          <v-text-field
            v-model="deliveryData.zipCode"
            elevation="0"
            :label="$t('helpers.zipCode')"
            autocomplete="zipCode"
            :rules="
              foreignDelivery(deliveryCountryCode)
                ? validators.requiredRules
                : validators.zipCodeRules
            "
            outlined
            solo
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="my-0 py-0">
        <v-col cols="12" sm="6" class="my-0 py-0" v-if="!isPickupInPerson">
          <h2 class="text mb-4">{{ $t('helpers.city') }}*</h2>
          <v-text-field
            v-model="deliveryData.city"
            elevation="0"
            :label="$t('helpers.city')"
            autocomplete="city"
            :rules="validators.requiredRules"
            outlined
            solo
          ></v-text-field
        ></v-col>
        <v-col cols="12" sm="6" class="my-0 py-0">
          <h2 class="text mb-4">{{ $t('helpers.phoneNumber') }}*</h2>
          <v-text-field
            v-model="deliveryData.phone"
            elevation="0"
            :label="$t('helpers.phoneNumber')"
            :rules="validators.addPhoneRules.concat(validators.requiredRules)"
            outlined
            solo
          ></v-text-field
        ></v-col>
        <v-col cols="12" sm="6" class="my-0 py-0">
          <h2 class="text mb-4">{{ $t('helpers.companyName') }}</h2>
          <v-text-field
            v-model="deliveryData.companyName"
            elevation="0"
            :label="$t('helpers.companyName')"
            :rules="validators.companyName"
            outlined
            solo
          ></v-text-field
        ></v-col>
        <v-col cols="12" sm="6" class="my-0 py-0">
          <h2 class="text mb-4">{{ $t('helpers.country') }}</h2>
          <v-autocomplete
            solo
            outlined
            v-model="deliveryData.countryCode"
            :items="mappedCountries"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <outlined-button :onClick="prev">{{ $t('helpers.goBack') }}</outlined-button>
        </v-col>
        <v-col>
          <v-spacer></v-spacer>
        </v-col>
        <v-col :class="{ 'text-right': !$vuetify.breakpoint.sm }">
          <primary-button :onClick="next">{{ $t('helpers.continue') }}</primary-button>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import PrimaryButton from '../../../components/buttons/PrimaryButton.vue';
import OutlinedButton from '../../../components/buttons/OutlinedButton.vue';
import { validators } from '../../../validators/Validators';
import { deliveryComputed, countryCodeComputed } from '../../../store/helper';
import CompanyService from '../../../services/CompanyService';
import SysadminService from '../../../services/SysadminService';
import { deliveryTypes } from '../../../static/deliveryTypes';
import { countriesCodeHandler } from '../../../mixins/countriesCodeHandler';
import { formMixin } from '../../../mixins/formMixin';
import { permissionRolesMixin } from '../../../mixins/permissionRolesMixin';
import SalesmanService from '../../../services/SalesmanService';

export default {
  components: { PrimaryButton, OutlinedButton },
  mixins: [countriesCodeHandler, formMixin, permissionRolesMixin],
  name: 'Address',
  data() {
    return {
      deliveryData: {
        companyName: '',
        firstName: '',
        lastName: '',
        address: '',
        zipCode: '',
        city: '',
        phone: '',
        countryCode: '',
      },
      selectedCompany: null,
      companies: [],
      isFormValid: false,
    };
  },
  methods: {
    prev() {
      this.$emit('prev');
    },
    next() {
      this.$refs.form.validate();
      if (this.isFormValid) {
        this.$store.commit('setDeliveryData', this.deliveryData);
        if (this.selectedCompany) {
          this.$store.commit('setOrderCompanyId', this.selectedCompany);
        }
        this.$emit('next');
      }
    },
    async useCompanyAddress() {
      const companyId = !this.isSysAdminOrSalesman ? this.userData.companyId : this.selectedCompany;
      const response = await CompanyService.get(companyId);
      const company = response.data;

      if (company) {
        this.deliveryData = {
          companyName: company.companyName,
          firstName: !this.isSysAdmin ? this.userData.firstName : '',
          lastName: !this.isSysAdmin ? this.userData.lastName : '',
          address: !this.isPickupInPerson ? company.address : '',
          zipCode: !this.isPickupInPerson ? company.zipCode : '',
          city: !this.isPickupInPerson ? company.city : '',
          countryCode: this.deliveryCountryCode,
        };
        this.$toasted.global.success({
          message: this.$i18n.t('components.address.useCompanyAddressFunc'),
        });
      }
    },
    async getAllCompanies() {
      let response;
      if (this.isSysAdmin)
        response = await SysadminService.getAllCompanies({ sort: 'companyName' });

      if (this.isSalesman) response = await SalesmanService.getAllSalesmanCompanies();

      this.companies = response.data;
    },
    unselectCompany() {
      this.selectedCompany = null;
      this.deliveryData = {
        firstName: '',
        lastName: '',
        address: '',
        zipCode: '',
        city: '',
        countryCode: '',
      };
    },
  },
  computed: {
    validators() {
      return validators;
    },
    ...deliveryComputed,
    ...countryCodeComputed,

    isPickupInPerson() {
      return this.delivery.type === deliveryTypes().PICKUP_IN_PERSON.type;
    },
  },
  async created() {
    if (this.isSysAdminOrSalesman) {
      await this.getAllCompanies();
    }
  },
};
</script>
