<template>
  <svg
    width="180"
    height="180"
    viewBox="0 0 180 180"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      id="svg_1"
      fill="#e7247b"
      d="m172.58278,107.65333l-63.776,63.777c-2.436,2.439 -5.328,4.374 -8.512,5.695c-3.1845,1.32 -6.5978,2 -10.0449,2c-3.4472,0 -6.8605,-0.68 -10.0447,-2c-3.1841,-1.321 -6.0767,-3.256 -8.5122,-5.695l-63.77653,-63.777c-4.9095,-4.921 -7.66667,-11.5894 -7.66667,-18.5411c0,-6.9516 2.75717,-13.6192 7.66667,-18.5408l63.77653,-63.77654c4.927,-4.91143 11.6,-7.66956 18.5569,-7.66956c6.9568,0 13.6299,2.75813 18.5569,7.66956l63.776,63.77654c4.91,4.9216 7.667,11.5892 7.667,18.5408c0,6.9517 -2.757,13.6201 -7.667,18.5411"
    />
    <path
      id="svg_8"
      d="m33.3745,71.37589l31.6242,29.12463"
      opacity="undefined"
      stroke-width="2"
      stroke="white"
      fill="none"
    />
    <line
      id="svg_9"
      y2="100.37563"
      x2="33.37424"
      y1="71.62472"
      x1="65.12602"
      stroke-width="2"
      stroke="white"
      fill="none"
    />
  </svg>
</template>
<script>
export default {
  name: 'FloatingCloseIcon',
};
</script>
<style lang="scss" scoped></style>
