<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-img
          contain
          v-if="product.image"
          :lazy-src="product.image"
          width="auto"
          height="300px"
          class="rounded"
          :src="product.image"
        ></v-img>
        <v-img v-else height="200" width="auto">
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-icon size="80px">mdi-image</v-icon>
          </v-row>
        </v-img>
      </v-col>
      <v-col class="pt-0">
        <v-container>
          <v-row>
            <v-col>
              <h2>
                {{ getNameByLang(product) }}
                <span v-if="product.producer && product.producer.name">{{
                  product.producer.name
                }}</span>
              </h2>
              <p>{{ getShortDescriptionByLang(product) }}</p>
            </v-col></v-row
          >
        </v-container>
      </v-col>
    </v-row>
    <div v-if="product.longDescription">
      <v-row>
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p>
            {{ getLongDescriptionByLang(product) }}
          </p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { getNameByLanguage } from '../mixins/getNameByLanguage';

export default {
  name: 'ProductDescription',
  mixins: [getNameByLanguage],
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
};
</script>
