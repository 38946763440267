<template>
  <title-link-wrapper
    :title="$t('components.generateOffer.title')"
    :to="{ name: 'SingleOfferIndex' }"
  >
    <v-progress-linear v-if="loading" :indeterminate="true" class="mb-3" />
    <v-container v-else class="white">
      <div class="d-flex align-center">
        <div>
          <v-img
            v-if="isSysadmin"
            cover
            :width="$vuetify.breakpoint.mobile ? 'auto' : 200"
            height="auto"
            src="@/assets/logo.svg"
            alt="was-logo"
          ></v-img>
          <v-img
            v-else
            cover
            :width="$vuetify.breakpoint.mobile ? 'auto' : 200"
            height="auto"
            :src="companyLogo"
          ></v-img>
        </div>
        <v-spacer></v-spacer>
        <div class="mr-10 ml-2 tertiary-text--text" v-if="company">
          <p class="my-0">
            {{ company.companyName || '' }}
          </p>
          <p class="my-0">{{ $t('helpers.registerForm') }}: {{ company.nip || '' }}</p>
          <p class="my-0">{{ company.address || '' }}</p>
          <p class="my-0">{{ company.zipCode || '' }} {{ company.city || '' }}</p>
        </div>
        <div class="ml-2 tertiary-text--text">
          <p class="my-0">{{ $t('components.generateOffer.phone') }}: {{ user.phone }}</p>
          <p class="my-0">{{ $t('helpers.email') }}: {{ user.email }}</p>
        </div>
      </div>
      <v-row class="ma-0 pa-3 font--text table-row mt-8">
        <v-col>Nr</v-col>
        <v-col>{{ $t('components.generateOffer.productName') }}</v-col>
        <v-col>{{ $t('helpers.amount') }}</v-col>
      </v-row>
      <v-row class="ma-0 pa-3 table-row" v-for="(prod, index) in filteredProducts" :key="index">
        <v-col>{{ index + 1 }}</v-col>
        <v-col>{{ prod.product.name }}</v-col>
        <v-col>{{ prod.quantity }}{{ $t('helpers.piecesAmount') }}</v-col>
      </v-row>
      <v-row class="my-0 mt-8 mx-0">
        <v-col class="pa-0 ma-0 text-right text-caption"
          >{{ $t('components.generateOffer.gross') }}:
          {{ formatCurrency(totalProductsGross, offer.currency, false) }}</v-col
        >
      </v-row>
      <v-row class="ma-0 align-center">
        <v-col class="text-right pa-0 mb-7">
          {{ $t('components.generateOffer.materialsPrice') }}:
        </v-col>
        <v-col class="pa-0" cols="3">
          <v-form ref="form" v-model="productsTotalValid">
            <v-text-field
              class="ml-3"
              v-model="productsTotal"
              :suffix="currencyLabel(offer.currency)"
              :rules="validators.priceGroupRules"
              type="number"
              outlined
              solo
            ></v-text-field>
          </v-form>
        </v-col>
      </v-row>
      <additional-offer-services
        ref="offerServices"
        :services="services"
        :exchangeRate="offer.currency"
        @onRemoveService="saveOffer"
      ></additional-offer-services>
      <v-row>
        <v-col>
          <v-textarea
            class="additional-info"
            v-model="additionalInfo"
            solo
            persistent-hint
            rows="3"
            :placeholder="$t('components.generateOffer.additionalComment')"
            :no-resize="true"
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          <p class="my-0">
            {{ $t('components.generateOffer.finalPriceNet') }}:
            {{ formatCurrency(finalPrice, offer.currency, false) }}
          </p>
          <p class="my-0">
            {{ $t('components.generateOffer.tax') }}:
            {{ formatCurrency(finalVat, offer.currency, false) }}
          </p>
          <p class="my-0">
            {{ $t('components.generateOffer.finalPriceGross') }}:
            {{ formatCurrency(finalPriceGross, offer.currency, false) }}
          </p>
          <p class="my-0 tertiary-text--text mt-1 text-caption">
            {{ $t('components.generateOffer.taxRate') }} {{ vatValue }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <h2 class="text mb-2">{{ $t('components.generateOffer.taxAmount') }}</h2>
          <v-select
            v-model="vatMultiplier"
            :items="Object.values(vatMultipliers)"
            item-text="label"
            hide-details
            :label="$t('components.generateOffer.taxAmount')"
            outlined
            solo
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <primary-button :onClick="saveOffer" class="mr-5 my-3">{{
            $t('components.generateOffer.saveOffer')
          }}</primary-button>
          <outlined-button :onClick="downloadOfferPdf" class="mr-5 my-3 my-md-0">{{
            $t('components.generateOffer.generateOffer')
          }}</outlined-button>
          <outlined-button class="mr-5 my-3 my-md-0" :onClick="downloadListPdf">{{
            $t('components.generateOffer.generateProductsList')
          }}</outlined-button>
        </v-col>
      </v-row>
    </v-container>
  </title-link-wrapper>
</template>
<script>
import OfferService from '../../../../services/OfferService.js';
import PrimaryButton from '../../../../components/buttons/PrimaryButton.vue';
import OutlinedButton from '../../../../components/buttons/OutlinedButton.vue';
import TitleLinkWrapper from '../../../../components/TitleLinkWrapper.vue';
import AdditionalOfferServices from '../../../../components/AdditionalOfferServices.vue';
import { validators } from '../../../../validators/Validators';
import { round, sumBy, filter, map } from 'lodash';
import FileSaver from 'file-saver';
import { formatCurrency } from '../../../../mixins/formatCurrency';
import { userDataComputed } from '../../../../store/helper';
import { roles } from '../../../../static/roles';
import { categories } from '../../../../static/categoriesList';
import { calculateGrossPrice } from '../../../../utils/priceUtils.js';
import { vatMultipliers } from '../../../../static/vatMultipliers.js';
import { formMixin } from '../../../../mixins/formMixin';

export default {
  components: { TitleLinkWrapper, PrimaryButton, OutlinedButton, AdditionalOfferServices },
  name: 'GenerateOffer',
  mixins: [formatCurrency, formMixin],
  data() {
    return {
      offerId: '',
      pdfType: '',
      offer: {},
      company: {},
      user: {},
      products: [],
      services: [],
      accessories: {
        quantity: 0,
        product: {
          name: this.$i18n.t(`statics.categoriesList.${categories.MOUNTING_ACCESSORIES.value}`),
        },
      },
      filteredProducts: [],
      productsTotal: 0,
      productsTotalValid: false,
      validators,
      vatMultipliers,
      vatMultiplier: 0,
      additionalInfo: '',
      loading: false,
    };
  },
  computed: {
    ...userDataComputed,
    totalProductsGross() {
      return calculateGrossPrice(this.productsTotal, this.offer.vatMultiplier);
    },
    totalServicesPrice() {
      const sum = round(
        sumBy(
          this.services.map((service) => {
            return {
              ...service,
              price: parseFloat(service.price),
            };
          }),
          'price'
        ),
        2
      );
      return parseFloat(sum || 0);
    },
    finalPrice() {
      return round(this.totalServicesPrice + this.productsTotal, 2);
    },
    finalPriceGross() {
      return calculateGrossPrice(this.finalPrice, this.offer.vatMultiplier);
    },
    finalVat() {
      return round(this.finalPriceGross - this.finalPrice, 2);
    },
    offerDeliveryPrice() {
      return this.offer.deliveryPrice || 0;
    },
    companyLogo() {
      return this.company.companyLogo;
    },
    isSysadmin() {
      return this.userData.role === roles.SYSADMIN;
    },
    vatValue() {
      const selectedVatMultiplier = Object.values(this.vatMultipliers).find(
        (vat) => vat.value === this.offer.vatMultiplier
      );
      return selectedVatMultiplier.label;
    },
  },
  methods: {
    async getOffer() {
      this.loading = true;
      try {
        const response = await OfferService.getOffer(this.offerId);
        this.offer = response.data;
        this.products = response.data.productsRefs;
        this.accessoriesToOneLine();
        this.company = response.data.company;
        this.user = response.data.user;
        this.services = response.data.services;
        this.productsTotal = response.data.productsTotal;
        this.additionalInfo = response.data.additionalInfo;
        this.vatMultiplier = response.data.vatMultiplier;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    accessoriesToOneLine() {
      const category = categories.MOUNTING_ACCESSORIES.value;
      const mountingAccessories = map(
        filter(this.products, ['product.category', category]),
        (accessory) => {
          return {
            ...accessory,
            quantity: parseInt(accessory.quantity),
          };
        }
      );
      this.filteredProducts = [
        ...this.products.filter((product) => product.product.category !== category),
      ];
      if (mountingAccessories.length) {
        this.accessories.quantity = sumBy(mountingAccessories, 'quantity');
        this.filteredProducts.push(this.accessories);
      }
    },
    async saveOffer() {
      try {
        if (!this.$refs.offerServices.servicesFormValid)
          return this.$toasted.global.error({
            message: this.$i18n.t('components.generateOffer.toasted.saveOfferFunc.checkServices'),
          });
        if (!this.productsTotalValid)
          return this.$toasted.global.error({
            message: this.$i18n.t(
              'components.generateOffer.toasted.saveOfferFunc.enterCorrectPricesSum'
            ),
          });
        await OfferService.update(this.offerId, {
          vatMultiplier: this.vatMultiplier,
          services: this.services,
          productsTotal: this.productsTotal,
          totalPrice: this.finalPriceGross,
          additionalInfo: this.additionalInfo,
        });
        await this.getOffer();
        this.$toasted.global.success({
          message: this.$i18n.t('components.generateOffer.toasted.saveOfferFunc.offerUpdated'),
        });
      } catch (err) {
        console.error(err);
      }
    },
    async downloadOfferPdf() {
      try {
        const res = await OfferService.generateOfferPdf(this.offerId);

        FileSaver.saveAs(res, `was-${this.$i18n.t('components.generateOffer.fileName')}.pdf`);
        this.$toasted.global.success({
          message: this.$i18n.t('components.generateOffer.toasted.downloadOfferPdfFunc'),
        });
      } catch (err) {
        console.error(err);
      }
    },
    async downloadListPdf() {
      try {
        const res = await OfferService.generateListPdf(this.offerId);

        FileSaver.saveAs(
          res,
          `was-${this.$i18n.t('components.generateOffer.productsListFileName')}.pdf`
        );
        this.$toasted.global.success({
          message: this.$i18n.t('components.generateOffer.toasted.downloadListPdfFunc'),
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
  async created() {
    const offerId = this.$route.params.offerId;
    this.offerId = offerId;
    await this.getOffer();
  },
};
</script>
<style lang="scss" scoped>
@import '../../../../scss/_variables.scss';
.table-row {
  border: 1px solid $grey;
}
.additional-info {
  @extend .table-row;
  border-radius: 0;
  padding-left: 0.5rem;
}
</style>
