<template>
  <title-wrapper :title="$t('components.manageOrders.title')">
    <v-progress-linear v-if="loading" indeterminate color="primary"> </v-progress-linear>
    <v-container v-else class="px-0">
      <v-row class="align-center">
        <v-col cols="12" sm="2">
          <v-text-field
            v-model="params.search"
            hide-details
            :placeholder="$t('components.manageOrders.searchId')"
            solo
          ></v-text-field
        ></v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            v-model="params.companyName"
            hide-details
            :placeholder="$t('helpers.companyName')"
            solo
          ></v-text-field
        ></v-col>

        <v-col cols="12" sm="2">
          <v-text-field
            v-model="params.nip"
            hide-details
            :placeholder="$t('helpers.registerForm')"
            solo
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            v-model="params.status"
            :items="filterStatuses"
            item-value="name"
            item-text="text"
            hide-details
            :placeholder="$t('helpers.status')"
            solo
          ></v-select
        ></v-col>
        <v-col cols="12" sm="2">
          <v-menu
            ref="menu"
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="slide-y-transition"
            offset-y
            :nudge-top="-10"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="params.dates"
                :label="$t('helpers.date')"
                readonly
                hide-details
                clearable
                @click:clear="onClearDate"
                :placeholder="$t('helpers.date')"
                solo
                v-bind="attrs"
                v-on="on"
              >
                <template slot="append">
                  <v-icon small :class="{ active: dateMenu }">$arrowIcon</v-icon>
                </template></v-text-field
              >
            </template>
            <v-date-picker
              v-model="params.dates"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '400'"
              no-title
              scrollable
              range
              :first-day-of-week="dayOfWeek"
              :locale="selectedLanguage"
              :min="params.dates ? params.dates[0] : ''"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" sm="1">
          <v-btn color="primary" elevation="0" fab dark block @click="onSearch">
            <v-icon>mdi-magnify</v-icon>
          </v-btn></v-col
        >
        <v-col cols="6" sm="1">
          <v-btn color="primary" elevation="0" fab dark block @click="clearParams">
            <v-icon>mdi-cancel</v-icon>
          </v-btn></v-col
        >
      </v-row>
      <v-container class="white my-4">
        <v-row
          ><v-col
            ><v-data-table
              :headers="headers"
              :items="orders"
              :items-per-page="params.limit"
              hide-default-footer
              class="elevation-0"
              :sortBy="sortBy"
              :sortDesc="sortDesc"
              @update:sort-by="sortByFunc"
              @update:sort-desc="sortDescFunc"
              :no-data-text="this.$t('components.myDataTable.noDataAvailable')"
            >
              <template v-slot:[`item.convertedTotalPrice`]="{ item }">
                <span>{{ formatCurrency(item.totalPrice, item.currency, false) }}</span>
              </template>
              <template v-slot:[`item.delivery.countryCode`]="{ item }">
                <span>{{ getCountryName(item.delivery.countryCode) }}</span>
              </template>
              <template v-slot:[`item.status.name`]="{ item }">
                <v-chip :color="item.status.color"
                  ><span class="white--text">{{
                    getTextForUser(item.status.id) || 'Błąd'
                  }}</span></v-chip
                >
                <v-tooltip top v-if="item.missingProducts.length">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="warning" class="ml-1" v-bind="attrs" v-on="on">
                      mdi-alert-circle
                    </v-icon>
                  </template>

                  <p class="my-0">{{ $t('components.manageOrders.orderNotComplete') }}:</p>
                  <p
                    v-for="(missingProduct, index) in item.missingProducts"
                    :key="missingProduct.code"
                    class="my-0"
                  >
                    {{ index + 1 }}. {{ missingProduct.name }},
                    {{ $t('components.manageOrders.productCode') }}: {{ missingProduct.code }},
                    {{ $t('components.manageOrders.misssingAmount') }}:
                    {{ missingProduct.missingQuantity }}
                  </p>
                  <p v-if="item.missingSkus.length">
                    {{ $t('components.manageOrders.noSKU') }}:
                    <span v-for="(sku, index) in item.missingSkus" :key="sku">
                      {{ sku }}<span>{{ index !== item.missingSkus.length - 1 ? ',' : '' }}</span>
                    </span>
                  </p>
                </v-tooltip>
              </template>
              <template v-slot:[`item.date`]="{ item }">
                <span>{{ formatDate(item.date) }}</span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu :close-on-content-click="true" :nudge-width="200" offset-x>
                  <template v-slot:activator="{ on }">
                    <v-icon small color="black" class="mr-2" v-on="on"> mdi-dots-vertical </v-icon>
                  </template>
                  <v-card
                    ><v-list-item
                      class="hover-item-popup"
                      @click="
                        () => {
                          showOrderDetails(item._id);
                        }
                      "
                    >
                      <v-list-item-content>{{ $t('helpers.showDetails') }} </v-list-item-content>
                    </v-list-item>
                    <div v-if="isSysAdmin">
                      <div v-if="!foreignDelivery(item.delivery.countryCode)">
                        <v-list-item
                          v-for="status in statuses"
                          :key="status.id"
                          class="hover-item-popup"
                          @click="
                            () => {
                              checkChangeStatus(item._id, status.id);
                            }
                          "
                        >
                          <v-list-item-content> {{ status.listItemText }} </v-list-item-content>
                        </v-list-item>
                      </div>
                      <div v-else>
                        <v-list-item
                          v-for="status in foreignStatuses"
                          :key="status.id"
                          class="hover-item-popup"
                          @click="
                            () => {
                              checkChangeStatus(item._id, status.id);
                            }
                          "
                        >
                          <v-list-item-content> {{ status.listItemText }} </v-list-item-content>
                        </v-list-item>
                      </div>
                    </div>
                  </v-card>
                </v-menu>
              </template></v-data-table
            ></v-col
          ></v-row
        >
        <slot name="below">
          <v-row>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h3 class="text-right">
                {{ $t('helpers.sum') }} ({{ $t('helpers.net') }}):
                {{ formatCurrency(totalSum(sum)) }}
              </h3>
            </v-col>
          </v-row>
        </slot>
      </v-container>
      <v-row v-if="isPaginationVisible"
        ><v-col
          ><div class="text-center">
            <v-pagination
              v-model="params.page"
              :length="length"
              total-visible="5"
            ></v-pagination></div></v-col
      ></v-row>
      <confirm-modal
        @accept="confirmShipmentNumber"
        @decline="closeShipmentModal"
        @optionalAction="cancelShipmentNumber"
        :showOptionalAction="true"
        :title="$t('helpers.shipmentNumber')"
        :open="showShipmentModal"
        :agree="$t('components.manageOrders.modal.agree')"
        :optionalActionLabel="$t('components.manageOrders.modal.optionalLabel')"
      >
        {{ $t('components.manageOrders.modal.text') }}
        <v-text-field
          class="text-h6 mt-4"
          name="shipmentNumber"
          type="text"
          dense
          :placeholder="$t('components.manageOrders.typeShipmentNumber')"
          v-model="shipmentNumber"
          outlined
          elavation="0"
        >
        </v-text-field>
      </confirm-modal>
    </v-container>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../components/TitleWrapper.vue';
import OrderService from '../../../services/OrderService';
import { isBefore } from 'date-fns';
import {
  CLIENT_ORDER_STATUSES,
  SENT,
  FOREIGN_CLIENT_STATUSES,
  STATUSES,
  FILTER_STATUSES,
} from '../../../static/orderStatuses';
import { round } from 'lodash';
import { formatCurrency } from '../../../mixins/formatCurrency';
import { formatDate } from '../../../mixins/formatDate';
import { sortHandler } from '../../../mixins/sortHandler';
import { dateHandler } from '../../../mixins/dateHandler';
import ConfirmModal from '../../../components/modals/ConfirmModal.vue';
import { countriesCodeHandler } from '../../../mixins/countriesCodeHandler';
import { selectedLanguageComputed } from '../../../store/helper.js';
import VueI18n from '../../../plugins/i18n';
import { permissionRolesMixin } from '../../../mixins/permissionRolesMixin';
import SalesmanService from '../../../services/SalesmanService';

export default {
  components: { TitleWrapper, ConfirmModal },
  mixins: [
    formatCurrency,
    formatDate,
    sortHandler,
    dateHandler,
    countriesCodeHandler,
    permissionRolesMixin,
  ],
  data() {
    return {
      count: 0,
      length: 0,
      dayOfWeek: 1,
      showShipmentModal: false,
      tempOrder: {},
      shipmentNumber: '',
      orders: [],
      loading: true,
      sum: '',
      dateMenu: false,
      serverItemsLength: 0,
      params: {
        search: '',
        visibility: '',
        status: '',
        nip: '',
        sort: '',
        companyName: '',
        dates: [],
        page: 1,
        limit: 10,
        countryCode: '',
      },
    };
  },
  watch: {
    'params.page': function () {
      this.getFilteredData();
    },
    date: function (val) {
      if (val && val.length === 2) {
        if (isBefore(new Date(val[1]), new Date(val[0]))) {
          this.date = [val[1], val[0]];
        }
      }
      this.getFilteredData();
    },
  },
  computed: {
    ...selectedLanguageComputed,
    statuses() {
      return CLIENT_ORDER_STATUSES();
    },
    foreignStatuses() {
      return FOREIGN_CLIENT_STATUSES();
    },
    orderStatuses() {
      return STATUSES();
    },
    filterStatuses() {
      return FILTER_STATUSES();
    },
    headers() {
      const tableHeaders = [
        {
          text: 'ID',
          align: 'start',
          value: 'baselinkerId',
        },
        { text: VueI18n.t('helpers.companyName'), value: 'company.companyName' },
        {
          text: VueI18n.t('helpers.registerForm'),
          value: 'company.nip',
          sortable: false,
        },
        { text: VueI18n.t('helpers.country'), value: 'delivery.countryCode' },
        { text: VueI18n.t('helpers.date'), value: 'date' },
        { text: VueI18n.t('helpers.status'), value: 'status.name' },
        {
          text: `${VueI18n.t('helpers.price')} (${VueI18n.t('helpers.net')})`,
          value: 'convertedTotalPrice',
        },
        { text: '', value: 'actions', sortable: false },
      ];
      return tableHeaders;
    },
    isPaginationVisible() {
      return this.length > 1;
    },
  },
  methods: {
    getTextForUser(id) {
      return this.orderStatuses.find((item) => item.id === id)?.text;
    },
    totalSum(val) {
      return round(val, 2);
    },
    showOrderDetails(orderId) {
      this.$router.push({
        name: this.isSysAdmin ? 'SingleOrder' : 'SalesmanSingleOrder',
        params: { orderId: orderId },
      });
    },
    mapParams() {
      this.sortDates();

      const params = {
        page: this.params.page,
        limit: this.params.limit,
        search: this.params.search,
        status: this.params.status,
        sort: this.params.sort,
        nip: this.params.nip,
        companyName: this.params.companyName,
        visibility: this.params.visibility,
        dateFrom: this.params.dateFrom,
        dateTo: this.params.dateTo,
      };
      return params;
    },

    onSearch() {
      this.params.page = 1;
      this.getFilteredData();
    },
    async getFilteredData() {
      try {
        let response;
        if (this.isSysAdmin) response = await OrderService.getAll(this.mapParams(this.params));
        if (this.isSalesman)
          response = await SalesmanService.getAllSalesmanOrders(this.mapParams(this.params));
        this.orders = response.data;
        this.sum = response.sum;
        this.loading = false;
        this.serverItemsLength = response.count;

        this.length = Math.ceil(response.count / this.params.limit);
      } catch (error) {
        console.error(error);
      }
    },
    async checkChangeStatus(id, status) {
      const currentOrder = this.orders.find((order) => order._id === id);

      if (status === SENT().id && !currentOrder.shipmentNumber) {
        this.tempOrder = { ...currentOrder };
        this.openShipmentModal();
      } else {
        await this.changeStatus(id, status);
      }
    },
    async changeStatus(id, status) {
      try {
        await OrderService.updateStatus(id, status);
        this.getFilteredData();
        this.$toasted.global.success({
          message: VueI18n.t('components.manageOrders.statusChanged'),
        });
      } catch (err) {
        console.error(err);
      }
    },
    async changeOrderStatusToSent() {
      try {
        await OrderService.updateStatus(this.tempOrder._id, SENT().id);
        this.$toasted.global.success({
          message: VueI18n.t('components.manageOrders.statusChanged'),
        });
        this.closeShipmentModal();
        this.getFilteredData();
      } catch (err) {
        console.error(err);
      }
    },
    openShipmentModal() {
      this.showShipmentModal = true;
    },
    closeShipmentModal() {
      this.showShipmentModal = false;
      this.shipmentNumber = '';
      this.tempOrder = {};
    },
    cancelShipmentNumber() {
      this.changeOrderStatusToSent();
    },
    async confirmShipmentNumber() {
      try {
        await OrderService.updateOrder(this.tempOrder._id, {
          ...this.tempOrder,
          shipmentNumber: this.shipmentNumber,
        });
        await this.changeOrderStatusToSent();
      } catch (error) {
        console.error(error);
      }
    },
    onClearDate() {
      this.date = [];
    },
    onEnter() {
      this.getTransactionsFiltered();
      if (this.$vuetify.breakpoint.smAndDown) {
        document.activeElement.blur();
      }
    },
    clearParams() {
      this.params = {
        search: '',
        visibility: '',
        status: '',
        companyName: '',
        nip: '',
        dates: [],
        sort: '',
        page: 1,
        limit: 9,
        dateFrom: '',
        dateTo: '',
      };
      this.getFilteredData();
    },
    fetchSort() {
      this.getFilteredData();
    },
  },
  async activated() {
    await this.getFilteredData();
  },
  async created() {
    await this.getFilteredData();
  },
};
</script>
<style lang="scss" scoped></style>
