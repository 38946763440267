<template>
  <div>
    <v-container class="px-0 d-flex flex-row align-center">
      <v-row>
        <v-col cols="12" sm="4" class="my-auto">
          <v-text-field
            v-model="params.search"
            hide-details
            :placeholder="$t('helpers.filterName')"
            solo
          ></v-text-field
        ></v-col>
        <v-col cols="12" sm="2" class="my-auto">
          <v-text-field
            v-model="params.powerFrom"
            elevation="0"
            :label="$t('helpers.filterPowerFrom')"
            solo
            hide-details
            type="number"
          ></v-text-field
        ></v-col>
        <v-col cols="12" sm="2" class="my-auto">
          <v-text-field
            v-model="params.powerTo"
            elevation="0"
            :label="$t('helpers.filterPowerTo')"
            type="number"
            solo
            hide-details
          ></v-text-field
        ></v-col>
        <v-col cols="12" sm="2" class="my-auto">
          <v-select
            v-model="sortPrice"
            :items="pricingSort"
            elevation="0"
            :label="$t('helpers.filterSortByPrice')"
            solo
            hide-details
          ></v-select
        ></v-col>
        <v-col cols="6" sm="1">
          <v-btn color="primary" elevation="0" fab dark block @click="onSearch">
            <v-icon>mdi-magnify</v-icon>
          </v-btn></v-col
        >
        <v-col cols="6" sm="1">
          <v-btn color="primary" elevation="0" fab dark block @click="clearParams">
            <v-icon>mdi-cancel</v-icon>
          </v-btn></v-col
        ></v-row
      >
    </v-container>
    <v-container class="white" :class="containerPadding ? 'pa-8' : 'pa-0'">
      <v-row>
        <v-col class="font-weight-bold" col="12" sm="2">{{
          $t('components.setsList.title')
        }}</v-col>
        <v-col class="font-weight-bold" col="12" sm="10">
          <v-container class="ma-0 pa-0">
            <v-row justify="end">
              <v-col class="font-weight-bold text-center" col="4" sm="2"
                >{{ $t('helpers.price') }} ({{ $t('helpers.net') }}) /
                {{ $t('helpers.piecesAmount') }}</v-col
              >
              <v-col class="font-weight-bold text-center" col="4" sm="2">{{
                $t('components.setsList.setsPower')
              }}</v-col>
              <v-col class="font-weight-bold text-center" col="4" sm="2">{{
                $t('helpers.amount')
              }}</v-col>
              <v-col class="font-weight-bold text-right" col="4" sm="2"
                >{{ $t('helpers.sum') }} ({{ $t('helpers.net') }})</v-col
              >
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row class="mb-0">
        <v-col class="my-0 py-0"><v-divider></v-divider> </v-col>
      </v-row>
      <div v-if="!count" class="text-center mt-3">{{ $t('components.setsList.notFound') }}</div>
      <catalog-list-item
        v-for="product in products"
        :key="product.id"
        :addAction="addAction"
        :removeAction="removeAction"
        :isDisabledAction="isDisabledAction"
        :onWizard="onWizard"
        :product="product"
        :isCompatibleWithPower="isCompatibleWithPower"
      ></catalog-list-item>
    </v-container>
    <v-row class="mt-3" v-if="isPaginationVisible"
      ><v-col
        ><div class="text-center">
          <v-pagination
            v-model="params.page"
            :length="length"
            :total-visible="params.limit"
          ></v-pagination></div></v-col
    ></v-row>
  </div>
</template>
<script>
import ProductService from '../services/ProductService';
import CatalogListItem from './CatalogListItem.vue';
import { pricingSort } from '../static/pricingSort';
import { categories } from '../static/categoriesList';

export default {
  components: { CatalogListItem },
  name: 'SetsList',
  props: {
    onWizard: { type: Boolean, default: false },
    containerPadding: { type: Boolean, default: true },
    addAction: { type: String },
    removeAction: { type: String },
    isCompatibleWithPower: { type: Boolean, default: true },
    isDisabledAction: { type: Boolean, default: false },
  },
  data() {
    return {
      products: [],
      sortPrice: '',
      sortPhrase: '',
      length: 1,
      count: 0,
      params: {
        category: categories.PRODUCTS_SET.value,
        page: 1,
        limit: 6,
        search: '',
        sort: '-stock',
        powerFrom: '',
        powerTo: '',
      },
    };
  },
  watch: {
    'params.page': function () {
      this.fetchProducts();
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    sortPrice: function () {
      this.sortPhrase = this.sortPrice;
    },
  },
  methods: {
    clearParams() {
      this.sortPhrase = '';
      this.sortPrice = '';
      this.params = {
        category: categories.PRODUCTS_SET.value,
        page: 1,
        limit: 6,
        search: '',
        sort: '-stock',
        powerFrom: '',
        powerTo: '',
      };
      this.fetchProducts();
    },
    onSearch() {
      this.params.page = 1;
      if (this.sortPhrase) this.params.sort = this.sortPhrase;
      this.fetchProducts();
    },
    async fetchProducts() {
      try {
        const response = await ProductService.getAll({
          ...this.params,
          visibility: true,
        });

        this.products = response.data;
        this.count = response.count;
        this.length = Math.ceil(response.count / this.params.limit);
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    isPaginationVisible() {
      return this.length > 1;
    },
    pricingSort() {
      return pricingSort();
    },
  },
  created() {
    this.fetchProducts();
  },
};
</script>
<style lang="scss" scoped></style>
