import VueI18n from '../plugins/i18n';

export const categories = {
  PHOTO_MODULES: {
    value: 'PHOTO_MODULES',
    text: VueI18n.t('statics.categoriesList.PHOTO_MODULES'),
  },
  INVERTERS: {
    value: 'INVERTERS',
    text: VueI18n.t('statics.categoriesList.INVERTERS'),
  },
  OPTIMIZERS: {
    value: 'OPTIMIZERS',
    text: VueI18n.t('statics.categoriesList.OPTIMIZERS'),
  },
  MOUNTING_ACCESSORIES: {
    value: 'MOUNTING_ACCESSORIES',
    text: VueI18n.t('statics.categoriesList.MOUNTING_ACCESSORIES'),
  },
  GROUNDED_CABLE: {
    value: 'GROUNDED_CABLE',
    text: VueI18n.t('statics.categoriesList.GROUNDED_CABLE'),
  },
  CABEL_DC: {
    value: 'CABEL_DC',
    text: VueI18n.t('statics.categoriesList.CABEL_DC'),
  },
  SWITCHGEAR: {
    value: 'SWITCHGEAR',
    text: VueI18n.t('statics.categoriesList.SWITCHGEAR'),
  },
  RESIDUAL: {
    value: 'RESIDUAL',
    text: VueI18n.t('statics.categoriesList.RESIDUAL'),
  },
  EARTHING: {
    value: 'EARTHING',
    text: VueI18n.t('statics.categoriesList.EARTHING'),
  },
  INSPECTION_BOX: {
    value: 'INSPECTION_BOX',
    text: VueI18n.t('statics.categoriesList.INSPECTION_BOX'),
  },
  CONDUIT: {
    value: 'CONDUIT',
    text: VueI18n.t('statics.categoriesList.CONDUIT'),
  },
  MC4: {
    value: 'MC4',
    text: VueI18n.t('statics.categoriesList.MC4'),
  },
  ELECTRICK_SHOCK: {
    value: 'ELECTRICK_SHOCK',
    text: VueI18n.t('statics.categoriesList.ELECTRICK_SHOCK'),
  },
  OTHERS: {
    value: 'OTHERS',
    text: VueI18n.t('statics.categoriesList.OTHERS'),
  },
  PRODUCTS_SET: {
    value: 'PRODUCTS_SET',
    text: VueI18n.t('statics.categoriesList.PRODUCTS_SET'),
  },
};

export const categoriesFunc = () => {
  const categoriesArray = [
    {
      value: 'PHOTO_MODULES',
      text: VueI18n.t('statics.categoriesList.PHOTO_MODULES'),
    },
    {
      value: 'INVERTERS',
      text: VueI18n.t('statics.categoriesList.INVERTERS'),
    },
    {
      value: 'OPTIMIZERS',
      text: VueI18n.t('statics.categoriesList.OPTIMIZERS'),
    },
    {
      value: 'MOUNTING_ACCESSORIES',
      text: VueI18n.t('statics.categoriesList.MOUNTING_ACCESSORIES'),
    },
    {
      value: 'GROUNDED_CABLE',
      text: VueI18n.t('statics.categoriesList.GROUNDED_CABLE'),
    },
    {
      value: 'CABEL_DC',
      text: VueI18n.t('statics.categoriesList.CABEL_DC'),
    },
    {
      value: 'SWITCHGEAR',
      text: VueI18n.t('statics.categoriesList.SWITCHGEAR'),
    },
    {
      value: 'RESIDUAL',
      text: VueI18n.t('statics.categoriesList.RESIDUAL'),
    },
    {
      value: 'EARTHING',
      text: VueI18n.t('statics.categoriesList.EARTHING'),
    },
    {
      value: 'INSPECTION_BOX',
      text: VueI18n.t('statics.categoriesList.INSPECTION_BOX'),
    },
    {
      value: 'CONDUIT',
      text: VueI18n.t('statics.categoriesList.CONDUIT'),
    },
    {
      value: 'MC4',
      text: VueI18n.t('statics.categoriesList.MC4'),
    },
    {
      value: 'ELECTRICK_SHOCK',
      text: VueI18n.t('statics.categoriesList.ELECTRICK_SHOCK'),
    },
    {
      value: 'OTHERS',
      text: VueI18n.t('statics.categoriesList.OTHERS'),
    },
    {
      value: 'PRODUCTS_SET',
      text: VueI18n.t('statics.categoriesList.PRODUCTS_SET'),
    },
  ];
  return categoriesArray;
};

export const electricalInstallationEquipmentSubcategories = Object.freeze({
  GROUNDED_CABLE: {
    value: 'GROUNDED_CABLE',
    text: VueI18n.t('statics.categoriesList.GROUNDED_CABLE'),
  },
  CABEL_DC: {
    value: 'CABEL_DC',
    text: VueI18n.t('statics.categoriesList.CABEL_DC'),
  },
  SWITCHGEAR: {
    value: 'SWITCHGEAR',
    text: VueI18n.t('statics.categoriesList.SWITCHGEAR'),
  },
  RESIDUAL: {
    value: 'RESIDUAL',
    text: VueI18n.t('statics.categoriesList.RESIDUAL'),
  },
  EARTHING: {
    value: 'EARTHING',
    text: VueI18n.t('statics.categoriesList.EARTHING'),
  },
  INSPECTION_BOX: {
    value: 'INSPECTION_BOX',
    text: VueI18n.t('statics.categoriesList.INSPECTION_BOX'),
  },
  CONDUIT: {
    value: 'CONDUIT',
    text: VueI18n.t('statics.categoriesList.CONDUIT'),
  },
  MC4: {
    value: 'MC4',
    text: VueI18n.t('statics.categoriesList.MC4'),
  },
  ELECTRICK_SHOCK: {
    value: 'ELECTRICK_SHOCK',
    text: VueI18n.t('statics.categoriesList.ELECTRICK_SHOCK'),
  },
});
