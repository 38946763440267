<template>
  <div>
    <small v-if="inProductItem"
      >{{ $t('components.quantityInput') }}: {{ supply > 0 ? supply : $t('helpers.noData') }}</small
    >
    <div class="d-flex flex-row align-center justify-center">
      <v-tooltip bottom :disabled="!disabledMinus">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <v-btn :disabled="disabled || disabledMinus" icon @click="decrementQuantity">
              <v-icon color="red"> mdi-minus-circle-outline </v-icon>
            </v-btn>
          </div>
        </template>
        <available-group-prices
          :availableGroupPrices="availableGroupPrices"
        ></available-group-prices>
      </v-tooltip>
      <input
        class="quantity"
        :min="min"
        :max="supply"
        :disabled="disabled"
        v-model="currQuantity"
        persistent-hint
        @blur="onBlur"
        elevation="0"
        outlined
        solo
      />
      <v-tooltip bottom :disabled="!disabledPlus">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <v-btn
              class="mr-3"
              :disabled="disabled || disabledPlus"
              icon
              @click="incrementQuantity"
            >
              <v-icon color="green"> mdi-plus-circle-outline</v-icon>
            </v-btn>
          </div>
        </template>
        <available-group-prices
          :availableGroupPrices="availableGroupPrices"
        ></available-group-prices>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import { toNumber, gte, lte } from 'lodash';
import AvailableGroupPrices from './AvailableGroupPrices.vue';
export default {
  name: 'QuantityInput',
  components: { AvailableGroupPrices },
  props: {
    quantity: { type: Number, default: 0 },
    supply: { type: Number, default: Number.MAX_SAFE_INTEGER },
    disabled: { type: Boolean, default: false },
    disabledPlus: { type: Boolean, default: false },
    disabledMinus: { type: Boolean, default: false },
    min: { type: Number, default: 0 },
    inProductItem: { type: Boolean, default: false },
    availableGroupPrices: { type: Array },
  },
  data() {
    return {
      currQuantity: this.quantity,
    };
  },
  watch: {
    currQuantity: function (newVal) {
      if (
        (this.isQuantityAllowedInAvailableGroups(newVal) && this.inProductItem) ||
        !this.inProductItem
      ) {
        if (newVal > this.min && newVal <= this.supply) {
          this.currQuantity = toNumber(newVal);
          this.$emit('quantityChange', toNumber(newVal));
        } else {
          this.currQuantity = 0;
          this.$emit('quantityChange', 0);
        }
      }
    },
  },
  methods: {
    onBlur() {
      if (!this.isQuantityAllowedInAvailableGroups(this.currQuantity) && this.inProductItem) {
        this.currQuantity = this.quantity;
        this.$emit('quantityChange', this.quantity);
      }
    },
    incrementQuantity() {
      if (this.currQuantity < this.supply) {
        this.currQuantity++;
      }
    },
    decrementQuantity() {
      if (this.currQuantity > this.min) {
        this.currQuantity--;
      }
    },
    isQuantityAllowedInAvailableGroups(quantity) {
      return this.availableGroupPrices.some((groupPrice) => {
        const {
          limitations: { min, max },
        } = groupPrice;
        return gte(quantity, min) && lte(quantity, max);
      });
    },
  },
};
</script>
<style>
.quantity {
  margin: 0 0.25rem 0.25rem 0.25rem;
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
  width: 75px;
  text-align: center;
  border: 0.125rem solid #c4c4c4;
}
</style>
