<template>
  <title-wrapper :title="$t('components.wizard.tcsType.title')">
    <clickable-section
      v-if="handleTypes[pourType]"
      :items="handleTypes[pourType]"
      @updateSelected="updateSelected"
      :active="selectedTcsType"
    ></clickable-section>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../components/TitleWrapper.vue';
import ClickableSection from '../../../components/ClickableSection.vue';
import { tcsTypeComputed, pourTypeComputed } from '../../../store/helper.js';
import { TSC_TYPES } from '../../../static/fotoConstants.js';
import wkretTCS from '@/assets/wizzardImages/wkretTCS.png';

export default {
  name: 'TcsType',
  components: { TitleWrapper, ClickableSection },
  data() {
    return {
      isValid: false,
      selectedTcsType: '',
      storeAction: 'setTcsType',
      handleTypes: {
        'Dachówka ceramiczna': [
          {
            value: TSC_TYPES.M8X60,
            text: TSC_TYPES.M8X60,
            imgPath: wkretTCS,
          },
          {
            value: TSC_TYPES.M8X80,
            text: TSC_TYPES.M8X80,
            imgPath: wkretTCS,
          },
          {
            value: TSC_TYPES.M8X120,
            text: TSC_TYPES.M8X120,
            imgPath: wkretTCS,
          },
        ],
        Karpiówka: [
          {
            value: TSC_TYPES.M8X60,
            text: TSC_TYPES.M8X60,
            imgPath: wkretTCS,
          },
          {
            value: TSC_TYPES.M8X80,
            text: TSC_TYPES.M8X80,
            imgPath: wkretTCS,
          },
          {
            value: TSC_TYPES.M8X120,
            text: TSC_TYPES.M8X120,
            imgPath: wkretTCS,
          },
        ],
        'Gont(łupek)': [
          {
            value: TSC_TYPES.M8X60,
            text: TSC_TYPES.M8X60,
            imgPath: wkretTCS,
          },
        ],
        Rąbek: [
          {
            value: TSC_TYPES.M8X60,
            text: TSC_TYPES.M8X60,
            imgPath: wkretTCS,
          },
        ],
      },
    };
  },
  computed: {
    ...tcsTypeComputed,
    ...pourTypeComputed,
  },
  activated() {
    this.selectedTcsType = this.tcsType;

    if (this.selectedTcsType) {
      this.$emit('isFowardEnabled', true);
    } else {
      this.isValid = false;
      this.$emit('isFowardEnabled', false);
    }
  },
  methods: {
    updateSelected(tcsType) {
      this.selectedTcsType = tcsType;
      this.isValid = true;
      this.$store.commit(this.storeAction, this.selectedTcsType);
      this.$emit('isFowardEnabled', this.isValid);
    },
  },
};
</script>
<style lang="scss" scoped></style>
