<template>
  <footer>
    <v-card elevation="0" class="pt-3">
      <v-row justify="center" class="pa-3">
        <language-switcher class="switcher" :onFooter="true"></language-switcher>
        <currency-switcher v-if="isLoggedIn" class="switcher" :onFooter="true"></currency-switcher>
        <span
          class="mx-3 my-1 text-caption text--disabled linkTo"
          @click="goTo('ProcessingPersonalData')"
          >{{ $t('components.footer.conditionsProcessingPersonalData') }}</span
        >
        <v-divider vertical></v-divider>
        <span
          class="mx-3 my-1 text-caption text--disabled linkTo"
          @click="goTo('ConditionsOfPayment')"
          >{{ $t('components.footer.paymentConditions') }}</span
        >
        <v-divider vertical></v-divider>
        <span
          class="mx-3 my-1 text-caption text--disabled linkTo"
          @click="goTo('TermsOfOrderFulfillment')"
          >{{ $t('components.footer.termsOfOrderFulfillment') }}</span
        >
      </v-row>
    </v-card>
  </footer>
</template>

<script>
import LanguageSwitcher from './LanguageSwitcher.vue';
import CurrencySwitcher from './CurrencySwitcher.vue';
import { permissionRolesMixin } from '../mixins/permissionRolesMixin';

export default {
  name: 'FooterComponent',
  components: { LanguageSwitcher, CurrencySwitcher },
  mixins: [permissionRolesMixin],
  methods: {
    goTo(path) {
      this.$router.push({ name: path });
    },
  },
};
</script>

<style lang="scss" scoped>
.linkTo {
  cursor: pointer;
}
.switcher {
  display: none;
  @media (max-width: 1350px) {
    display: block;
  }
}
</style>
