<template>
  <title-wrapper :title="$t('components.wizard.electricalInstallationEquipment.title')">
    <collapse
      v-for="category in categories"
      :key="category.value"
      :title="$t(`statics.categoriesList.${category.value}`)"
    >
      <catalog-list
        :limit="limit"
        :category="category.value"
        :onWizard="onWizard"
        :filters="filters"
        :searchActive="searchActive"
        :containerPadding="containerPadding"
      ></catalog-list>
    </collapse>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../components/TitleWrapper.vue';
import Collapse from '../../components/Collapse.vue';
import CatalogList from '../../components/CatalogList.vue';
import { electricalInstallationEquipmentSubcategories } from '../../static/categoriesList.js';
import { cartComputed } from '../../store/helper.js';
export default {
  name: 'ElectricalInstallationEquipment',
  components: { TitleWrapper, Collapse, CatalogList },
  data() {
    return {
      categories: electricalInstallationEquipmentSubcategories,
      limit: 3,
      onWizard: true,
      filters: false,
      searchActive: false,
      containerPadding: false,
      wizardStep: 6,
    };
  },
  methods: {
    handleIncompatibility() {
      const electricalInstallationCategories = Object.values(this.categories);
      const electricalInstallationEquipmentProducts = this.cart.filter((product) =>
        electricalInstallationCategories.some((item) => item.value === product.category)
      );
      if (electricalInstallationEquipmentProducts.some((pr) => pr.incompatible)) {
        this.$store.commit('addToIncompatibleSteps', this.wizardStep);
      } else {
        this.$store.commit('removeFromIncompatibleSteps', this.wizardStep);
      }
    },
  },
  computed: {
    ...cartComputed,
  },
  activated() {
    this.$emit('isFowardEnabled', true);
    this.handleIncompatibility();
  },
};
</script>
<style lang="scss" scoped></style>
