<template>
  <title-wrapper :title="$t('components.manageOffers.title')">
    <div>
      <v-progress-linear v-if="loading" indeterminate color="primary"> </v-progress-linear>
      <v-container v-else class="px-0">
        <v-row class="align-center">
          <v-col cols="12" sm="4">
            <v-menu
              ref="menu"
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="slide-y-transition"
              offset-y
              :nudge-top="-10"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="params.dates"
                  :label="$t('helpers.date')"
                  readonly
                  hide-details
                  clearable
                  @click:clear="onClearDate"
                  :placeholder="$t('helpers.date')"
                  solo
                  v-bind="attrs"
                  v-on="on"
                >
                  <template slot="append">
                    <v-icon small :class="{ active: dateMenu }">$arrowIcon</v-icon>
                  </template></v-text-field
                >
              </template>
              <v-date-picker
                v-model="params.dates"
                :width="$vuetify.breakpoint.smAndDown ? '100%' : '400'"
                no-title
                scrollable
                range
                :first-day-of-week="dayOfWeek"
                :locale="selectedLanguage"
                :min="params.dates ? params.dates[0] : ''"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="6" sm="1">
            <v-btn color="primary" elevation="0" fab dark block @click="onSearch">
              <v-icon>mdi-magnify</v-icon>
            </v-btn></v-col
          >
          <v-col cols="6" sm="1">
            <v-btn color="primary" elevation="0" fab dark block @click="clearParams">
              <v-icon>mdi-cancel</v-icon>
            </v-btn></v-col
          >
        </v-row>
        <v-container class="white my-4">
          <v-row
            ><v-col
              ><v-data-table
                :headers="headers"
                :items="offers"
                :items-per-page="params.limit"
                hide-default-footer
                class="elevation-0"
                :sortBy="sortBy"
                :sortDesc="sortDesc"
                @update:sort-by="sortByFunc"
                @update:sort-desc="sortDescFunc"
                :no-data-text="this.$t('components.myDataTable.noDataAvailable')"
              >
                <template v-slot:[`item.convertedTotalPrice`]="{ item }">
                  <span>{{ formatCurrency(item.totalPrice, item.currency, false) }}</span>
                </template>
                <template v-slot:[`item.date`]="{ item }">
                  <span>{{ item.date }}</span>
                </template>

                <template v-slot:[`item.fullName`]="{ item }">
                  <span>{{ item.user.fullName }} </span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu :close-on-content-click="true" :nudge-width="200" offset-x>
                    <template v-slot:activator="{ on }">
                      <v-icon small color="black" class="mr-2" v-on="on">
                        mdi-dots-vertical
                      </v-icon>
                    </template>
                    <v-card>
                      <v-list-item
                        class="hover-item-popup"
                        @click="
                          () => {
                            showDetails(item._id);
                          }
                        "
                      >
                        <v-list-item-content>
                          {{ $t('helpers.showDetails') }}
                        </v-list-item-content> </v-list-item
                      ><v-list-item class="hover-item-popup" @click="handleModals(item)">
                        <v-list-item-content>
                          {{ $t('components.manageOffers.addToCart') }}
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        class="hover-item-popup"
                        @click="
                          () => {
                            deleteOffer(item._id);
                          }
                        "
                      >
                        <v-list-item-content>{{ $t('helpers.remove') }}</v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-menu>
                </template></v-data-table
              ></v-col
            ></v-row
          >
          <slot name="below"></slot>
        </v-container>
        <confirm-modal
          :title="$t('components.manageOffers.modalOne.title')"
          @accept="confirmForward"
          @decline="decline"
          :open="showModal"
          :agree="$t('components.manageOffers.modalOne.agree')"
        >
          {{ $t('components.manageOffers.modalOne.text') }}
        </confirm-modal>
        <confirm-modal
          :title="$t('components.manageOffers.modalTwo.title')"
          @accept="confirmWithDiffTotalPrice"
          @decline="decline"
          :open="showDiffTotalPriceModal"
          :agree="$t('components.manageOffers.modalTwo.agree')"
        >
          {{ $t('components.manageOffers.modalTwo.text.partOne') }}
          <strong>{{ currentProductsPrice() }}</strong
          >{{ $t('components.manageOffers.modalTwo.text.partTwo', { currency: selectedCurrency }) }}
        </confirm-modal>
        <v-row v-if="isPaginationVisible"
          ><v-col
            ><div class="text-center">
              <v-pagination
                v-model="params.page"
                :length="length"
                total-visible="5"
              ></v-pagination></div></v-col
        ></v-row>
      </v-container>
    </div>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../components/TitleWrapper.vue';
import OfferService from '../../../services/OfferService';
import { format, isBefore } from 'date-fns';
import {
  userDataComputed,
  selectedLanguageComputed,
  selectedCurrencyComputed,
} from '../../../store/helper';
import { pl } from 'date-fns/locale';
import { formatCurrency } from '../../../mixins/formatCurrency';
import { sortHandler } from '../../../mixins/sortHandler';
import { dateHandler } from '../../../mixins/dateHandler';
import { productUnitPrice } from '../../../mixins/productUnitPrice';
import { permissionRolesMixin } from '../../../mixins/permissionRolesMixin';
import ConfirmModal from '../../../components/modals/ConfirmModal.vue';
import { sum, round } from 'lodash';
import VueI18n from '../../../plugins/i18n';

export default {
  components: { TitleWrapper, ConfirmModal },
  mixins: [formatCurrency, sortHandler, dateHandler, productUnitPrice, permissionRolesMixin],
  data() {
    return {
      count: 0,
      length: 0,
      dayOfWeek: 1,
      showModal: false,
      showDiffTotalPriceModal: false,
      currentItem: {},
      offers: [],
      loading: true,
      dateMenu: false,
      productsCount: 0,
      params: {
        dates: [],
        sort: '',
        page: 1,
        limit: 10,
      },
    };
  },

  watch: {
    'params.page': function () {
      this.getFilteredByRole();
    },
    date: function (val) {
      if (val && val.length === 2) {
        if (isBefore(new Date(val[1]), new Date(val[0]))) {
          this.date = [val[1], val[0]];
        }
      }
      this.getFilteredByRole();
    },
  },
  computed: {
    ...selectedLanguageComputed,
    ...selectedCurrencyComputed,
    ...userDataComputed,
    headers() {
      const tableHeaders = [
        { text: VueI18n.t('helpers.totalPrice'), value: 'convertedTotalPrice' },
        { text: VueI18n.t('helpers.author'), value: 'user.fullName' },
        { text: VueI18n.t('helpers.date'), value: 'date' },
        { text: '', value: 'actions', sortable: false },
      ];
      return tableHeaders;
    },
    isPaginationVisible() {
      return this.length > 1;
    },
  },
  methods: {
    fetchSort() {
      this.getFilteredByRole();
    },
    confirmWithDiffTotalPrice() {
      this.showDiffTotalPriceModal = false;
      this.showModal = true;
    },
    addToCart(offer) {
      const products = offer.productsRefs.map((product) => {
        return {
          ...product.product,
          quantity: parseInt(product.quantity),
        };
      });
      this.$store.commit('addProductsToCart', products);
      this.$router.push({ name: 'TransactionSummary' });
    },
    showDetails(offerId) {
      this.$router.push({ name: 'SingleOfferIndex', params: { offerId: offerId } });
    },
    mapParams() {
      this.sortDates();

      const params = {
        page: this.params.page,
        limit: this.params.limit,
        sort: this.params.sort,
        dateFrom: this.params.dateFrom,
        dateTo: this.params.dateTo,
      };

      return params;
    },
    async getFilteredEmployeeData() {
      try {
        const response = await OfferService.getAllUserOffers(this.mapParams(this.params));
        this.offers = response.data.map((item) => {
          item.date = format(new Date(item.createdAt), 'HH:mm, dd.MM.yyyy', { locale: pl });
          return item;
        });
        this.loading = false;
        this.length = Math.ceil(response.count / this.params.limit);
      } catch (error) {
        console.error(error);
      }
    },
    async getFilteredAdminData() {
      try {
        const response = await OfferService.getAllCompanyOffers(this.mapParams(this.params));
        this.offers = response.data.map((item) => {
          item.date = format(new Date(item.createdAt), 'HH:mm, dd.MM.yyyy', { locale: pl });
          return item;
        });
        this.loading = false;
        this.length = Math.ceil(response.count / this.params.limit);
      } catch (error) {
        console.error(error);
      }
    },
    onClearDate() {
      this.date = [];
    },
    onEnter() {
      this.getTransactionsFiltered();
      if (this.$vuetify.breakpoint.smAndDown) {
        document.activeElement.blur();
      }
    },
    onSearch() {
      this.params.page = 1;
      this.getFilteredByRole();
    },
    getFilteredByRole() {
      if (this.isCompanyAdmin) {
        this.getFilteredAdminData();
      } else {
        this.getFilteredEmployeeData();
      }
    },
    clearParams() {
      this.params = {
        sort: '',
        dates: [],
        page: 1,
        limit: 9,
        dateFrom: '',
        dateTo: '',
      };
      this.getFilteredByRole();
    },
    async deleteOffer(id) {
      try {
        await OfferService.delete(id);
        this.getFilteredByRole();
        this.$toasted.global.success({
          message: VueI18n.t('components.manageOffers.deleteOfferFunc'),
        });
      } catch (error) {
        console.error(error);
      }
    },
    currentProductsPrice() {
      const productsSum = this.currentItem?.productsRefs?.map((product) =>
        round(this.productUnitPrice(product.product) * parseFloat(product.quantity), 2)
      );
      return round(sum(productsSum), 2);
    },
    async handleModals(offer) {
      const response = await OfferService.getOffer(offer._id);
      this.currentItem = response.data;

      if (this.currentProductsPrice() !== offer.totalPrice)
        return (this.showDiffTotalPriceModal = true);
      this.showModal = true;
    },
    decline() {
      this.showModal = false;
      this.showDiffTotalPriceModal = false;
      this.currentItem = {};
    },
    confirmForward() {
      this.showModal = false;
      this.addToCart(this.currentItem);
      this.currentItem = {};
    },
  },

  created() {
    this.getFilteredByRole();
  },
};
</script>
<style lang="scss" scoped></style>
