import i18n from '../plugins/i18n';

const isValidZipCode = (code) => {
  return /^\d{2}-\d{3}$/.test(code);
};

const isEmpty = (value) => {
  return value === undefined || value === null || value === '';
};

const checkIfNumber = (value) => {
  if (isEmpty(value)) {
    return true;
  }
  return /^\d+[.\s,]?\d*$/.test(value);
};

export const isValidNip = (nip) => {
  if (typeof nip !== 'string') return false;

  // eslint-disable-next-line
  nip = nip.replace(/[\ \-]/gi, '');

  let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  let controlNumber = parseInt(nip.substring(9, 10));
  let weightCount = weight.length;
  for (let i = 0; i < weightCount; i++) {
    sum += parseInt(nip.substr(i, 1)) * weight[i];
  }

  return sum % 11 === controlNumber;
};

export const isValidRowNumber = (rowNumber) => {
  if (parseFloat(rowNumber) > 30 || parseFloat(rowNumber) < 1) return false;
  if (!Number.isInteger(parseFloat(rowNumber))) return false;
  return true;
};

export const validators = {
  passwordRules: [
    (v) => {
      return !!v || i18n.t('validators.required');
    },
    (v) => (v && v.length >= 8) || i18n.t('validators.passwordRules.ruleOne'),
    (v) => /(?=.*[A-Z])/.test(v) || i18n.t('validators.passwordRules.ruleTwo'),
    (v) => /(?=.*\d)/.test(v) || i18n.t('validators.passwordRules.ruleThree'),
    (v) =>
      /([!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/.test(v) || i18n.t('validators.passwordRules.ruleFour'),
    (v) => /(?=.*[a-z])/.test(v) || i18n.t('validators.passwordRules.ruleFive'),
  ],
  emailRules: [
    (v) => !!v || i18n.t('validators.required'),
    (value) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || i18n.t('validators.emailRules');
    },
  ],
  nipRules: [
    (v) => !!v || i18n.t('validators.required'),
    (value) => {
      return isValidNip(value) || i18n.t('validators.nipRules');
    },
  ],
  foreignNipRules: [
    (v) => !!v || i18n.t('validators.required'),
    (value) => {
      return (value.length >= 6 && /^\S*$/.test(value)) || i18n.t('validators.nipRules');
    },
  ],
  zipCodeRules: [
    (v) => !!v || i18n.t('validators.required'),
    (value) => {
      return isValidZipCode(value) || i18n.t('validators.zipCodeRules');
    },
  ],
  shortDescRules: [(v) => v?.length <= 150 || i18n.t('validators.shortDescRules')],
  longDescRules: [(v) => v?.length <= 1000 || i18n.t('validators.longDescRules')],
  declaredPowerRules: [
    (v) => !!v.toString() || i18n.t('validators.required'),
    (value) => {
      const parsedValue = parseFloat(value) > 0;
      return parsedValue || i18n.t('validators.declaredPowerRules');
    },
  ],
  rowsNumberRules: [
    (v) => !!v || i18n.t('validators.required'),
    (v) => {
      const parsedValue = parseFloat(v) > 0 && parseFloat(v) <= 30;
      return parsedValue || i18n.t('validators.rowsNumberRules');
    },
    (v) => {
      const parsedValue = Number.isInteger(parseFloat(v));
      return parsedValue || i18n.t('validators.integerNumber');
    },
  ],
  modulesNumberRules: [
    (v) => {
      const parsedValue = parseFloat(v) >= 0 && parseFloat(v) <= 30;
      return parsedValue || i18n.t('validators.modulesNumberRules');
    },
    (v) => {
      const parsedValue = Number.isInteger(parseFloat(v));
      return parsedValue || i18n.t('validators.integerNumber');
    },
  ],
  deliveryRules: [
    (v) => !!v || i18n.t('validators.required'),
    (v) => {
      const parsedValue = parseFloat(v) >= 1 && parseFloat(v) <= 30;
      return parsedValue || i18n.t('validators.deliveryRules');
    },
    (v) => {
      const parsedValue = Number.isInteger(parseFloat(v));
      return parsedValue || i18n.t('validators.integerNumber');
    },
  ],
  discountRules: [
    (v) => !!v || i18n.t('validators.required'),
    (v) => {
      const parsedValue = parseFloat(v) >= 0 && parseFloat(v) <= 100;
      return parsedValue || i18n.t('validators.badValue');
    },
  ],
  discountGroupQuantityRules: [
    (v) => {
      const parsedValue = parseFloat(v) > 0;
      return parsedValue || i18n.t('validators.discountGroupQuantityRules');
    },
    (v) => !!v || i18n.t('validators.required'),
    (v) => {
      const parsedValue = Number.isInteger(parseFloat(v));
      return parsedValue || i18n.t('validators.integerNumber');
    },
  ],
  noRequiredZipCodeRules: [
    (value) => {
      let verified = false;
      if (value === '') {
        verified = true;
      } else if (isValidZipCode(value)) {
        verified = true;
      }
      return verified || i18n.t('validators.zipCodeRules');
    },
  ],
  requiredRules: [
    (value) => !!value || i18n.t('validators.required'),
    (value) => !!value.replace(/\s/g, '').length || i18n.t('validators.noSpaces'),
  ],
  productsSetPowerRules: [
    (value) => !!value || i18n.t('validators.required'),
    (value) => !!value.replace(/\s/g, '').length || i18n.t('validators.noSpaces'),
    (value) => checkIfNumber(value) || i18n.t('validators.numberRequired'),
    (value) => isEmpty(value) || value >= 0 || i18n.t('validators.nonNegativeValue'),
  ],
  attributeRules: [
    (value) => checkIfNumber(value) || i18n.t('validators.numberRequired'),
    (value) => isEmpty(value) || value >= 0 || i18n.t('validators.nonNegativeValue'),
  ],
  priceGroupRules: [
    (value) => !!value.toString() || i18n.t('validators.priceGroupRules.ruleOne'),
    (value) => value >= 0 || i18n.t('validators.nonNegativeValue'),
    (value) => {
      const valueContainsComma = value.toString().includes('.');
      let spllitedValue = true;
      if (valueContainsComma) {
        spllitedValue = value.toString().split('.')[1]?.length <= 2;
      }
      return spllitedValue || i18n.t('validators.priceGroupRules.ruleTwo');
    },
  ],
  checkboxRequiredRules: [(value) => !!value || i18n.t('validators.required')],
  fileRules: [(file) => !file || !(file.size > 4097152) || i18n.t('validators.fileRules')],
  phoneRules: [
    (value) => !!value || i18n.t('validators.required'),
    (value) => {
      let re = /^\s*\+?\s*([0-9][\s-]*){9,}$/;
      return re.test(value) || i18n.t('validators.phoneRules');
    },
  ],
  addPhoneRules: [
    (value) => {
      if (!value) return true;
    },
    (value) => {
      if (value) {
        let re = /^\s*\+?\s*([0-9][\s-]*){9,}$/;
        return re.test(value) || i18n.t('validators.phoneRules');
      }
    },
  ],
  companyName: [
    (value) => {
      if (!value) return true;
    },
    (value) => {
      if (value) return value.replace(/\s/g, '').length || i18n.t('validators.noSpaces');
    },
  ],
  samePasswordRule: (password, value) => {
    return value === password || i18n.t('validators.samePasswordRule');
  },

  counter: (value) => value.length <= 50 || i18n.t('validators.counterRules'),

  currencyRules: [
    (v) => !!v || i18n.t('validators.required'),
    (v) =>
      isEmpty(v) ||
      (checkIfNumber(v) && parseFloat(v) >= 0) ||
      i18n.t('validators.nonNegativeValue'),
  ],
};
