import VueI18n from '../plugins/i18n';

const NEW_ORDER = () => {
  return {
    id: 84254,
    name: 'Zamówienie nieopłacone',
    text: VueI18n.t('statics.orderStatuses.NEW_ORDER.text'),
    name_for_customer: VueI18n.t('statics.orderStatuses.NEW_ORDER.nameForCustomer'),
    listItemText: VueI18n.t('statics.orderStatuses.NEW_ORDER.listItemText'),
    color: '#0077da',
  };
};

const PAID = () => {
  return {
    id: 84255,
    name: 'Opłacone',
    text: VueI18n.t('statics.orderStatuses.PAID.text'),
    name_for_customer: VueI18n.t('statics.orderStatuses.PAID.nameForCustomer'),
    listItemText: VueI18n.t('statics.orderStatuses.PAID.listItemText'),
    color: '#ea864d',
  };
};

export const SENT = () => {
  return {
    id: 84256,
    name: 'Wysłane',
    text: VueI18n.t('statics.orderStatuses.SENT.text'),
    name_for_customer: VueI18n.t('statics.orderStatuses.SENT.nameForCustomer'),
    listItemText: VueI18n.t('statics.orderStatuses.SENT.listItemText'),
    color: '#22A564',
  };
};

const CANCELLED = () => {
  return {
    id: 84257,
    name: 'Anulowane',
    text: VueI18n.t('statics.orderStatuses.CANCELLED.text'),
    name_for_customer: VueI18n.t('statics.orderStatuses.CANCELLED.nameForCustomer'),
    listItemText: VueI18n.t('statics.orderStatuses.CANCELLED.listItemText'),
    color: '#D54839',
  };
};

const EXAMPLE_STATUS = {
  id: 84258,
  name: 'Przykładowy status',
  text: VueI18n.t('statics.orderStatuses.EXAMPLE_STATUS.text'),
  name_for_customer: VueI18n.t('statics.orderStatuses.EXAMPLE_STATUS.nameForCustomer'),
  color: '#4E565F',
};

const TO_IMPORT = {
  id: 95217,
  name: 'Do importu',
  text: VueI18n.t('statics.orderStatuses.TO_IMPORT.text'),
  name_for_customer: VueI18n.t('statics.orderStatuses.TO_IMPORT.nameForCustomer'),
  color: '#f4b04f',
};

const IMPORTED = {
  id: 95218,
  name: 'Zaimportowane',
  text: VueI18n.t('statics.orderStatuses.IMPORTED.text'),
  name_for_customer: VueI18n.t('statics.orderStatuses.IMPORTED.nameForCustomer'),
  color: '#f4b04f',
};

const IMPORT_ERROR = {
  id: 95220,
  name: 'Błąd przy imporcie',
  text: VueI18n.t('statics.orderStatuses.IMPORT_ERROR.text'),
  name_for_customer: VueI18n.t('statics.orderStatuses.IMPORT_ERROR.nameForCustomer'),
  color: '#f4b04f',
};

const FOR_CLIENT_FV = () => {
  return {
    id: 106171,
    name: 'FV dla klienta',
    text: VueI18n.t('statics.orderStatuses.FOR_CLIENT_FV.text'),
    name_for_customer: VueI18n.t('statics.orderStatuses.FOR_CLIENT_FV.nameForCustomer'),
    color: '#b34ff5',
  };
};

const FOR_ADMIN_FV = () => {
  return {
    id: 106172,
    name: 'FV dla admina',
    text: VueI18n.t('statics.orderStatuses.FOR_ADMIN_FV.text'),
    name_for_customer: VueI18n.t('statics.orderStatuses.FOR_ADMIN_FV.nameForCustomer'),
    color: '#f50000',
  };
};

const FOR_CLIENT_NOT_PAID_PRO_FV = () => {
  return {
    id: 106169,
    name: 'Proforma dla klienta (n-opl)',
    text: VueI18n.t('statics.orderStatuses.FOR_CLIENT_NOT_PAID_PRO_FV.text'),
    name_for_customer: VueI18n.t(
      'statics.orderStatuses.FOR_CLIENT_NOT_PAID_PRO_FV.nameForCustomer'
    ),
    color: '#b34ff5',
  };
};
const FOR_ADMIN_NOT_PAID_PRO_FV = () => {
  return {
    id: 106170,
    name: 'Proforma dla admina (n-opl)',
    text: VueI18n.t('statics.orderStatuses.FOR_ADMIN_NOT_PAID_PRO_FV.text'),
    name_for_customer: VueI18n.t('statics.orderStatuses.FOR_ADMIN_NOT_PAID_PRO_FV.nameForCustomer'),
    color: '#f50000',
  };
};

const FOR_CLIENT_PAID_PRO_FV = () => {
  return {
    id: 106173,
    name: 'Proforma dla klienta (opl)',
    text: VueI18n.t('statics.orderStatuses.FOR_CLIENT_PAID_PRO_FV.text'),
    name_for_customer: VueI18n.t('statics.orderStatuses.FOR_CLIENT_PAID_PRO_FV.nameForCustomer'),
    color: '#b34ff5',
  };
};

const FOR_ADMIN_PAID_PRO_FV = () => {
  return {
    id: 106174,
    name: 'Proforma dla admina (opl)',
    text: VueI18n.t('statics.orderStatuses.FOR_ADMIN_PAID_PRO_FV.text'),
    name_for_customer: VueI18n.t('statics.orderStatuses.FOR_ADMIN_PAID_PRO_FV.nameForCustomer'),
    color: '#f50000',
  };
};
const FOREIGN_ORDER_NEW = () => {
  return {
    id: 11660,
    name: 'ZAGRANICZNE - nowe',
    text: VueI18n.t('statics.orderStatuses.FOREIGN_ORDER_NEW.text'),
    name_for_customer: VueI18n.t('statics.orderStatuses.FOREIGN_ORDER_NEW.nameForCustomer'),
    listItemText: VueI18n.t('statics.orderStatuses.FOREIGN_ORDER_NEW.listItemText'),
    color: '#ff8f2b',
  };
};

const FOREIGN_ORDER_REALIZED = () => {
  return {
    id: 11661,
    name: 'ZAGRANICZNE - zrealizowane',
    text: VueI18n.t('statics.orderStatuses.FOREIGN_ORDER_REALIZED.text'),
    name_for_customer: VueI18n.t('statics.orderStatuses.FOREIGN_ORDER_REALIZED.nameForCustomer'),
    listItemText: VueI18n.t('statics.orderStatuses.FOREIGN_ORDER_REALIZED.listItemText'),
    color: '#009b00',
  };
};

const FOREIGN_ORDER_CANCELLED = () => {
  return {
    id: 11662,
    name: 'ZAGRANICZNE - anulowane',
    text: VueI18n.tc('statics.orderStatuses.FOREIGN_ORDER_CANCELLED.text'),
    name_for_customer: VueI18n.t('statics.orderStatuses.FOREIGN_ORDER_CANCELLED.nameForCustomer'),
    listItemText: VueI18n.t('statics.orderStatuses.FOREIGN_ORDER_CANCELLED.listItemText'),
    color: '#b40505',
  };
};

export const STATUSES = () => {
  return [
    NEW_ORDER(),
    PAID(),
    SENT(),
    CANCELLED(),
    EXAMPLE_STATUS,
    TO_IMPORT,
    IMPORTED,
    IMPORT_ERROR,
    FOR_CLIENT_NOT_PAID_PRO_FV(),
    FOR_ADMIN_NOT_PAID_PRO_FV,
    FOR_CLIENT_FV(),
    FOR_ADMIN_FV(),
    FOR_CLIENT_PAID_PRO_FV(),
    FOR_ADMIN_PAID_PRO_FV(),
    FOREIGN_ORDER_NEW(),
    FOREIGN_ORDER_REALIZED(),
    FOREIGN_ORDER_CANCELLED(),
  ];
};

export const ORDER_STATUSES = () => {
  return {
    NEW_ORDER: NEW_ORDER(),
    PAID: PAID(),
    SENT: SENT(),
    CANCELLED: CANCELLED(),
    TO_IMPORT,
    IMPORTED,
    IMPORT_ERROR,
    FOR_CLIENT_NOT_PAID_PRO_FV: FOR_CLIENT_NOT_PAID_PRO_FV(),
    FOR_ADMIN_NOT_PAID_PRO_FV,
    FOR_CLIENT_FV: FOR_CLIENT_FV(),
    FOR_ADMIN_FV: FOR_ADMIN_FV(),
    FOR_CLIENT_PAID_PRO_FV: FOR_CLIENT_PAID_PRO_FV(),
    FOR_ADMIN_PAID_PRO_FV: FOR_ADMIN_PAID_PRO_FV(),
  };
};

export const CLIENT_ORDER_STATUSES = () => {
  return [NEW_ORDER(), PAID(), SENT(), CANCELLED()];
};

export const FOREIGN_CLIENT_STATUSES = () => {
  return [FOREIGN_ORDER_NEW(), FOREIGN_ORDER_REALIZED(), FOREIGN_ORDER_CANCELLED()];
};

export const FILTER_STATUSES = () => {
  return [
    NEW_ORDER(),
    PAID(),
    SENT(),
    CANCELLED(),
    FOREIGN_ORDER_NEW(),
    FOREIGN_ORDER_REALIZED(),
    FOREIGN_ORDER_CANCELLED(),
  ];
};
