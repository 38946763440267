<template>
  <svg
    class="triangle"
    width="37"
    height="72"
    viewBox="0 0 37 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.3207 0.780182C33.5962 0.265226 34.9638 6.14726e-05 36.3449 0V35V70C34.9645 70.0022 33.5973 69.7393 32.3218 69.2265C31.0462 68.7136 29.8874 67.9608 28.9118 67.0114L3.40004 42.245C2.42411 41.2984 1.64979 40.1739 1.12149 38.936C0.593196 37.698 0.321289 36.3709 0.321289 35.0306C0.321289 33.6904 0.593196 32.3633 1.12149 31.1253C1.64979 29.8874 2.42411 28.7629 3.40004 27.8163L28.9118 3.00089C29.8867 2.04977 31.0452 1.29514 32.3207 0.780182Z"
      fill="#e7247b"
    />
  </svg>
</template>
<script>
export default {
  name: 'NavbarTriangleIcon',
};
</script>
