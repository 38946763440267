<template>
  <title-button-wrapper :to="goBack" :title="$t('components.catalogItem.title')">
    <v-container class="white pa-6" v-if="!loading">
      <product-description :product="product"></product-description>
      <download-product-specification
        :pdfSpecification="product.pdfSpecification"
      ></download-product-specification>
      <product-technical-data :attributes="product.attributes"></product-technical-data>
      <v-divider class="mt-4"></v-divider>
      <v-row class="mt-2">
        <product-stock-info :stock="product.stock" :supply="product.supply"></product-stock-info>
        <v-spacer></v-spacer>
        <v-col class="d-flex align-center justify-end">
          <span class="mr-4">
            {{ formattedPrice }}
            ({{ $t('helpers.net') }}) / {{ $t('helpers.piecesAmount') }}
          </span>
          <quantity-input
            v-if="isStockAvailable"
            :quantity="parseInt(quantity)"
            :supply="product.stock"
            :disabled="false"
            :inProductItem="false"
            :availableGroupPrices="availableGroupPrices"
            v-on:quantityChange="changeQuantity"
          ></quantity-input>
          <v-tooltip bottom :disabled="priceGroupAvailable">
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  large
                  :disabled="!isStockAvailable || !priceGroupAvailable"
                  color="primary"
                  fab
                  @click="addToCart"
                >
                  <v-icon> mdi-cart-plus</v-icon>
                </v-btn>
              </div>
            </template>
            <available-group-prices
              :availableGroupPrices="availableGroupPrices"
            ></available-group-prices>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else
      ><v-row> <v-progress-linear indeterminate></v-progress-linear></v-row
    ></v-container>
  </title-button-wrapper>
</template>
<script>
import QuantityInput from '../../components/QuantityInput.vue';
import TitleButtonWrapper from '../../components/TitleButtonWrapper.vue';
import ProductDescription from '../../components/ProductDescription.vue';
import ProductService from '../../services/ProductService';
import DownloadProductSpecification from '../../components/DownloadProductSpecification.vue';
import ProductTechnicalData from '../../components/ProductTechnicalData.vue';
import AvailableGroupPrices from '../../components/AvailableGroupPrices.vue';
import ProductStockInfo from '../../components/ProductStockInfo.vue';
import { groupPriceMixin } from '../../mixins/groupPriceMixin.js';
import { formatCurrency } from '../../mixins/formatCurrency';
import { categories } from '../../static/categoriesList.js';
import { selectedCurrencyComputed } from '../../store/helper';
export default {
  name: 'CatalogItem',
  mixins: [groupPriceMixin, formatCurrency],
  components: {
    QuantityInput,
    TitleButtonWrapper,
    ProductDescription,
    DownloadProductSpecification,
    ProductTechnicalData,
    ProductStockInfo,
    AvailableGroupPrices,
  },
  data() {
    return {
      product: {},
      quantity: 1,
      loading: true,
    };
  },
  methods: {
    goBack() {
      const {
        history: {
          current: { name },
        },
      } = this.$router;
      if (name === 'CatalogList') {
        return this.$router.go(-1);
      }
      if (this.product.category === categories.PRODUCTS_SET.value) {
        return this.$router.push({ name: 'Sets' });
      }
      return this.$router.push({ name: 'Catalog' });
    },
    changeQuantity(quantity) {
      this.quantity = quantity;
    },
    async fetchProduct() {
      try {
        const response = await ProductService.get(this.productId);
        this.product = response.data;
        this.loading = false;
      } catch (error) {
        console.error(error);
      }
    },

    addToCart() {
      if (this.quantity > 0) {
        if (this.canAddMoreToCart) {
          const cartProduct = {
            ...this.product,
            quantity: this.quantity,
          };
          this.$store.commit('addToCart', { product: cartProduct });
        } else {
          this.$toasted.global.error({
            message: this.$i18n.t('components.catalogItem.addToCartFunc.toastedOne'),
          });
        }
      } else {
        this.$toasted.global.error({
          message: this.$i18n.t('components.catalogItem.addToCartFunc.toastedOne'),
        });
      }
    },
  },
  computed: {
    ...selectedCurrencyComputed,
    isStockAvailable() {
      return this.product.stock > 0;
    },
    formattedPrice() {
      return this.isStockAvailable && this.priceGroupAvailable
        ? this.formatCurrency(this.productUnitPrice)
        : `- ${this.currencyLabel()}`;
    },
  },
  activated() {
    const productId = this.$route.params.productId;
    this.productId = productId;
    this.fetchProduct();
  },
};
</script>
