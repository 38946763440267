<template>
  <title-wrapper :title="$t('components.wizard.mountingAccessories.title')">
    <v-row>
      <v-col>
        <v-card class="py-4 px-6 text-center mb-0" elevation="0">
          {{ $t('components.wizard.mountingAccessories.info') }}<br /><br />
          <v-divider></v-divider>
        </v-card>
        <product-list
          :items="accessories"
          :isButtonAddVisible="false"
          @delete="deleteItem"
        ></product-list>
      </v-col>
    </v-row>
    <confirm-modal
      @accept="accept"
      :title="$t('components.wizard.mountingAccessories.modal.title')"
      :open="modalIncomplete"
      :agree="$t('components.wizard.mountingAccessories.modal.agree')"
      :showDisagree="false"
    >
      {{ $t('components.wizard.mountingAccessories.modal.text') }}
      <div v-if="missingProducts.length" class="mt-2">
        {{ $t('components.wizard.mountingAccessories.missingProducts') }}:
        <p v-for="(missingProduct, index) of missingProducts" :key="missingProduct.name">
          {{ index + 1 }}.{{ missingProduct.name }},
          {{ $t('components.wizard.mountingAccessories.missingAmount') }}:
          {{ missingProduct.missingQuantity }}
        </p>
      </div>
      <div v-if="missingSkus.length" class="mt-2">
        {{ $t('components.wizard.mountingAccessories.productNotInSystem') }}:
        <p v-for="(missingSku, index) of missingSkus" :key="missingSku">
          {{ index + 1 }}.{{ missingSku }}
        </p>
      </div>
    </confirm-modal>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../components/TitleWrapper.vue';
import WizardService from '../../services/WizardService';
import ProductList from '../../components/itemList/ProductList.vue';
import ConfirmModal from '../../components/modals/ConfirmModal.vue';
import {
  wizardOptionsComputed,
  missingSkusComputed,
  missingProductsComputed,
  savedAccessoriesComputed,
  accessoriesRefetchComputed,
} from '../../store/helper';

export default {
  name: 'MountingAccessories',
  components: { TitleWrapper, ProductList, ConfirmModal },
  data() {
    return {
      addedToCart: false,
      accessories: [],
      baseLength: 0,
      wizardStep: 5,
      modalIncomplete: false,
    };
  },
  computed: {
    ...wizardOptionsComputed,
    ...missingSkusComputed,
    ...missingProductsComputed,
    ...savedAccessoriesComputed,
    ...accessoriesRefetchComputed,
  },
  watch: {
    accessories: {
      handler() {
        this.$store.commit('addSavedAccessories', this.accessories);
      },
      deep: true,
    },
  },
  methods: {
    accept() {
      this.modalIncomplete = false;
    },
    incompleteAccessories() {
      this.modalIncomplete = true;
    },
    deleteItem(item) {
      this.accessories = this.accessories.filter((el) => el._id !== item._id);
      this.$store.commit('addToIncompatibleSteps', 5);
    },
    addAccessoriesToCart(accessories) {
      for (const accessory of accessories) {
        if (accessory.quantity > 0) {
          this.$store.commit('addAccessoryToCart', { product: accessory });
        }
      }
      this.$toasted.success(this.$i18n.t('components.wizard.mountingAccessories.toasted'));
    },
    deleteAccessoriesFromCart(accessories) {
      for (const accessory of accessories) {
        this.$store.commit('removeFromCart', accessory._id);
      }
    },
    resetAccessories() {
      this.deleteAccessoriesFromCart(this.accessories);
      this.addedToCart = false;

      this.accessories = [];
      this.$store.commit('resetAccessories');
    },
    async fetchAccessories() {
      this.resetAccessories();
      try {
        const wizardOptions = this.$store.state.wizardOptions;
        const response = await WizardService.getAccessories(wizardOptions);
        this.accessories = response.data.accessories;
        this.baseLength = this.accessories.length;
        this.$store.commit('addSavedAccessories', this.accessories);

        if (response.complete) {
          this.$store.commit('removeFromIncompatibleSteps', 5);
        } else {
          this.$store.commit('addToIncompatibleSteps', 5);
          this.$store.commit('setMissingSkus', response.missingSkus);
          this.$store.commit('setMissingProducts', response.missingProducts);
          this.incompleteAccessories();
        }

        this.addAccessoriesToCart(this.accessories);
      } catch (error) {
        console.error(error);
        this.$store.commit('addToIncompatibleSteps', 5);
      }
    },
    handleIncompatibility() {
      if (this.savedAccessories.some((acc) => acc.quantity < acc.wizardRecommends)) {
        this.$store.commit('addToIncompatibleSteps', this.wizardStep);
      } else {
        if (this.$store.state.missingSkus.length === 0) {
          this.$store.commit('removeFromIncompatibleSteps', this.wizardStep);
        }
      }
    },
  },
  activated() {
    this.$emit('isFowardEnabled', true);

    if (this.accessoriesRefetch) {
      this.fetchAccessories();
    } else {
      if (this.savedAccessories.length) {
        this.accessories = this.savedAccessories;
      } else {
        this.fetchAccessories();
      }
    }

    this.addedToCart = true;
    this.handleIncompatibility();
  },
};
</script>
<style lang="scss" scoped></style>
