<template>
  <div v-if="showTechnicalData()">
    <v-row>
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3 class="mb-4">{{ $t('components.productTechnicalData') }}</h3>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr v-for="attribute in attributes" :key="attribute.id">
                <div v-if="attribute.value">
                  <td>{{ getAttributeName(attribute) }}: &nbsp;</td>
                  <td>
                    {{ getAttributeValueTranslation(attribute)
                    }}<span v-if="attribute.unit"> [{{ attribute.unit }}]</span>
                  </td>
                </div>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { attributesNameMixin } from '../mixins/attributesNameMixin';

export default {
  name: 'ProductTechnicalData',
  mixins: [attributesNameMixin],
  props: {
    attributes: {
      type: Array,
    },
  },
  methods: {
    showTechnicalData() {
      return this.attributes.find((attr) => attr.value);
    },
  },
};
</script>
