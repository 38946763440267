<template>
  <router-link :to="to">
    <v-card
      :style="{ minHeight: panelMinHeight || panelSizeMobile }"
      class="d-flex flex-column white my-card align-center"
      :class="optionsRoute ? 'main-options' : 'categories'"
    >
      <v-icon :size="iconSize ? iconSize : iconSizeMobile" class="mb-8" color="primary">{{
        icon
      }}</v-icon>
      <h2 class="font-weight-bold text-center"><slot></slot></h2>
    </v-card>
  </router-link>
</template>
<script>
export default {
  name: 'ChoosePanel',
  props: ['icon', 'to', 'iconSize', 'panelMinHeight'],
  computed: {
    panelSizeMobile() {
      return this.$vuetify.breakpoint.sm ? '15rem' : '30rem';
    },
    iconSizeMobile() {
      return this.$vuetify.breakpoint.sm ? '75px' : '100px';
    },
  },
  methods: {
    optionsRoute() {
      return this.$route.name === 'Options';
    },
  },
};
</script>
<style lang="scss" scoped>
.my-card {
  transition: all 0.2s ease-out !important;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 11px 18px -2px rgba(0, 0, 0, 0.3) !important;
  }
}
.main-options {
  justify-content: flex-start;
  padding: 6rem 1rem 3rem 1rem;
  gap: 1rem;
}

.categories {
  justify-content: center;
  padding: 1rem;
}
</style>
