<template>
  <div class="py-4 item">
    <v-row>
      <v-col cols="12" sm="2" v-if="!onWizard" class="d-flex justify-center align-center">
        <router-link :to="detailsRoute()">
          <v-img
            v-if="product.image"
            :lazy-src="product.image"
            contain
            width="auto"
            :height="!isMobile ? '350px' : '200px'"
            class="rounded"
            :max-width="!isMobile ? '150px' : '300px'"
            :src="product.image"
          ></v-img>
          <v-img v-else height="150" width="auto">
            <v-row class="fill-height ma-0" justify="center">
              <v-icon size="80px">mdi-image</v-icon>
            </v-row>
          </v-img>
        </router-link>
      </v-col>
      <v-col cols="12" :sm="onWizard ? 12 : 10">
        <v-container class="ma-0 pa-0">
          <v-row>
            <v-col cols="12" :sm="isProductsSetCategory ? 4 : 6">
              <v-container>
                <v-row
                  ><v-col class="py-0">
                    <router-link
                      class="font-weight-bold black--text hover-primary"
                      :to="detailsRoute()"
                      >{{ getNameByLang(product) }}</router-link
                    >
                    <v-img
                      v-if="product.image && onWizard"
                      :lazy-src="product.image"
                      contain
                      position="left center"
                      :height="!isMobile ? '300px' : '200px'"
                      width="auto"
                      max-width="300px"
                      class="rounded my-2"
                      :src="product.image"
                    ></v-img> </v-col
                ></v-row>
                <v-row v-if="product.shortDescription"
                  ><v-col
                    ><p>{{ getShortDescriptionByLang(product) }}</p>
                  </v-col></v-row
                >
                <v-row v-if="product.attributes.length > 0"
                  ><v-col>
                    <tr v-if="product.producer">
                      <td>
                        {{ $t('components.catalogListItem.productProducer') }}:
                        {{ product.producer.name }}
                      </td>
                    </tr>
                    <tr v-for="attribute in mappedAttributes" :key="attribute.id">
                      <div v-if="checkIfFullblack(attribute)">
                        <td>{{ getAttributeName(attribute) }}: &nbsp;</td>
                        <td>
                          {{ getAttributeValueTranslation(attribute)
                          }}<span v-if="attribute.unit"> [{{ attribute.unit }}]</span>
                        </td>
                      </div>
                    </tr>
                  </v-col></v-row
                >
                <v-row>
                  <v-col>
                    <router-link class="showDetails" :to="detailsRoute()">{{
                      $t('components.catalogListItem.productShowDetails')
                    }}</router-link>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="12" sm="2" class="font-weight-bold text-center"
              >{{ formatCurrency(productUnitPrice) }}
              {{ $t('helpers.piecesAmount') }}
            </v-col>
            <v-col
              v-if="isProductsSetCategory"
              cols="12"
              sm="2"
              class="font-weight-bold text-center"
              >{{ getPowerAtrribute(product.attributes[0]) }}
            </v-col>
            <v-col cols="12" sm="2" class="text-center"
              ><quantity-input
                v-if="onWizard && isStockAvailable"
                :quantity="parseInt(quantity)"
                :supply="product.stock"
                ref="quantityInput"
                :availableGroupPrices="availableGroupPrices"
                :disabled="productInCart"
                :inProductItem="false"
                v-on:quantityChange="changeQuantity"
              ></quantity-input>
              <quantity-input
                v-else-if="isStockAvailable"
                :quantity="parseInt(quantity)"
                :availableGroupPrices="availableGroupPrices"
                :supply="product.stock"
                ref="quantityInput"
                :disabled="false"
                :inProductItem="false"
                v-on:quantityChange="changeQuantity"
              ></quantity-input
              ><span v-else>-</span><span v-if="isStockAvailable">{{ totalPower }}</span></v-col
            >
            <v-col cols="12" sm="2" class="text-right pa-0"
              ><v-container>
                <v-row class="text-center"
                  ><v-col class="font-weight-bold"
                    >{{ $t('helpers.sum') }}:
                    {{ isStockAvailable && priceGroupAvailable ? formatCurrency(totalPrice) : '-' }}
                  </v-col></v-row
                >
                <v-row class="text-center"
                  ><v-col>
                    <v-tooltip bottom :disabled="priceGroupAvailable">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" v-bind="attrs">
                          <v-btn
                            v-if="onWizard && isStockAvailable"
                            @click="productInCart ? removeFromCart() : handleCart()"
                            fab
                            dark
                            :disabled="(isDisabledAction && !productInCart) || !priceGroupAvailable"
                            large
                            :color="productInCart ? '' : 'primary'"
                          >
                            <v-icon dark>
                              {{ productInCart ? 'mdi-cart-remove' : 'mdi-cart-plus' }}
                            </v-icon>
                          </v-btn>

                          <v-btn
                            dark
                            v-else
                            v-on="on"
                            v-bind="attrs"
                            large
                            :disabled="!isStockAvailable || !priceGroupAvailable"
                            color="primary"
                            fab
                            @click="handleCart()"
                          >
                            <v-icon> mdi-cart-plus</v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <available-group-prices
                        :availableGroupPrices="availableGroupPrices"
                      ></available-group-prices>
                    </v-tooltip> </v-col
                ></v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="productInCart && onWizard">
              <span></span>
              <div v-if="!incompatibleProduct">
                <v-icon class="mr-2 mb-1" color="green">mdi-check</v-icon>
                <span class="green--text" v-if="isCompatibleWithPower">{{
                  $t('components.catalogListItem.infoCompatible.info1')
                }}</span>
                <span class="green--text" v-else>{{
                  $t('components.catalogListItem.infoCompatible.info2')
                }}</span>
              </div>
              <div v-else>
                <v-icon class="mr-2 mb-1" color="warning">mdi-alert</v-icon>
                <span class="warning--text" v-if="isCompatibleWithPower">{{
                  $t('components.catalogListItem.infoIncompatible.info1')
                }}</span>
                <span class="warning--text" v-else>{{
                  $t('components.catalogListItem.infoIncompatible.info2')
                }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col v-if="isStockAvailable" class="green--text">
              <v-icon class="mr-2 mb-1" color="green">mdi-check</v-icon
              >{{ $t('components.catalogListItem.product.amountAvailable') }}: {{ product.stock }}
            </v-col>
            <v-col v-else class="error--text">
              <v-icon class="mr-2 mb-1" color="error">mdi-cancel</v-icon
              >{{ $t('components.catalogListItem.product.notAvailable') }}
            </v-col>
            <v-col v-if="product.supply" class="text-right blue--text">
              <v-icon class="mr-2 mb-1" color="blue">mdi-calendar</v-icon
              >{{ $t('components.catalogListItem.product.delivery') }}: {{ product.supply }}</v-col
            >
          </v-row>
          <v-row class="my-0 py-0" v-if="productInCart && !onWizard">
            <v-col class="green--text text-right py-0 my-0">
              <v-icon class="mr-2 mb-1" color="green">mdi-check</v-icon>
              <span class="green--text">{{ $t('components.catalogListItem.addedToCart') }}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <confirm-modal
      @decline="decline"
      @accept="accept"
      :title="$t('components.catalogListItem.modal.storage.title')"
      :open="confirmModalOpen"
      :agree="$t('components.catalogListItem.modal.storage.agree')"
    >
      {{ $t('components.catalogListItem.modal.storage.storageAmountWarning.partOne') }}:
      <strong>{{ this.productDemand }}</strong
      >. {{ $t('components.catalogListItem.modal.storage.storageAmountWarning.partTwo') }}
    </confirm-modal>
    <confirm-modal
      @decline="declineQuantityChange"
      @accept="acceptQuantityChange"
      :title="$t('components.catalogListItem.modal.productAmount.title')"
      :open="quantityChangedModal"
      :agree="$t('components.catalogListItem.modal.productAmount.agree')"
    >
      {{ $t('components.catalogListItem.modal.productAmount.suggestedAmountChangedWarning') }}
    </confirm-modal>
    <confirm-modal
      @decline="declineDcCabelChange"
      @accept="acceptDcCabelChange"
      :title="$t('components.catalogListItem.modal.dcCable.title')"
      :open="dcCabelModal"
      :agree="$t('components.catalogListItem.modal.dcCable.agree')"
    >
      {{ $t('components.catalogListItem.modal.dcCable.text') }}
    </confirm-modal>

    <v-divider class="mt-3"></v-divider>
  </div>
</template>
<script>
import QuantityInput from './QuantityInput.vue';
import ConfirmModal from './modals/ConfirmModal.vue';
import AvailableGroupPrices from './AvailableGroupPrices.vue';
import {
  declaredPowerComputed,
  wizardStepComputed,
  wizardSubstepComputed,
  incompatibleStepsComputed,
  incompatibleSubStepsComputed,
} from '../store/helper.js';
import {
  categories,
  electricalInstallationEquipmentSubcategories,
} from '../static/categoriesList.js';
import { attributes } from '../static/attributes.js';
import { ceil, round } from 'lodash';
import { groupPriceMixin } from '../mixins/groupPriceMixin.js';
import { formatCurrency } from '../mixins/formatCurrency';
import { attributesNameMixin } from '../mixins/attributesNameMixin';
import { getNameByLanguage } from '../mixins/getNameByLanguage';
import VueI18n from '../plugins/i18n';

export default {
  name: 'CatalogListItem',
  components: {
    QuantityInput,
    ConfirmModal,
    AvailableGroupPrices,
  },
  mixins: [groupPriceMixin, formatCurrency, attributesNameMixin, getNameByLanguage],
  props: {
    isDisabledAction: { type: Boolean, default: false },
    product: { type: Object },
    onWizard: { type: Boolean, default: false },
    addAction: { type: String },
    removeAction: { type: String },
    isCompatibleWithPower: { type: Boolean, default: false },
  },
  data() {
    return {
      quantity: 0,
      productDemand: 0,
      confirmModalOpen: false,
      quantityChangedModal: false,
      quantityChanged: false,
      dcCabelModal: false,
      incompatibleProduct: false,
    };
  },
  methods: {
    getPowerAtrribute(attr) {
      return `${attr.value} ${attr.unit}`;
    },
    checkIfFullblack(attribute) {
      if (attribute.name === 'Czy fullblack' && attribute.value === 'Nie') return false;
      return attribute.value;
    },
    decline() {
      this.confirmModalOpen = false;
    },
    detailsComponentName() {
      return this.onWizard ? 'WizardItemDetails' : 'CatalogItem';
    },
    detailsRoute() {
      return { name: this.detailsComponentName(), params: { productId: this.product._id } };
    },
    accept() {
      this.confirmModalOpen = false;
      this.incompatibleProduct = true;
      this.$store.commit('addToIncompatibleSteps');
      this.addToCart();
    },
    declineDcCabelChange() {
      this.dcCabelModal = false;
    },
    acceptDcCabelChange() {
      this.dcCabelModal = false;
      this.incompatibleProduct = true;
      this.$store.commit('addToIncompatibleSteps');
      this.addToCart();
    },
    acceptQuantityChange() {
      this.quantityChangedModal = false;
      this.incompatibleProduct = true;
      this.addToCart();
      this.updateIncompatibleSteps();
    },
    declineQuantityChange() {
      this.quantity = this.productDemand;
      this.$refs.quantityInput.setQuantity(this.quantity);
      this.quantityChangedModal = false;
    },
    changeQuantity(quantity) {
      this.quantityChanged = quantity !== this.productDemand;
      this.quantity = quantity;
    },
    updateIncompatibleSteps() {
      if (this.wizardStep !== 4) {
        this.$store.commit('addToIncompatibleSteps', this.wizardStep);
        this.incompatibleProduct = true;
      } else {
        this.$store.commit('addToIncompatibleSubSteps', this.wizardSubStep);
      }
    },
    handleCart() {
      if (this.onWizard) {
        if (this.product.category === categories.CABEL_DC.value) {
          const crossSection = parseFloat(
            this.product.attributes.find((attr) => attr.name === attributes.CROSS_SECTION)?.value
          );
          if (
            (this.declaredPower > 5 && crossSection !== 6) ||
            (this.declaredPower < 5 && crossSection !== 4)
          ) {
            return (this.dcCabelModal = true);
          }
        }
        if (
          !Object.values(electricalInstallationEquipmentSubcategories).some(
            (item) => item.value === this.product.category
          )
        ) {
          if (this.productDemand > this.product.stock) {
            return (this.confirmModalOpen = true);
          }
          if (this.quantity === 0) {
            return this.$toasted.global.error({
              message: VueI18n.t('components.catalogListItem.handleCartFunc.toasted'),
            });
          }
          if (this.productDemand < this.product.stock && this.quantityChanged) {
            return (this.quantityChangedModal = true);
          }
        }
      }
      this.addToCart();
    },
    addToCart() {
      if (this.quantity > 0) {
        if (this.canAddMoreToCart) {
          const cartProduct = {
            ...this.product,
            quantity: this.quantity,
            incompatible: this.incompatibleProduct,
          };
          this.$store.commit('addToCart', { product: cartProduct });
          if (this.addAction) {
            this.$store.commit(this.addAction, cartProduct);
          }
        } else {
          this.$toasted.global.error({
            message: VueI18n.t('components.catalogListItem.addToCartFunc.toasted1'),
          });
        }
      } else {
        this.$toasted.global.error({
          message: VueI18n.t('components.catalogListItem.addToCartFunc.toasted1'),
        });
      }
    },
    removeFromCart() {
      this.$store.commit('removeFromCart', this.product._id);
      if (this.removeAction) {
        this.$store.commit(this.removeAction, this.product._id);
        if (
          this.wizardStep !== 4 &&
          this.incompatibleSteps.includes(this.wizardStep) &&
          this.incompatibleProduct
        ) {
          this.$store.commit('removeFromIncompatibleSteps', this.wizardStep);
          this.incompatibleProduct = false;
        } else if (
          this.wizardStep === 4 &&
          this.incompatibleSubSteps.includes(this.wizardSubstep)
        ) {
          this.$store.commit('removeFromIncompatibleSubSteps', this.wizardSubStep);
        }
      }
      this.$toasted.global.success({
        message: `${VueI18n.t('components.catalogListItem.removeFromCartFunc.toastedPartOne')} ${
          this.product.name
        } ${VueI18n.t('components.catalogListItem.removeFromCartFunc.toastedPartTwo')}`,
      });
    },
    setupProductFromCart() {
      if (this.onWizard) {
        const productInCart = this.cart.find((product) => product._id === this.product._id);
        if (productInCart) {
          this.quantity = productInCart.quantity;
          if (this.productDemand > 0 && this.productDemand !== productInCart.quantity) {
            this.incompatibleProduct = true;
          }
        }
      }
    },
    setCountOfProduct() {
      const allowedCategoryList = [
        categories.PHOTO_MODULES.value,
        categories.INVERTERS.value,
        categories.OPTIMIZERS.value,
      ];
      if (
        this.onWizard &&
        !!this.declaredPower &&
        allowedCategoryList.includes(this.product.category)
      ) {
        const productPower = parseFloat(
          this.product.attributes.find((attr) => attr.name === attributes.POWER).value
        );
        if (productPower) {
          const flooredDeclaredPower = this.declaredPower * 1000;
          this.productDemand = ceil(flooredDeclaredPower / productPower);
          this.quantity =
            this.productDemand > this.product.stock ? this.product.stock : this.productDemand;
        }
      } else {
        this.quantity = 1;
      }
    },
    findAttribute(name) {
      const power = this.product.attributes.find((attr) => attr.name === name);
      if (power) {
        return power.value;
      } else {
        return null;
      }
    },
  },
  activated() {
    this.setCountOfProduct();
    this.setupProductFromCart();
    this.$refs.quantityInput.setQuantity(this.quantity);
  },
  created() {
    this.setCountOfProduct();
    this.setupProductFromCart();
  },
  computed: {
    ...declaredPowerComputed,
    ...wizardStepComputed,
    ...wizardSubstepComputed,
    ...incompatibleStepsComputed,
    ...incompatibleSubStepsComputed,
    isStockAvailable() {
      return this.product.stock > 0;
    },
    totalPower() {
      const powerValue = this.findAttribute(attributes.POWER);
      const totalPow = round((this.quantity * powerValue) / 1000, 2);

      return powerValue ? `${totalPow.toFixed(2)} kW` : '';
    },
    productInCart() {
      return !this.cart.length ? false : !!this.cart.find((pr) => pr._id === this.product._id);
    },
    mappedAttributes() {
      if (this.product.category === categories.PHOTO_MODULES.value) {
        let strippedAttributes = this.product.attributes;
        const width = this.product.attributes.find((attr) => attr.name === 'Szerokość');
        const height = this.product.attributes.find((attr) => attr.name === 'Wysokość');
        const length = this.product.attributes.find((attr) => attr.name === 'Długość');

        strippedAttributes = this.product.attributes.filter(
          (attr) => attr.name !== 'Szerokość' && attr.name !== 'Wysokość' && attr.name !== 'Długość'
        );

        const attributes = [
          {
            name: 'Wymiary',
            value: `${width.value} x ${height.value} x ${length.value}`,
            unit: 'mm',
          },
          ...strippedAttributes,
        ];

        return attributes;
      } else {
        return this.product.attributes;
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    isProductsSetCategory() {
      return this.product.category === categories.PRODUCTS_SET.value;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../scss/variables.scss';

.showDetails {
  color: $font;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;
  &:hover {
    color: $primary;
  }
}

.v-btn--round {
  border-radius: 4px;
}

.hover-primary {
  transition: all 0.2 ease-in;

  &:hover {
    color: $primary !important;
  }
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  opacity: 0.8;
  background-color: $primary !important;
}
</style>
