<template>
  <title-wrapper
    :title="`${$t('components.wizard.rowsNumber.title')} - ${$t(
      `statics.panelOrientations.${panelOrientation}`
    )} `"
  >
    <v-form v-model="isValid" ref="rowsNumberForm">
      <div v-if="panelOrientation !== panelOrientations.POZIOM">
        <h2 class="text mb-3">{{ $t('components.wizard.rowsNumber.rowsAmountUpright') }}</h2>
        <v-text-field
          name="verticalRowsNumber"
          :label="$t('components.wizard.rowsNumber.rowsAmountUpright')"
          type="number"
          min="1"
          max="30"
          :rules="validators.rowsNumberRules"
          v-model="verticalRowsNumber"
          solo
          elavation="0"
        >
        </v-text-field>
      </div>
      <div v-if="panelOrientation !== panelOrientations.PION">
        <h2 class="text mb-3">{{ $t('components.wizard.rowsNumber.rowsAmountHorizontal') }}</h2>
        <v-text-field
          name="horizontalRowsNumber"
          :label="$t('components.wizard.rowsNumber.rowsAmountHorizontal')"
          type="number"
          min="1"
          max="30"
          :rules="validators.rowsNumberRules"
          v-model="horizontalRowsNumber"
          solo
          elavation="0"
        >
        </v-text-field>
      </div>
    </v-form>
    <div class="white pa-6">
      <v-img
        v-if="$vuetify.breakpoint.sm"
        max-width="280"
        contain
        class="mx-auto"
        height="200"
        :src="orentiationImage"
      ></v-img>
      <v-img
        v-else
        max-width="400"
        contain
        class="mx-auto"
        height="350"
        :src="orentiationImage"
      ></v-img>
    </div>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../components/TitleWrapper.vue';
import {
  verticalRowNumberComputed,
  horizontalRowNumberComputed,
  panelOrientationComputed,
} from '../../../store/helper.js';
import { validators, isValidRowNumber } from '../../../validators/Validators';
import { PANEL_ORIENTATION } from '../../../static/fotoConstants.js';
import orientacjaPionowo from '@/assets/wizzardImages/orientacjaPionowo.png';
import orientacjaPoziomo from '@/assets/wizzardImages/orientacjaPoziomo.png';
import orientacjaMieszane from '@/assets/wizzardImages/orientacjaMieszane.png';

export default {
  name: 'RowsNumber',
  components: { TitleWrapper },
  data() {
    return {
      isValid: false,
      rowsNumber: 0,
      verticalRowsNumber: 0,
      horizontalRowsNumber: 0,
      storeActionVertical: 'setVerticalRowNumber',
      storeActionHorizontal: 'setHorizontalRowNumber',
      panelOrientations: PANEL_ORIENTATION,
      orentiationImage: '',
      type: {
        name: 'RowNumber',
        isRoofConstruction: true,
      },
      orientationTypes: [
        {
          name: PANEL_ORIENTATION.PION,
          imgPath: orientacjaPionowo,
        },
        {
          name: PANEL_ORIENTATION.POZIOM,
          imgPath: orientacjaPoziomo,
        },
        {
          name: PANEL_ORIENTATION.MIESZANE,
          imgPath: orientacjaMieszane,
        },
      ],
    };
  },
  computed: {
    ...verticalRowNumberComputed,
    ...horizontalRowNumberComputed,
    ...panelOrientationComputed,
    validators() {
      return validators;
    },
  },

  methods: {
    validate() {
      this.$refs.rowsNumberForm.validate();
    },
    setupOrientation() {
      this.orentiationImage = this.orientationTypes.find(
        (or) => or.name === this.panelOrientation
      ).imgPath;
    },
    setRowsNumber() {
      if (
        (this.panelOrientation === PANEL_ORIENTATION.PION && this.verticalRowNumber > 0) ||
        (this.panelOrientation === PANEL_ORIENTATION.POZIOM && this.horizontalRowNumber > 0) ||
        (this.panelOrientation === PANEL_ORIENTATION.MIESZANE &&
          this.verticalRowNumber > 0 &&
          this.horizontalRowNumber > 0)
      ) {
        this.$emit('isFowardEnabled', true);
      } else {
        this.$emit('isFowardEnabled', false);
      }
      this.verticalRowsNumber = this.verticalRowNumber ? this.verticalRowNumber : 0;
      this.horizontalRowsNumber = this.horizontalRowNumber ? this.horizontalRowNumber : 0;
    },
  },
  watch: {
    verticalRowsNumber: function (val) {
      if (isValidRowNumber(parseFloat(val))) {
        this.$store.commit(this.storeActionVertical, parseFloat(val));
      }
    },
    horizontalRowsNumber: function (val) {
      if (isValidRowNumber(parseFloat(val))) {
        this.$store.commit(this.storeActionHorizontal, parseFloat(val));
      }
    },
    isValid: function (val) {
      this.$emit('isFowardEnabled', val);
    },
  },
  created() {
    this.setupOrientation();
    this.setRowsNumber();
  },
  activated() {
    this.setupOrientation();
    this.setRowsNumber();
  },
};
</script>
<style lang="scss" scoped></style>
