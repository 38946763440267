<template>
  <title-wrapper :title="$t('components.manageTraders.title')">
    <my-data-table
      :data="companies"
      :headers="headers"
      :loading="loading"
      :length="length"
      v-bind:page.sync="params.page"
      :sortBy="sortBy"
      :sortDesc="sortDesc"
      @update:sort-by="sortByFunc"
      @update:sort-desc="sortDescFunc"
    >
      <template v-slot:filters>
        <v-col cols="12" sm="3">
          <v-text-field
            class="stretch-height"
            v-model="params.firstName"
            hide-details
            :placeholder="$t('helpers.firstName')"
            solo
          ></v-text-field
        ></v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            class="stretch-height"
            v-model="params.lastName"
            hide-details
            :placeholder="$t('helpers.lastName')"
            solo
          ></v-text-field
        ></v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            class="stretch-height"
            v-model="params.salesmanCode"
            hide-details
            :placeholder="$t('helpers.code')"
            solo
          ></v-text-field
        ></v-col>
        <v-col cols="6" sm="1">
          <v-btn color="primary" elevation="0" fab dark block @click="onSearch">
            <v-icon>mdi-magnify</v-icon>
          </v-btn></v-col
        >
        <v-col cols="6" sm="1">
          <v-btn color="primary" elevation="0" fab dark block @click="clearParams">
            <v-icon>mdi-cancel</v-icon>
          </v-btn></v-col
        >
      </template>

      <template v-slot:actions="slotProps">
        <router-link :to="{ name: 'SingleTrader', params: { traderId: slotProps.item._id } }">
          <v-list-item class="hover-item-popup">
            <v-list-item-content>
              {{ $t('components.manageTraders.showDetails') }}</v-list-item-content
            >
          </v-list-item>
        </router-link>
        <v-list-item class="hover-item-popup" @click="() => deleteTrader(slotProps.item._id)">
          <v-list-item-content>
            {{ $t('components.manageTraders.deleteTrader') }}</v-list-item-content
          >
        </v-list-item>
      </template>

      <template v-slot:below>
        <v-row
          ><v-col><v-divider></v-divider></v-col
        ></v-row>
        <v-row
          ><v-col>
            <primary-button :onClick="openModal"
              ><v-icon class="mr-4">mdi-plus-circle-outline</v-icon
              >{{ $t('components.manageTraders.addTrader') }}</primary-button
            >
          </v-col></v-row
        >
      </template>
    </my-data-table>
    <change-address-modal
      :open="modal"
      :title="$t('components.manageTraders.modal.title')"
      :agree="$t('components.manageTraders.modal.agree')"
      :disagree="$t('components.manageTraders.modal.disagree')"
      v-on:decline="decline"
      v-on:accept="accept"
      :loading="loadingSaveButton"
    >
      <v-form v-model="isFormValid" ref="form" class="mt-4">
        <v-row>
          <v-col class="py-0 py-sm-2" col="4">
            <h2 class="text font-weight-bold text-color">
              {{ $t('helpers.firstName') }}
            </h2>
            <v-text-field
              v-model="newTrader.firstName"
              :rules="validator.requiredRules"
              outlined
              solo
              dense
              hide-details="auto"
              elevation="0"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0 py-sm-2" col="8">
            <h2 class="text font-weight-bold text-color">
              {{ $t('helpers.lastName') }}
            </h2>
            <v-text-field
              v-model="newTrader.lastName"
              :rules="validator.requiredRules"
              outlined
              solo
              dense
              hide-details="auto"
              elevation="0"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0 py-sm-2" col="12">
            <h2 class="text font-weight-bold text-color">{{ $t('helpers.email') }}</h2>
            <v-text-field
              v-model="newTrader.email"
              :rules="validator.emailRules"
              outlined
              hide-details="auto"
              solo
              dense
              elevation="0"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0 py-sm-2" col="12">
            <h2 class="text font-weight-bold text-color">
              {{ $t('helpers.phoneNumber') }}
            </h2>
            <v-text-field
              v-model="newTrader.phone"
              :rules="validator.phoneRules"
              outlined
              solo
              dense
              hide-details="auto"
              elevation="0"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0 py-sm-2" col="12">
            <h2 class="text font-weight-bold text-color">{{ $t('helpers.code') }}</h2>
            <v-text-field
              v-model="newTrader.salesmanCode"
              :rules="validator.requiredRules"
              outlined
              hide-details="auto"
              dense
              solo
              elevation="0"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0 py-sm-2" col="12">
            <h2 class="text font-weight-bold text-color">{{ $t('helpers.discountPercentage') }}</h2>
            <v-text-field
              v-model="newTrader.discountPercentage"
              :rules="validator.discountRules"
              outlined
              hide-details="auto"
              suffix="%"
              dense
              solo
              elevation="0"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </change-address-modal>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../components/TitleWrapper.vue';
import MyDataTable from '../../../components/MyDataTable.vue';
import SalesmanService from '../../../services/SalesmanService.js';
import PrimaryButton from '../../../components/buttons/PrimaryButton.vue';
import ChangeAddressModal from '../../../components/modals/ChangeAddressModal.vue';
import { validators } from '../../../validators/Validators';
import { sortHandler } from '../../../mixins/sortHandler';
import { formMixin } from '../../../mixins/formMixin';
import { supportedCurrencies } from '../../../static/supportedCurrencies';

export default {
  name: 'ManageTraders',
  components: { TitleWrapper, MyDataTable, PrimaryButton, ChangeAddressModal },
  mixins: [sortHandler, formMixin],
  data() {
    return {
      modal: false,
      isFormValid: false,
      loadingSaveButton: false,
      count: 0,
      itemsPerPage: 6,
      length: 0,
      companies: [],
      loading: true,
      newTrader: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        sales: '',
        salesmanCode: '',
        discountPercentage: '',
      },
      params: {
        firstName: '',
        lastName: '',
        salesmanCode: '',
        page: 1,
        limit: 6,
        sort: '',
      },
    };
  },
  watch: {
    'params.page': function () {
      this.getFiltered();
    },
  },
  methods: {
    async accept() {
      try {
        this.loadingEditButton = true;
        const traderToCreate = {
          ...this.newTrader,
          discountPercentage: this.newTrader.discountPercentage / 100,
        };
        const response = await SalesmanService.create(traderToCreate);
        this.trader = response.data;
        this.getFiltered();
        this.modal = false;
        this.$refs.form.reset();
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingEditButton = false;
      }
    },
    decline() {
      this.modal = false;
    },
    openModal() {
      this.modal = true;
    },
    clearParams() {
      this.params = {
        firstName: '',
        lastName: '',
        sort: '',
        salesmanCode: '',
        page: 1,
        limit: 6,
      };
      this.getFiltered();
    },
    async deleteTrader(id) {
      try {
        await SalesmanService.delete(id);
        this.$toasted.success(this.$t('components.deleteAccount.toasted.success'));
        this.getFiltered();
      } catch (error) {
        this.$toasted.error(this.$t('components.deleteAccount.toasted.error'));
      }
    },
    onSearch() {
      this.params.page = 1;
      this.getFiltered();
    },
    async getFiltered() {
      try {
        const response = await SalesmanService.getAllTraders(this.params);
        this.companies = response.data;
        this.count = response.count;
        this.length = Math.ceil(response.count / this.itemsPerPage);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    fetchSort() {
      this.getFiltered();
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t('helpers.fullName'),
          align: 'start',
          value: 'fullName',
          sortable: false,
        },
        {
          text: this.$i18n.t('components.manageTraders.table.registrationCode'),
          value: 'salesmanCode',
        },
        {
          text: this.$i18n.t('components.manageTraders.table.registeredCompanies'),
          value: 'companiesCount',
        },
        {
          text: this.$i18n.t('components.manageTraders.table.sumOfOrders', {
            currency: supportedCurrencies.PLN.label,
          }),
          value: 'convertedFullProfit',
        },
        { text: '', value: 'actions', sortable: false },
      ];
    },

    validator() {
      return validators;
    },
  },
  created() {
    this.getFiltered();
  },
};
</script>
<style lang="scss" scoped>
.v-btn--round {
  border-radius: 4px;
}
</style>
