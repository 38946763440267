<template>
  <v-container class="white pa-6">
    <v-form ref="form" v-model="isFormValid" @submit.prevent="register">
      <v-container>
        <v-row>
          <v-col class="py-0" cols="12" md="6">
            <h2 class="text mb-2">{{ $t('helpers.firstName') }}</h2>
            <v-text-field
              v-model="firstName"
              :label="$t('helpers.firstName')"
              :rules="validators.requiredRules"
              outlined
              solo
              elavation="0"
            ></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="6">
            <h2 class="text mb-2">{{ $t('helpers.lastName') }}</h2>
            <v-text-field
              v-model="lastName"
              :label="$t('helpers.lastName')"
              :rules="validators.requiredRules"
              outlined
              solo
              elavation="0"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0" cols="12">
            <h2 class="text mb-2">{{ $t('helpers.address') }} {{ $t('helpers.email') }}</h2>
            <v-text-field
              v-model="email"
              :label="`${$t('helpers.address')} ${$t('helpers.email')}`"
              :rules="validators.emailRules"
              outlined
              solo
              elavation="0"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0" cols="12" md="6">
            <h2 class="text mb-2">{{ $t('helpers.password') }}</h2>

            <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              :type="showPassword ? 'text' : 'password'"
              :label="$t('helpers.password')"
              :rules="[
                ...validators.passwordRules,
                (value) => validators.samePasswordRule(value, password),
              ]"
              outlined
              solo
              elavation="0"
            ></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="6">
            <h2 class="text mb-2">{{ $t('helpers.repeatPassword') }}</h2>

            <v-text-field
              :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showConfirmPassword = !showConfirmPassword"
              :type="showConfirmPassword ? 'text' : 'password'"
              v-model="confirmPassword"
              :label="$t('helpers.repeatPassword')"
              :rules="[
                ...validators.passwordRules,
                (value) => validators.samePasswordRule(value, password),
              ]"
              outlined
              solo
              elavation="0"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0" cols="12">
            <h2 class="text mb-2">{{ $t('helpers.country') }}</h2>
            <v-autocomplete
              solo
              outlined
              v-model="countryCode"
              :items="mappedCountries"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0" cols="12" md="8">
            <h2 class="text mb-2">{{ $t('helpers.registerForm') }}</h2>

            <v-text-field
              v-model="nip"
              :rules="foreignCountry ? validators.foreignNipRules : validators.nipRules"
              :label="$t('helpers.registerForm')"
              persistent-hint
              outlined
              solo
              elavation="0"
            ></v-text-field>
          </v-col>
          <v-col class="py-0 my-auto" cols="12" md="4">
            <primary-button
              :color="foreignCountry ? 'grey lighten-2' : 'primary'"
              class="mb-4 mb-md-0"
              :onClick="loadCompanyDataByNip"
              :disabled="!validNip || foreignCountry"
              :loading="nipLoading"
              >{{ $t('components.registerForm.loadCompanyData') }}</primary-button
            >
          </v-col>
        </v-row>
        <v-row class="mb-7">
          <v-col class="py-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0" cols="12">
            <h2 class="text mb-2">{{ $t('components.registerForm.companyName') }}</h2>

            <v-text-field
              v-model="companyName"
              :rules="validators.requiredRules"
              :label="$t('helpers.companyName')"
              outlined
              solo
              elavation="0"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0" cols="12">
            <h2 class="text mb-2">{{ $t('components.registerForm.companyAddress') }}</h2>

            <v-text-field
              v-model="address"
              :rules="validators.requiredRules"
              :label="$t('components.registerForm.companyAddress')"
              outlined
              solo
              elavation="0"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0" cols="12" md="6">
            <h2 class="text mb-2">{{ $t('helpers.zipCode') }}</h2>

            <v-text-field
              v-model="zipCode"
              :rules="foreignCountry ? [] : validators.zipCodeRules"
              :label="$t('helpers.zipCode')"
              outlined
              solo
              elavation="0"
            ></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="6">
            <h2 class="text mb-2">{{ $t('helpers.city') }}</h2>

            <v-text-field
              v-model="city"
              :rules="validators.requiredRules"
              :label="$t('helpers.city')"
              outlined
              solo
              elavation="0"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0" cols="12" md="6">
            <h2 class="text mb-2">{{ $t('components.registerForm.referralCode') }}</h2>

            <v-text-field
              v-model="referralCode"
              :label="$t('components.registerForm.referralCode')"
              outlined
              solo
              elavation="0"
            ></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="6">
            <h2 class="text mb-2">{{ $t('helpers.phoneNumber') }}</h2>

            <v-text-field
              v-model="phone"
              :label="$t('helpers.phoneNumber')"
              :rules="validators.phoneRules"
              outlined
              solo
              elavation="0"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0" cols="12">
            <v-row align="start" class="pa-3 flex-nowrap">
              <v-checkbox
                v-model="privacyPolicyAgreement"
                hide-details
                :rules="validators.checkboxRequiredRules"
                class="pa-0 mt-3"
              >
                <template slot="label">
                  <div class="mr-3">{{ $t('components.registerForm.privacyPolicy') }} *</div>
                </template>
              </v-checkbox>

              <a class="my-3 linkTo" href="/regulamin.html" target="_blank">
                <v-icon>mdi-file</v-icon>- {{ $t('components.registerForm.siteRules') }}</a
              >
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mt-4">
            <processing-personal-data-link
              customClass="text--secondary"
            ></processing-personal-data-link>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-4">
            <primary-button color="primary" class="mt-3" :loading="loading" type="submit">{{
              $t('helpers.register')
            }}</primary-button>
          </v-col>
        </v-row>
        <v-row
          ><v-col>
            <p>
              {{ $t('components.registerForm.haveAccount') }}
              <router-link :to="{ name: 'Login' }" class="font-weight-bold">{{
                $t('helpers.logIn')
              }}</router-link>
            </p>
            <p class="text--disabled text-caption mb-0 mt-3 pl-2">
              * - {{ $t('helpers.required') }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>
<script>
import { validators, isValidNip } from '../validators/Validators';
import AuthService from '../services/AuthService';
import NipService from '../services/NipService';
import PrimaryButton from './buttons/PrimaryButton.vue';
import ProcessingPersonalDataLink from '../components/ProcessingPersonalDataLink.vue';
import { countriesCodeHandler } from '../mixins/countriesCodeHandler';
import { formMixin } from '../mixins/formMixin';

export default {
  name: 'RegisterForm',
  components: { PrimaryButton, ProcessingPersonalDataLink },
  mixins: [countriesCodeHandler, formMixin],
  data() {
    return {
      isFormValid: false,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      nip: '',
      companyName: '',
      address: '',
      zipCode: '',
      city: '',
      referralCode: '',
      phone: '',
      privacyPolicyAgreement: false,
      regulationsAgreement: false,
      loading: false,
      nipLoading: false,
      validNip: false,
      showAlert: false,
      lang: this.$i18n.locale,
    };
  },
  methods: {
    async register() {
      this.$refs.form.validate();
      if (this.isFormValid) {
        try {
          this.loading = true;
          await AuthService.register(
            this.firstName,
            this.lastName,
            this.email,
            this.password,
            this.nip,
            this.companyName,
            this.address,
            this.zipCode,
            this.city,
            this.phone,
            this.referralCode,
            this.countryCode,
            this.lang
          );
          this.track();
          this.$toasted.global.success({
            message: this.$i18n.t('components.registerForm.toasted.registerFunc'),
          });
          this.$router.push({ name: 'RegistrationSuccessful' });
          this.showAlert = true;
        } catch (err) {
          console.error(err);
        } finally {
          this.loading = false;
        }
      }
    },
    track() {
      this.$gtag.event('conversion', {
        send_to: ['AW-10840634448/jJptCMqst7sDENDom7Eo'],
        lang: this.lang,
      });
    },
    async loadCompanyDataByNip() {
      try {
        this.nipLoading = true;
        const companyData = await NipService.getDataByNip(this.nip);
        this.companyName = companyData.name;
        this.address = companyData.street;
        this.zipCode = companyData.zipCode;
        this.city = companyData.city;
        this.$toasted.global.success({
          message: this.$i18n.t('components.registerForm.toasted.loadCompanyDataByNipFunc'),
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.nipLoading = false;
      }
    },
  },
  computed: {
    validators() {
      return validators;
    },
  },
  watch: {
    nip: function (val) {
      this.validNip = isValidNip(val);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../scss/variables.scss';

.linkTo {
  cursor: pointer;
  color: $primary;
}
</style>
