<template>
  <title-wrapper :title="$t('components.wizard.handleType.title')">
    <clickable-section
      v-if="handleTypes[pourType]"
      :items="handleTypes[pourType]"
      @updateSelected="updateSelected"
      :active="selectedHandleType"
      cols="6"
    ></clickable-section>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../components/TitleWrapper.vue';
import ClickableSection from '../../../components/ClickableSection.vue';
import { handleTypeComputed, pourTypeComputed } from '../../../store/helper.js';
import { HANDLE_TYPE } from '../../../static/fotoConstants.js';
import uchwytTapezRegulowany from '@/assets/wizzardImages/uchwytTrapezRegulowany.png';
import uchwytZBlachy from '@/assets/wizzardImages/uchwytZBlachy.png';
import VueI18n from '../../../plugins/i18n';

export default {
  name: 'HandleType',
  components: { TitleWrapper, ClickableSection },
  data() {
    return {
      selectedHandleType: '',
      isValid: false,
      storeAction: 'setHandleType',
    };
  },
  methods: {
    updateSelected(handleType) {
      this.selectedHandleType = handleType;
      this.$store.commit(this.storeAction, this.selectedHandleType);
      this.isValid = true;
      this.$emit('isFowardEnabled', true);
    },
  },
  computed: {
    handleTypes() {
      const handleTypesList = {
        'Blacha trapezowa': [
          {
            value: HANDLE_TYPE.UCHWYT_TRAPEZOWY,
            text: VueI18n.t('statics.handleType.UCHWYT_TRAPEZOWY'),
            imgPath: uchwytTapezRegulowany,
          },
          {
            value: HANDLE_TYPE.MOSTEK_TRAPEZOWY_330,
            text: VueI18n.t('statics.handleType.MOSTEK_TRAPEZOWY_330'),
            imgPath: uchwytTapezRegulowany,
          },
          {
            value: HANDLE_TYPE.MOSTEK_TRAPEZOWY_400,
            text: VueI18n.t('statics.handleType.MOSTEK_TRAPEZOWY_400'),
            imgPath: uchwytTapezRegulowany,
          },
          {
            value: HANDLE_TYPE.MOSTEK_TRAPEZOWY_500,
            text: VueI18n.t('statics.handleType.MOSTEK_TRAPEZOWY_500'),
            imgPath: uchwytTapezRegulowany,
          },
        ],
        'Gont(łupek)': [
          {
            value: HANDLE_TYPE.MOSTEK_TRAPEZOWY_330,
            text: VueI18n.t('statics.handleType.MOSTEK_TRAPEZOWY_330'),
            imgPath: uchwytTapezRegulowany,
          },
          {
            value: HANDLE_TYPE.MOSTEK_TRAPEZOWY_400,
            text: VueI18n.t('statics.handleType.MOSTEK_TRAPEZOWY_400'),
            imgPath: uchwytTapezRegulowany,
          },
          {
            value: HANDLE_TYPE.MOSTEK_TRAPEZOWY_500,
            text: VueI18n.t('statics.handleType.MOSTEK_TRAPEZOWY_500'),
            imgPath: uchwytTapezRegulowany,
          },
        ],
        Rąbek: [
          {
            value: HANDLE_TYPE.MOSTEK_TRAPEZOWY_330,
            text: VueI18n.t('statics.handleType.MOSTEK_TRAPEZOWY_330'),
            imgPath: uchwytTapezRegulowany,
          },
          {
            value: HANDLE_TYPE.MOSTEK_TRAPEZOWY_400,
            text: VueI18n.t('statics.handleType.MOSTEK_TRAPEZOWY_400'),
            imgPath: uchwytTapezRegulowany,
          },
          {
            value: HANDLE_TYPE.MOSTEK_TRAPEZOWY_500,
            text: VueI18n.t('statics.handleType.MOSTEK_TRAPEZOWY_500'),
            imgPath: uchwytTapezRegulowany,
          },
        ],
        Karpiówka: [
          {
            value: HANDLE_TYPE.DG,
            text: VueI18n.t('statics.handleType.DG'),
            imgPath: uchwytZBlachy,
          },
          {
            value: HANDLE_TYPE.DC2_L460,
            text: VueI18n.t('statics.handleType.DC2_L460'),
            imgPath: uchwytZBlachy,
          },
          {
            value: HANDLE_TYPE.DC2_L560,
            text: VueI18n.t('statics.handleType.DC2_L560'),
            imgPath: uchwytZBlachy,
          },
          {
            value: HANDLE_TYPE.DC_L460,
            text: VueI18n.t('statics.handleType.DC_L460'),
            imgPath: uchwytZBlachy,
          },
        ],
        'Dachówka ceramiczna': [
          {
            value: HANDLE_TYPE.DC1_L110,
            text: VueI18n.t('statics.handleType.DC1_L110'),
            imgPath: uchwytZBlachy,
          },
          {
            value: HANDLE_TYPE.DC1_L140,
            text: VueI18n.t('statics.handleType.DC1_L140'),
            imgPath: uchwytZBlachy,
          },
        ],
      };

      return handleTypesList;
    },
    ...handleTypeComputed,
    ...pourTypeComputed,
  },
  activated() {
    this.selectedHandleType = this.handleType;
    if (this.selectedHandleType) {
      this.$emit('isFowardEnabled', true);
    } else {
      this.isValid = false;
      this.$emit('isFowardEnabled', false);
    }
  },
};
</script>
<style lang="scss" scoped></style>
