<template>
  <title-wrapper :title="$t('components.wizard.inverters.title')">
    <catalog-list
      :category="categories.INVERTERS.value"
      :filters="false"
      :onWizard="true"
      :addAction="addAction"
      :removeAction="removeAction"
      :isCompatibleWithPower="true"
    >
    </catalog-list>
    <multiple-compatibility-modal
      :remove-action="removeAction"
      incompatibility-step-action="addToIncompatibleSteps"
      :selected-items="selectedInverters"
      :wizard-step="wizardStep"
      :openModal="openModal"
      @update:openModal="updateOpenModal"
    ></multiple-compatibility-modal>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../components/TitleWrapper.vue';
import CatalogList from '../../components/CatalogList.vue';
import { categories } from '../../static/categoriesList.js';
import { selectedInvertersComputed } from '../../store/helper.js';
import MultipleCompatibilityModal from '../../components/MultipleCompatibilityModal.vue';

export default {
  name: 'Inverters',
  components: { TitleWrapper, CatalogList, MultipleCompatibilityModal },
  data() {
    return {
      categories: categories,
      addAction: 'addInverter',
      wizardStep: 2,
      removeAction: 'removeInverter',
      openModal: false,
    };
  },
  methods: {
    updateOpenModal(value) {
      this.openModal = value;
    },
    handleIncompatibility() {
      if (
        this.selectedInverters.some((inv) => inv.incompatible) ||
        !this.isNumberCompatibile(this.selectedInverters)
      ) {
        this.$store.commit('addToIncompatibleSteps', this.wizardStep);
      } else {
        this.$store.commit('removeFromIncompatibleSteps', this.wizardStep);
      }
    },
    isNumberCompatibile(items) {
      return items.length === 1;
    },
  },
  computed: {
    ...selectedInvertersComputed,
  },
  activated() {
    if (this.selectedInverters.length > 0) {
      this.$emit('isFowardEnabled', true);
    }
    this.handleIncompatibility();
  },
  watch: {
    selectedInverters: function (selected, old) {
      const oldLength = old.length;
      const length = selected.length;
      const valid = length > 0;
      this.$emit('isFowardEnabled', valid);
      this.handleIncompatibility();
      if (length > 1 && oldLength < length) {
        this.openModal = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
