import countries from 'i18n-iso-countries';
import { defaultCountryCode } from '../static/defaultCountryCode';
import { selectedLanguageComputed } from '../store/helper';
import { supportedLangs } from '../static/supportedLangs';

export const countriesCodeHandler = {
  data() {
    return {
      countryCode: defaultCountryCode.PL,
      countriesList: [],
    };
  },
  methods: {
    initCountriesHandler() {
      countries.registerLocale(require('i18n-iso-countries/langs/pl.json'));
      countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
      countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
      countries.registerLocale(require('i18n-iso-countries/langs/ro.json'));
      countries.registerLocale(require('i18n-iso-countries/langs/sr.json'));
      countries.registerLocale(require('i18n-iso-countries/langs/it.json'));
      countries.registerLocale(require('i18n-iso-countries/langs/hu.json'));

      this.countriesList = countries.getNames(this.mappedListLanguage, {
        select: 'official',
      });
    },
    getCountryName(code) {
      return countries.isValid(code) ? countries.getName(code, this.mappedListLanguage) : '';
    },
    foreignDelivery(countryCode) {
      return countryCode && countryCode !== defaultCountryCode.PL;
    },
  },
  computed: {
    ...selectedLanguageComputed,
    mappedListLanguage() {
      return supportedLangs[this.selectedLanguage].textForUser.toLowerCase();
    },
    mappedCountries() {
      return Object.keys(this.countriesList).map((key) => ({
        value: key,
        text: this.countriesList[key],
      }));
    },
    foreignCountry() {
      return this.countryCode !== defaultCountryCode.PL;
    },
    foreignCountryEditView() {
      return this.editCompany.countryCode !== defaultCountryCode.PL;
    },
  },
  watch: {
    selectedLanguage: function () {
      this.initCountriesHandler();
    },
  },
  created() {
    this.initCountriesHandler();
  },
};
