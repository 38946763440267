import { selectedLanguageComputed } from '../store/helper';
import defaultLang from '../static/defaultLang';

export const getNameByLanguage = {
  computed: {
    ...selectedLanguageComputed,
  },
  methods: {
    supportSelectedLang(product) {
      return (
        this.selectedLanguage !== defaultLang.PL &&
        product.langs.some((product) => product.code === this.selectedLanguage)
      );
    },
    productTextOptions(product) {
      return product.langs.find((product) => product.code === this.selectedLanguage);
    },
    getNameByLang(product) {
      if (this.supportSelectedLang(product)) {
        const name = this.productTextOptions(product)?.name;
        return name && this.noSpacesRule(name) ? name : product.name;
      } else return product.name;
    },
    getShortDescriptionByLang(product) {
      if (this.supportSelectedLang(product)) {
        const shortDescription = this.productTextOptions(product)?.shortDescription;
        return shortDescription && this.noSpacesRule(shortDescription)
          ? shortDescription
          : product.shortDescription;
      } else return product.shortDescription;
    },
    getLongDescriptionByLang(product) {
      if (this.supportSelectedLang(product)) {
        const longDescription = this.productTextOptions(product)?.longDescription;
        return longDescription && this.noSpacesRule(longDescription)
          ? longDescription
          : product.longDescription;
      } else return product.longDescription;
    },
    noSpacesRule(value) {
      return value.replace(/\s/g, '').length > 0;
    },
  },
};
