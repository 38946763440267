<template>
  <title-wrapper :title="$t('components.main.title')">
    <v-container class="mx-0 px-0"
      ><v-row
        ><v-col cols="12" sm="4"
          ><choose-panel
            :to="{ name: 'StartWizard' }"
            :iconSize="$vuetify.breakpoint.sm ? 100 : 140"
            icon="$wizardIcon"
          >
            {{ $t('components.main.wizard') }}
            <p class="primary--text text-uppercase text-subtitle-2 mt-1">
              <v-icon class="mb-1 mr-1" size="20">$chatIcon</v-icon>
              {{ $t('components.main.virtualAssistant') }}
            </p>
          </choose-panel></v-col
        ><v-col cols="12" sm="4"
          ><choose-panel
            :to="{ name: 'Categories' }"
            :iconSize="$vuetify.breakpoint.sm ? 100 : 140"
            icon="$shoppingIcon"
            >{{ $t('components.main.fastShoping') }}</choose-panel
          ></v-col
        >
        <v-col cols="12" sm="4"
          ><choose-panel
            :to="{ name: 'Sets' }"
            :iconSize="$vuetify.breakpoint.sm ? 100 : 140"
            icon="$setsComboIcon"
          >
            {{ $t('components.main.sets') }}</choose-panel
          ></v-col
        >
      </v-row>
    </v-container>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../components/TitleWrapper.vue';
import ChoosePanel from '../components/ChoosePanel.vue';

export default {
  components: { TitleWrapper, ChoosePanel },
};
</script>
<style lang="scss" scoped></style>
