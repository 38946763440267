import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import PeopleIcon from '../components/icons/PeopleIcon.vue';
import NavbarTriangleIcon from '../components/icons/NavbarTriangleIcon.vue';
import WizardIcon from '../components/icons/WizardIcon.vue';
import ShoppingIcon from '../components/icons/ShoppingIcon.vue';
import SquareIcon from '../components/icons/SquareIcon.vue';
import TriangleIcon from '../components/icons/TriangleIcon.vue';
import FloatingHelpIcon from '../components/icons/FloatingHelpIcon.vue';
import FloatingCloseIcon from '../components/icons/FloatingCloseIcon.vue';
import HomeIcon from '../components/icons/HomeIcon.vue';
import ListIcon from '../components/icons/ListIcon.vue';
import LogoutIcon from '../components/icons/LogoutIcon.vue';
import SettingsIcon from '../components/icons/SettingsIcon.vue';
import PhoneIcon from '../components/icons/PhoneIcon.vue';
import MailIcon from '../components/icons/MailIcon.vue';
import PdfIcon from '../components/icons/PdfIcon.vue';
import ArrowIcon from '../components/icons/ArrowIcon.vue';
import ChatIcon from '../components/icons/ChatIcon.vue';
import InverterIcon from '../components/icons/InverterIcon.vue';
import MountingAccessoriesIcon from '../components/icons/MountingAccessoriesIcon.vue';
import BFIcon from '../components/icons/BFIcon.vue';
import PhotoModule from '../components/icons/PhotoModule.vue';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 340,
      sm: 540,
      md: 800,
      lg: 1280,
    },
    scrollBarWidth: 24,
  },
  theme: {
    themes: {
      light: {
        primary: '#e7247b',
        info: '#5C87C7',
        warning: '#FFC107',
        background: '#F5F5F5',
        font: '#36313c',
        'success-primary': '#84C75C',
        'success-secondary': 'F9FFF5',
        'background-secondary': '#fefefe',
        'error-primary': '#ff0000',
        'error-secondary': '#ffcbcb',
        'error-tertiary': '#dc5252',
        'tertiary-text': '7D7D7D',
      },
    },
    options: {
      customProperties: true,
    },
  },
  icons: {
    values: {
      peopleIcon: {
        component: PeopleIcon,
      },
      arrowIcon: {
        component: ArrowIcon,
      },
      navbarTriangleIcon: {
        component: NavbarTriangleIcon,
      },
      wizardIcon: {
        component: WizardIcon,
      },
      shoppingIcon: {
        component: ShoppingIcon,
      },
      squareIcon: {
        component: SquareIcon,
      },
      triangleIcon: {
        component: TriangleIcon,
      },
      floatingHelpIcon: {
        component: FloatingHelpIcon,
      },
      floatingCloseIcon: {
        component: FloatingCloseIcon,
      },
      homeIcon: {
        component: HomeIcon,
      },
      listIcon: {
        component: ListIcon,
      },
      logoutIcon: {
        component: LogoutIcon,
      },
      settingsIcon: {
        component: SettingsIcon,
      },
      phoneIcon: {
        component: PhoneIcon,
      },
      mailIcon: {
        component: MailIcon,
      },
      pdfIcon: {
        component: PdfIcon,
      },
      chatIcon: {
        component: ChatIcon,
      },
      inverterIcon: {
        component: InverterIcon,
      },
      mountingAccessoriesIcon: {
        component: MountingAccessoriesIcon,
      },
      setsComboIcon: {
        component: BFIcon,
      },
      photoModule: {
        component: PhotoModule,
      },
    },
  },
});
