import config from '../../configuration.json';
import axios from 'axios';
import QueryHandler from '../utils/QueryHandler';

const url = `${config.VUE_APP_API_URL}/products`;

axios.defaults.withCredentials = true;

class ProductService {
  static async getAll(params = {}) {
    const resultUrl = QueryHandler.buildQuery(`${url}`, params);
    const res = await axios.get(resultUrl);
    return res.data;
  }
  static async update(id, product) {
    const res = await axios.put(`${url}/${id}`, { ...product });
    return res.data;
  }

  static async getCategories() {
    const res = await axios.get(`${url}/categories`);
    return res.data;
  }
  static async get(id) {
    const res = await axios.get(`${url}/${id}`);
    return res.data;
  }

  static async updateImage(productId, file) {
    var formData = new FormData();
    formData.append('image', file);
    const res = await axios.put(`${url}/image/${productId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return res.data;
  }

  static async deleteImage(shopId) {
    const res = await axios.delete(`${url}/image/${shopId}`);
    return res.data;
  }

  static async updatePdf(productId, file) {
    var formData = new FormData();
    formData.append('pdfSpecification', file);
    const res = await axios.put(`${url}/spec/${productId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return res.data;
  }

  static async deletePdf(shopId) {
    const res = await axios.delete(`${url}/spec/${shopId}`);
    return res.data;
  }
}

export default ProductService;
