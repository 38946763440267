<template>
  <div>
    <router-view></router-view>
    <title-wrapper :title="`${$t('components.manageCompanies.title')} (${count})`">
      <my-data-table
        :data="companies"
        :headers="headers"
        :loading="loading"
        :length="length"
        :page.sync="params.page"
        :sortBy="sortBy"
        :sortDesc="sortDesc"
        @update:sort-by="sortByFunc"
        @update:sort-desc="sortDescFunc"
      >
        <template v-slot:filters>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="params.companyName"
              hide-details
              :placeholder="$t('helpers.companyName')"
              solo
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="params.email"
              hide-details
              :placeholder="$t('helpers.email')"
              solo
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
              solo
              v-model="params.countryCode"
              :items="mappedCountries"
              :hide-details="true"
              :placeholder="$t('helpers.country')"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="params.nip"
              hide-details
              :placeholder="$t('helpers.registerForm')"
              solo
            ></v-text-field
          ></v-col>
          <v-col cols="6" sm="1">
            <v-btn color="primary " elevation="0" fab dark block @click="onSearch">
              <v-icon>mdi-magnify</v-icon>
            </v-btn></v-col
          >
          <v-col cols="6" sm="1">
            <v-btn color="primary" elevation="0" fab dark block @click="clearParams">
              <v-icon>mdi-cancel</v-icon>
            </v-btn></v-col
          >
        </template>

        <template v-slot:actions="slotProps">
          <router-link :to="{ name: returnRouteName, params: { companyId: slotProps.item._id } }">
            <v-list-item class="hover-item-popup">
              <v-list-item-content> {{ $t('helpers.showDetails') }}</v-list-item-content>
            </v-list-item>
          </router-link>
        </template>

        <template v-slot:below>
          <v-row
            ><v-col><v-divider></v-divider></v-col
          ></v-row>
          <v-row
            ><v-col v-if="isSysAdmin"
              ><primary-button :to="{ name: 'AddCompany' }"
                ><v-icon class="mr-4">mdi-plus-circle-outline</v-icon
                >{{ $t('components.manageCompanies.addCompany') }}</primary-button
              ></v-col
            ></v-row
          >
        </template>
      </my-data-table>
    </title-wrapper>
  </div>
</template>
<script>
import PrimaryButton from '../../../../components/buttons/PrimaryButton.vue';
import MyDataTable from '../../../../components/MyDataTable.vue';
import TitleWrapper from '../../../../components/TitleWrapper.vue';
import SysadminService from '../../../../services/SysadminService';
import { sortHandler } from '../../../../mixins/sortHandler';
import { countriesCodeHandler } from '../../../../mixins/countriesCodeHandler';
import VueI18n from '../../../../plugins/i18n';
import { permissionRolesMixin } from '../../../../mixins/permissionRolesMixin';
import SalesmanService from '../../../../services/SalesmanService';

export default {
  components: { TitleWrapper, MyDataTable, PrimaryButton },
  mixins: [sortHandler, countriesCodeHandler, permissionRolesMixin],
  name: 'Companies',
  data() {
    return {
      count: 0,
      itemsPerPage: 6,
      length: 0,
      companies: [],
      loading: true,
      params: {
        email: '',
        nip: '',
        companyName: '',
        sort: '',
        page: 1,
        limit: 6,
        countryCode: '',
      },
    };
  },
  computed: {
    returnRouteName() {
      return this.isSalesman ? 'SalesmanSingleCompany' : 'SingleCompany';
    },
    headers() {
      return [
        { text: VueI18n.t('helpers.companyName'), value: 'namePlusEmployees' },
        { text: VueI18n.t('helpers.email'), value: 'users[0].email', sortable: false },
        { text: VueI18n.t('helpers.country'), value: 'countryCode' },
        { text: VueI18n.t('helpers.registerForm'), value: 'nip' },
        { text: '', value: 'actions', sortable: false },
      ];
    },
  },
  watch: {
    'params.page': function () {
      this.getFiltered();
    },
  },
  methods: {
    updatePage(page) {
      this.page = page;
    },
    clearParams() {
      this.params = {
        email: '',
        nip: '',
        companyName: '',
        sort: '',
        page: 1,
        limit: 6,
        countryCode: '',
      };
      this.getFiltered();
    },
    onSearch() {
      this.params.page = 1;
      this.getFiltered();
    },
    async getFiltered() {
      try {
        let response;

        if (this.isSysAdmin) response = await SysadminService.getAllCompanies(this.params);
        if (this.isSalesman) response = await SalesmanService.getCompanies(this.params);

        this.companies = response.data.map((company) => {
          company.namePlusEmployees = `${company.companyName} (${company.users.length})`;
          return company;
        });
        this.count = response.count;
        this.length = Math.ceil(response.count / this.itemsPerPage);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    fetchSort() {
      this.getFiltered();
    },
  },

  created() {
    this.getFiltered();
  },
};
</script>
<style lang="scss" scoped></style>
