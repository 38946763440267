<template>
  <title-wrapper :title="$t('components.categories.title')">
    <v-container class="mx-0 px-0"
      ><v-row>
        <v-col cols="12" sm="4" v-for="category in categoriesCards" :key="category.name">
          <choose-panel
            panelMinHeight="15rem"
            :to="{ name: 'Catalog', query: { category: category.value } }"
            :icon="category.icon"
          >
            {{ category.name }}
          </choose-panel>
        </v-col>
      </v-row></v-container
    >
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../components/TitleWrapper.vue';
import ChoosePanel from '../components/ChoosePanel.vue';
import { categories } from '../static/categoriesList.js';
import { checkoutModes } from '../static/checkoutModes.js';

export default {
  components: { TitleWrapper, ChoosePanel },
  computed: {
    categoriesCards() {
      const categoriesCards = [
        {
          name: this.$i18n.t('statics.categoriesList.PHOTO_MODULES'),
          value: categories.PHOTO_MODULES.value,
          icon: '$photoModule',
        },
        {
          name: this.$i18n.t('statics.categoriesList.INVERTERS'),
          value: categories.INVERTERS.value,
          icon: '$inverterIcon',
        },
        {
          name: this.$i18n.t('statics.categoriesList.OPTIMIZERS'),
          value: categories.OPTIMIZERS.value,
          icon: 'mdi-arrow-collapse',
        },
        {
          name: this.$i18n.t('statics.categoriesList.MOUNTING_ACCESSORIES'),
          value: categories.MOUNTING_ACCESSORIES.value,
          icon: '$mountingAccessoriesIcon',
        },
        {
          name: this.$i18n.t('statics.categoriesList.GROUNDED_CABLE'),
          value: categories.GROUNDED_CABLE.value,
          icon: 'mdi-cable-data',
        },
        {
          name: this.$i18n.t('statics.categoriesList.CABEL_DC'),
          value: categories.CABEL_DC.value,
          icon: 'mdi-cable-data',
        },
        {
          name: this.$i18n.t('statics.categoriesList.SWITCHGEAR'),
          value: categories.SWITCHGEAR.value,
          icon: 'mdi-electric-switch',
        },
        {
          name: this.$i18n.t('statics.categoriesList.RESIDUAL'),
          value: categories.RESIDUAL.value,
          icon: 'mdi-close-network',
        },
        {
          name: this.$i18n.t('statics.categoriesList.EARTHING'),
          value: categories.EARTHING.value,
          icon: 'mdi-earth-box',
        },
        {
          name: this.$i18n.t('statics.categoriesList.INSPECTION_BOX'),
          value: categories.INSPECTION_BOX.value,
          icon: 'mdi-delete',
        },
        {
          name: this.$i18n.t('statics.categoriesList.CONDUIT'),
          value: categories.CONDUIT.value,
          icon: 'mdi-lightbulb-fluorescent-tube',
        },
        {
          name: this.$i18n.t('statics.categoriesList.MC4'),
          value: categories.MC4.value,
          icon: 'mdi-connection',
        },
        {
          name: this.$i18n.t('statics.categoriesList.ELECTRICK_SHOCK'),
          value: categories.ELECTRICK_SHOCK.value,
          icon: 'mdi-close-network',
        },
        {
          name: this.$i18n.t('statics.categoriesList.OTHERS'),
          value: categories.OTHERS.value,
          icon: 'mdi-dots-horizontal',
        },
      ];
      return categoriesCards;
    },
  },
  created() {
    this.$store.commit('setMode', checkoutModes.FAST_CHECKOUT);
  },
};
</script>
<style lang="scss" scoped></style>
