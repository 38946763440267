<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    id="svg2"
    version="1.1"
    inkscape:version="0.91 r13725"
    xml:space="preserve"
    width="550.97375"
    height="257.65625"
    viewBox="0 0 750.97376 257.65625"
    sodipodi:docname="bf-logo-pic-slim.svg"
  >
    <metadata id="metadata8">
      <rdf:RDF
        ><cc:Work rdf:about=""
          ><dc:format>image/svg+xml</dc:format
          ><dc:type
            rdf:resource="http://purl.org/dc/dcmitype/StillImage" /><dc:title></dc:title></cc:Work
      ></rdf:RDF>
    </metadata>
    <defs id="defs6">
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath20">
        <path
          d="m 0,572.598 572.598,0 L 572.598,0 0,0 0,572.598 Z"
          id="path22"
          inkscape:connector-curvature="0"
        />
      </clipPath>
    </defs>
    <sodipodi:namedview
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1"
      objecttolerance="10"
      gridtolerance="10"
      guidetolerance="10"
      inkscape:pageopacity="0"
      inkscape:pageshadow="2"
      inkscape:window-width="1193"
      inkscape:window-height="710"
      id="namedview4"
      showgrid="false"
      inkscape:zoom="0.32972522"
      inkscape:cx="-78.519294"
      inkscape:cy="307.2883"
      inkscape:window-x="263"
      inkscape:window-y="77"
      inkscape:window-maximized="0"
      inkscape:current-layer="g16"
      fit-margin-top="0"
      fit-margin-left="0"
      fit-margin-right="0"
      fit-margin-bottom="0"
    />
    <g
      id="g10"
      inkscape:groupmode="layer"
      inkscape:label="Logo_Final_v1_01"
      transform="matrix(1.25,0,0,-1.25,-95.19985,308.2417)"
    >
      <g id="g12" transform="translate(126.55088,135.32236)">
        <path
          d="m 0,0 100.174,-9.61 20.833,-23.733 10.688,3.426 1.84,21.035 40.286,20.069 0,11.374 L 144.052,56.475 65.034,75.323 -0.314,51.084 -11.155,38.121 0,0 Z m 97.486,-84.854 2.315,0 -24.781,53.88 14.363,2.705 29.305,-28.584 29.172,14.391 1.156,24.396 45.772,23.22 0,22.78 -34.606,48.203 -98.031,24.665 -80.777,-30.978 -20.86,-26.407 10.279,-40.615 18.486,-1.774 -11.532,39.41 15.971,19.099 70.683,26.218 85.067,-20.292 34.353,-39.135 0,-21.331 -40.811,-20.332 -1.932,-22.076 -23.261,-7.456 -22.544,25.68 -90.24,8.658 8.139,-13.225 14.633,-10.351 30.454,0 39.227,-50.749 z m 17.92,-10 -22.83,0 -39.227,50.749 -28.724,0 -18.898,13.368 -13.172,21.405 -29.729,2.853 -13.217,52.224 25.62,32.433 86.295,33.093 104.646,-26.33 38.618,-53.789 0,-32.138 -46.059,-23.365 -1.158,-24.473 -40.802,-20.127 -24.079,23.487 22.716,-49.39 z"
          style="fill: white; fill-rule: nonzero; stroke: none"
          id="path14"
          inkscape:connector-curvature="0"
        />
      </g>
      <g id="g16" transform="translate(-116.46061,-160.13334)" />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'FloatingHelpIcon',
};
</script>
<style lang="scss" scoped></style>
