import { allAttributes } from '../static/categories/allAttributes';
import VueI18n from '../plugins/i18n';

export const attributesNameMixin = {
  methods: {
    getAttributeName(attr) {
      const path = Object.values(allAttributes).find((item) => item.value === attr?.name)?.text;
      return VueI18n.t(`statics.attributes.${path}`);
    },
    getAttributeValueTranslation(attribute) {
      const { name, value } = attribute;

      if (name === 'Czy fullblack') {
        return value === 'Tak'
          ? this.$i18n.t('components.singleProduct.fullBlackValues.yes')
          : this.$i18n.t('components.singleProduct.fullBlackValues.no');
      } else if (name === 'Kolor ramy') {
        return value === 'Srebrny'
          ? this.$i18n.t('components.singleProduct.frameColor.silver')
          : this.$i18n.t('components.singleProduct.frameColor.black');
      } else return value;
    },
  },
};
